<template>
  <div class="editFrom">
    <el-dialog
      title="修改用户信息"
      :visible.sync="dialogVisible"
      width="400px"
      :close-on-click-modal="false"
    >
      <el-form
        :model="ruleForm"
        :rules="rules"
        ref="ruleForm"
        label-width="70px"
      >
        <el-form-item
          v-if="!ruleForm.username"
          label="用户名"
          prop="username"
          :rules="{ required: true, accountName: true }"
        >
          <el-input v-model="ruleForm.username" placeholder="用户名"></el-input>
        </el-form-item>

        <!-- <el-form-item
          label="姓名"
          prop="name"
          :rules="{ required: true, username: true }"
        >
          <el-input v-model="ruleForm.name" placeholder="用户名"></el-input>
        </el-form-item> -->

        <el-form-item label="手机号" prop="phone">
          <el-input
            v-model="ruleForm.phone"
            :rules="{ required: true, phone: true }"
            placeholder="手机号"
          ></el-input>
        </el-form-item>

        <el-form-item label="角色" prop="roleType">
          <el-select
            clearable
            filterable
            @blur="selectBlur"
            v-model="ruleForm.roleType"
            placeholder="请选择角色"
            style="width: 100%"
            @change="handleRoleChange"
          >
            <el-option label="学校管理员" :value="0"></el-option>
            <el-option label="文印室" :value="1"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="学校" prop="schoolName" v-show="!isSchoolAdmin">
          <el-input
            v-model="ruleForm.schoolName"
            filterable
            placeholder="请选择学校"
            style="width: 100%"
          >
          </el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="submitForm()"> 确 定 </el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import { getCustomerlist } from "@/request/api/customer";
import { updateUser } from "@/request/api/login";

export default {
  name: "addFrom",
  data() {
    return {
      search: {
        keyword: "",
        page: 1, // 当前页码
        limit: 10, // 每页显示数量
      },
      options: [],
      dialogVisible: false,
      isSchoolAdmin: true, // 用于跟踪是否选择了学校管理员
      schoolList: [],
      visible: null,
      ruleForm: {
        deptId: "",
        name: "",
        username: "",
        roleType: "",
        phone: "",
      },
      rules: {
        // 用户名规则：必填且符合自定义的用户名格式
        // username: [
        //   { required: true, message: "用户名不能为空", trigger: "blur" },
        //   {
        //     pattern: /^[a-zA-Z0-9_]{4,20}$/,
        //     message: "用户名格式不正确",
        //     trigger: "blur",
        //   },
        // ],
        // 账号名规则：必填且符合自定义的账号名格式
        accountName: [
          { required: true, message: "账号名不能为空", trigger: "blur" },
          {
            pattern: /^[a-zA-Z0-9._]{6,30}$/,
            message: "账号名格式不正确",
            trigger: "blur",
          },
        ],
        // 手机号规则：必填且符合中国大陆手机号格式
        phone: [
          { required: true, message: "手机号不能为空", trigger: "blur" },
          {
            pattern: /^1[3-9]\d{9}$/,
            message: "手机号格式不正确",
            trigger: "blur",
          },
        ],
        roleType: [
          { required: true, message: "请选择角色", trigger: "change" },
        ],
        // schoolId: [
        //   { required: true, message: "请选择学校", trigger: "change" },
        // ],
      },
    };
  },

  created() {
    // this.checkRoleAndDisableSchool();
    this.getSchoollists();
  },
  mounted() {},
  methods: {
    selectBlur(e) {
      // 意见类型
      if (e.target.value !== "") {
        this.value = e.target.value;
        this.$forceUpdate(); // 强制更新
      }
    },

    async getSchoollists() {
      const params = {
        size: 1000,
        current: 1,
      };
      const res = await getCustomerlist(params);
      this.options = res.data.records;

      // console.log(res);
    },
    // 修改handleRoleChange方法中的逻辑
    handleRoleChange() {
      const newValue = this.ruleForm.roleType;
      this.isSchoolAdmin = newValue !== 1; // 仅当角色为学校管理员(值为0)时，isSchoolAdmin为true，否则为false
    },
    checkRoleAndDisableSchool() {
      // 根据角色类型动态禁用学校选择框
      const disableForRoles = [-1, 0];
      this.isSchoolDisabled = disableForRoles.includes(this.ruleForm.roleType);
    },
    submitForm() {
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          // 表单验证通过，执行提交逻辑

          // console.log("表单验证通过");
          this.updateUseron();
        } else {
          // 表单验证失败
          // console.log("表单验证失败");
        }
      });
    },

    init(data) {
      // console.log(data);
      this.isSchoolAdmin = data.roleType !== 1;
      if (data.roleType === -1) {
        data.roleType = "超级管理员";
      }
      this.ruleForm = JSON.parse(JSON.stringify(data));
      this.dialogVisible = true;
    },
    //更新用户信息
    async updateUseron() {
      try {
        this.dialogVisible = false;
        //将数据转换
        const SysUse = JSON.parse(JSON.stringify(this.ruleForm));
        await updateUser(SysUse);
        this.$message({
          message: "修改成功！",
          type: "success",
          showClose: true,
        });
        this.$emit("call-get-userlists");
      } catch (error) {
        // console.error("更新用户信息失败:", error);
      }
    },
  },
};
</script>
<style lang="scss" scoped></style>
