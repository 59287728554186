import request from "@/request/axios";

// 获取指定学校的科目信息
export const getSubjectinfo = ({ userId }) => {
  return request({
    url: "/class/userinfo",
    method: "get",
    params: {
      userId,
    },
  });
};

//获取全部的科目信息
export const getSubject = () => {
  return request({
    url: "/subject/list",
    method: "get",
  });
};

//更新科目信息

export const updateSubject = (data) => {
  return request({
    url: "/subject/update",
    method: "put",
    data,
  });
};

//一键修改年级、班级

export const upGradeandclass = (data) => {
  return request({
    url: "/class/updateGradeClass",
    method: "post",
    data,
  });
};

//删除科目

export const delSubject = (id) => {
  return request({
    url: `/subject/delete/${id}`,
    method: "delete",
  });
};

//添加科目

export const addSubject = (data) => {
  return request({
    url: "/subject/add",
    method: "post",
    data,
  });
};

//科目中间修改

export const addSubjectMiddle = (data) => {
  return request({
    url: "/subjectUser/updateUserSubjects",
    method: "post",
    data,
  });
};

//科目中间通过ID获取
export const getSubjectMiddle = (userId) => {
  return request({
    url: `/subjectUser/getByUserId/${userId}`,
    method: "get",
  });
};
