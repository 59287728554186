import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import ElementUI, { Scrollbar } from "element-ui";
// import "element-ui/lib/theme-chalk/index.css";
import "./element/index.css";
// import "@/utils/directives.js";

import filters from "@/utils/filters.js";
// 加载过滤器
Object.keys(filters).forEach((key) => {
  Vue.filter(key, filters[key]);
});

Vue.config.productionTip = false;
Vue.use(ElementUI);
Vue.use(Scrollbar);
new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
