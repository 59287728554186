<template>
  <div
    class="menuBar"
    :class="{
      'check-menu': isCollapse,
    }"
  >
    <div class="title">
      <img src="@/assets/logo/logo.png" alt="" />
      <span class="title-name"> 业务管理系统 </span>
      <i class="el-icon-s-unfold" v-if="isCollapse" @click="showHmenu()"></i>
      <i class="el-icon-s-fold" v-else @click="showHmenu()"></i>
    </div>
    <el-scrollbar ref="scrollbar">
      <el-menu :default-active="$route.meta.url" :collapse="isCollapse">
        <template v-for="(item, index) in menuItem">
          <el-submenu :index="item.url" v-if="item.children" :key="index">
            <template slot="title">
              <img :src="item.icon" alt="" style="display: inline-block" />
              <!-- <img class="on" :src="item.icon_on" alt="" /> -->
              <span slot="title">{{ item.name }}</span>
            </template>
            <el-menu-item-group>
              <el-menu-item
                :index="c_item.url"
                v-for="(c_item, c_index) in item.children"
                :key="c_index + 'child'"
                @click="linkTo(c_item)"
                >{{ c_item.name }}</el-menu-item
              >
            </el-menu-item-group>
          </el-submenu>
          <el-menu-item
            :index="item.url"
            v-else
            :key="index"
            @click="linkTo(item)"
          >
            <img :src="item.icon" alt="" />
            <img class="on" :src="item.icon_on" alt="" />
            <span slot="title">{{ item.name }}</span>
          </el-menu-item>
        </template>
      </el-menu>
    </el-scrollbar>
  </div>
</template>
<script>
import { getMenu } from "@/router/menu";

export default {
  name: "menuBar",

  data() {
    return { isCollapse: false, menuItem: getMenu() };
  },

  created() {},
  mounted() {
    this.initView();
  },
  methods: {
    initView() {
      this.$nextTick(() => {
        const scr = this.$refs.scrollbar.$el; // 获取<el-scrollbar>的DOM节点
        const observer = new ResizeObserver(() => {
          if (!this.$refs.scrollbar) return;
          this.$refs.scrollbar.update();
        });
        observer.observe(scr); // 监听高度变化
      });
    },
    showHmenu() {
      this.isCollapse = !this.isCollapse;
    },

    linkTo(val) {
      if (val.url == this.$route.path) return;
      this.$router.push({
        path: val.url,
      });
    },
    // handleOpen(key, keyPath) {
    //   console.log(key, keyPath);
    // },
    // handleClose(key, keyPath) {
    //   console.log(key, keyPath);
    // },
  },
};
</script>
<style lang="scss" scoped>
.check-menu.menuBar {
  width: 63px;

  .title {
    .title-name {
      width: 1px;
      overflow: hidden;
    }
    img {
      width: 1px;
      overflow: hidden;
      margin-right: 0;
    }
  }
}
.menuBar {
  position: relative;
  height: 100vh;
  width: 200px;
  flex-shrink: 0;
  transition: all 0.3s ease;
  background-color: #ffffff;
  .el-menu {
    ::v-deep {
      img {
        margin-right: 4px;
      }
      .on {
        display: none;
      }
      .is-active {
        // > .el-submenu__title {
        //   color: #409eff;
        // }
        img {
          display: none;
        }
        .on {
          display: inline-block;
        }
      }
    }
  }
  .menuBar-box {
    height: 100%;
  }
  .el-menu {
    border-right: 0;
  }
  .title {
    padding: 10px;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #409eff;

    img {
      width: 28px;
    }

    .title-name {
      color: #409eff;
      display: block;
      width: 160px;
      text-align: center;
      padding-left: 4px;
      font-size: 18px;
      font-weight: 600;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      transition: all 0.3s ease;
    }
    i {
      font-size: 24px;
      cursor: pointer;
    }
  }
  .el-scrollbar {
    height: 100%;
  }
}
</style>
