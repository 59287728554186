import request from "@/request/axios";
import qs from "qs";
const formLoginClient = "pig:pig"; // 用户名密码登录的 client 信息
const scope = "server";
export const loginByUsername = (username, password, code, randomStr) => {
  const grant_type = "password";
  const dataObj = qs.stringify({ username: username, password: password });
  const basicAuth = "Basic " + window.btoa(formLoginClient);

  return request({
    url: "/auth/oauth2/token",
    headers: {
      isToken: false,
      "TENANT-ID": "1",
      Authorization: basicAuth,
    },
    method: "post",
    params: { randomStr, code, grant_type, scope },
    data: dataObj,
  });
};

// 获取用户信息
export const getUserInfo = () => {
  return request({
    url: "/admin/user/info",
    method: "get",
  });
};

// 用户登录
export const userLogin = (data) => {
  return request({
    url: "/user/login",
    method: "post",
    params: data,
  });
};

// 退出登录
export const logout = () => {
  return request({
    url: "/auth/token/logout",
    method: "delete",
  });
};

// 新增用户
export const addUser = (data) => {
  return request({
    url: "/user",
    method: "post",
    data,
  });
};

// 获取用户列表
export const getUserlist = ({ keyword, size, current, createBy }) => {
  return request({
    url: "/user/myList",
    method: "get",
    params: {
      keyword,
      size,
      current,
      createBy,
    },
  });
};

// 删除用户
export const deleteUser = (data) => {
  return request({
    url: "/user",
    method: "delete",
    data,
  });
};

// 修改用户信息
export const updateUser = (data) => {
  return request({
    url: "/user/update",
    method: "post",
    data,
  });
};

// 验证密码是否正确
export const checkPassword = (data) => {
  return request({
    url: "/user/checkPassword",
    method: "post",
    params: data,
  });
};

//获取当前用户的信息
export const getUserByid = (id) => {
  return request({
    url: `/user/get/${id}`,
    method: "get",
  });
};

// 验证密码是否正确
export const updatePassword = ({ id, newPassword }) => {
  return request({
    url: "/user/updatePassword",
    method: "post",
    params: { id, newPassword },
  });
};

// 重置密码
export const remakePassword = (data) => {
  return request({
    url: "/user/resetPassword",
    method: "post",
    params: data,
  });
};

//获取当前用户的信息
export const getstuta = (data) => {
  return request({
    url: "/report/status",
    method: "get",
    params: data,
  });
};
