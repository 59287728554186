<template>
  <div class="home">
    <menuBar></menuBar>
    <div class="cnt-box">
      <operation></operation>
      <el-scrollbar ref="scrollbar" class="scroll-box">
        <div class="view-box">
          <router-view></router-view>
        </div>
      </el-scrollbar>
    </div>
  </div>
</template>

<script>
import menuBar from "@/components/home/menuBar.vue";
import operation from "@/components/home/operation.vue";
export default {
  name: "home",
  components: { menuBar, operation },
  mounted() {
    this.initView();
  },
  methods: {
    initView() {
      // 监听高度变化  el-scrollbar bug
      this.$nextTick(() => {
        const scr = this.$refs.scrollbar.$el; // 获取<el-scrollbar>的DOM节点
        const observer = new ResizeObserver(() => {
          if (!this.$refs.scrollbar) return;
          this.$refs.scrollbar.update();
        });
        observer.observe(scr);
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.home {
  background-color: #f5f5f5;
  width: 100vw;
  height: 100vh;
  display: flex;
  overflow: hidden;
  flex-direction: row;
  flex: 1;
  flex-basis: auto;
  box-sizing: border-box;
  min-width: 0;

  .scroll-box {
    // height: calc(100vh - 50px);
    padding: 18px;
    // > div {
    //   width: 100%;
    // }
    .view-box {
      background-color: #ffffff;
      padding: 18px;
      min-height: calc(100vh - 90px);
      width: 100%;
    }
  }
  .cnt-box {
    display: flex;
    flex-direction: column;
    flex: 1;
    flex-basis: auto;
    box-sizing: border-box;
    min-width: 0;
  }
}
</style>
