import { validatenull } from "@/utils/validate";

// key: 'wtjy-', 配置主键,目前用于存储
export const keyName = "wtjy-";

/**
 * 存储浏览器Storage
 */
export const setStore = (params = {}) => {
  let { name, content, type } = params;
  name = keyName + name;
  const obj = {
    dataType: typeof content,
    content: content,
    type: type,
    datetime: new Date().getTime(),
  };
  if (type) {
    window.sessionStorage.setItem(name, JSON.stringify(obj));
  } else {
    window.localStorage.setItem(name, JSON.stringify(obj));
  }
};

/**
 * 获取浏览器Storage
 */
export const getStore = (params = {}) => {
  let { name, debug } = params;
  name = keyName + name;
  let obj = {};
  let content;
  obj = window.sessionStorage.getItem(name);
  if (validatenull(obj)) obj = window.localStorage.getItem(name);
  if (validatenull(obj)) return;
  try {
    obj = JSON.parse(obj);
  } catch (e) {
    return obj;
  }
  if (debug) {
    return obj;
  }
  if (obj.dataType === "string") {
    content = obj.content;
  } else if (obj.dataType === "number") {
    content = Number(obj.content);
  } else if (obj.dataType === "boolean") {
    content = obj.content;
  } else if (obj.dataType === "object") {
    content = obj.content;
  }
  return content;
};

/**
 * 内部使用方法
 */
const getStoreInternal = (params = {}) => {
  let { name, debug } = params;
  let obj = {};
  let content;
  obj = window.sessionStorage.getItem(name);
  if (validatenull(obj)) obj = window.localStorage.getItem(name);
  if (validatenull(obj)) return;
  try {
    obj = JSON.parse(obj);
  } catch (e) {
    return obj;
  }
  if (debug) {
    return obj;
  }
  if (obj.dataType === "string") {
    content = obj.content;
  } else if (obj.dataType === "number") {
    content = Number(obj.content);
  } else if (obj.dataType === "boolean") {
    content = obj.content;
  } else if (obj.dataType === "object") {
    content = obj.content;
  }
  return content;
};

/**
 * 删除浏览器Storage
 */
export const removeStore = (params = {}) => {
  let { name, type } = params;
  name = keyName + name;
  if (type) {
    window.sessionStorage.removeItem(name);
  } else {
    window.localStorage.removeItem(name);
  }
};

/**
 * 获取全部浏览器Storage
 */
export const getAllStore = (params = {}) => {
  const list = [];
  const { type } = params;
  if (type) {
    for (let i = 0; i <= window.sessionStorage.length; i++) {
      list.push({
        name: window.sessionStorage.key(i),
        content: getStoreInternal({
          name: window.sessionStorage.key(i),
          type: "session",
        }),
      });
    }
  } else {
    for (let i = 0; i <= window.localStorage.length; i++) {
      list.push({
        name: window.localStorage.key(i),
        content: getStoreInternal({
          name: window.localStorage.key(i),
        }),
      });
    }
  }
  return list;
};

/**
 * 清空全部浏览器Storage
 */
export const clearStore = (params = {}) => {
  // console.log(type);
  const { type } = params;

  let name = keyName + "template_version";
  let version = window.localStorage.getItem(name);
  if (type) {
    window.sessionStorage.clear();
  } else {
    window.localStorage.clear();
  }
  // 添加一个判断  如果用户存在版本  就在清除后添加回去

  // console.log(version);
  if (version) {
    version = JSON.parse(version).content;
    const obj = {
      dataType: typeof version,
      content: version,
      type: type,
      datetime: new Date().getTime(),
    };
    window.localStorage.setItem(name, JSON.stringify(obj));
  }
};

//清除登录缓存
export const clearLogonStore = () => {
  window.sessionStorage.clear();
  window.localStorage.removeItem("wtjy-school_id");
  window.localStorage.removeItem("wtjy-userinfo");
  window.localStorage.removeItem("wtjy-routingList");
  window.localStorage.removeItem("wtjy-lastRequestTime");
};

// 表单序列化
export const serialize = (data) => {
  const list = [];
  Object.keys(data).forEach((ele) => {
    list.push(`${ele}=${data[ele]}`);
  });
  return list.join("&");
};

export function downloadFile(url, fileName) {
  // 创建一个隐藏的可下载链接
  let element = document.createElement("a");
  element.setAttribute("href", url);
  element.setAttribute("download", fileName || "download");

  // 触发点击
  document.body.appendChild(element);
  element.click();

  // 然后移除
  document.body.removeChild(element);
}
// blob文件下载
/**
 *
 * @param {*} res 后台返回的所有对象 res.data为blob对象
 * @param {*} filename  文件名  可以不传
 * @returns
 */
export function downloadBlob(res, filename) {
  const dispositionHeader =
    res.headers["content-disposition"] || res.headers["Content-Disposition"];
  if (dispositionHeader) {
    console.log(dispositionHeader);
    // 解析 Content-Disposition header 中的文件名 /filename*=UTF-8[^;=\n]*=((['"]).*?\2|[^;\n]*)/
    // attachment;filename*=UTF-8''
    const filenameRegex = dispositionHeader.replace(
      "attachment; filename*=UTF-8''",
      ""
    );
    if (filenameRegex) {
      filename = decodeURIComponent(filenameRegex);
    }
  }
  const blob = res.data;
  if (!blob) return;
  // 创建一个URL表示这个Blob对象
  const url = URL.createObjectURL(blob);
  // 创建一个隐藏的可下载链接
  const a = document.createElement("a");
  a.style.display = "none";
  a.href = url;
  if (filename) {
    a.download = filename;
  }
  // console.log(a);
  // 添加到DOM并触发点击，然后移除元素
  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);

  // 清理URL
  setTimeout(() => {
    URL.revokeObjectURL(url);
  }, 100); // 或者在点击事件回调中执行
}
