<template>
  <div class="user">
    <div class="filter-box">
      <span>学校：</span>
      <el-select
        filterable
        v-model="userId"
        placeholder="请选择学校"
        @change="handleSchoolChange(), mays()"
      >
        <el-option
          v-for="branch in branches"
          :key="branch.id"
          :label="branch.schoolName"
          :value="branch.userId"
        ></el-option>
      </el-select>
      <span>价格表：</span>
      <el-select
        filterable
        v-model="selectedPriceList"
        placeholder="请选择价格表"
        :disabled="isSelectDisabled"
        title="请先选择公司，才能选择价格表"
        @change="handlePriceChange()"
      >
        <el-option
          v-for="priceList in priceLists"
          :key="priceList.id"
          :label="priceList.name"
          :value="priceList.id"
        ></el-option>
      </el-select>

      <span>纸型：</span>
      <el-select
        v-model="search.paperType"
        filterable
        clearable
        placeholr="请选择纸型"
        @keyup.native="handleKeyup"
      >
        <el-option
          v-for="item in options"
          :key="item.id"
          :label="item.paperType"
          :value="item.paperType"
        ></el-option>
      </el-select>

      <span>印刷方式：</span>
      <el-select
        v-model="search.project"
        filterable
        clearable
        placeholder="请选择印刷方式"
        @keyup.native="handleKeyup"
      >
        <el-option
          v-for="method in printingMethods"
          :key="method.id"
          :label="method.printingMethod"
          :value="method.printingMethod"
        ></el-option>
      </el-select>

      <el-button
        type="primary"
        @click="getNew()"
        icon="el-icon-search"
        :disabled="isSelectDisabled1"
        >查询</el-button
      >
    </div>
    <div class="filter-box">
      <el-button
        v-loading="addloading"
        element-loading-text="正在新增价格表"
        type="primary"
        icon="el-icon-circle-plus-outline"
        @click="dialogVisible = true"
        :disabled="isSelectDisabled"
        >新增价格表</el-button
      >

      <el-button
        type="primary"
        @click="onDeletes"
        icon="el-icon-delete"
        :disabled="isSelectDisabled1"
        >删除价格表</el-button
      >
      <el-button
        v-loading="mloading"
        type="primary"
        @click="debouncedSaveQuerys"
        icon="el-icon-edit"
        class="ms"
        >保存
      </el-button>
    </div>

    <el-table
      :data="tableData"
      style="width: 100%"
      v-loading="loading"
      height="580"
      element-loading-text="拼命加载中"
      stripe
      border
      ref="tableRef"
    >
      <el-table-column prop="priceListName" label="价格表" align="center">
      </el-table-column>
      <el-table-column prop="project" label="印刷方式" align="center">
      </el-table-column>
      <el-table-column prop="paperType" label="纸型" align="center">
      </el-table-column>
      <el-table-column prop="singleDoubleSided" label="单双面" align="center">
      </el-table-column>

      <el-table-column label="加版单价(元)" align="center">
        <template slot-scope="scope">
          <el-input
            v-model="scope.row.extraUnitPrice"
            size="mini"
            @input="validatePrice(scope.row, 'extraUnitPrice')"
            @blur="validatePrice(scope.row, 'extraUnitPrice')"
          ></el-input>
        </template>
      </el-table-column>

      <el-table-column label="单价(元)" align="center">
        <template slot-scope="scope">
          <el-input
            v-model="scope.row.unitPrice"
            size="mini"
            @input="validatePrice(scope.row, 'unitPrice')"
            @blur="validatePrice(scope.row, 'unitPrice')"
          ></el-input>
        </template>
      </el-table-column>

      <el-table-column prop="address" label="操作" align="center" width="160">
        <template slot-scope="{ row }">
          <el-button type="text" @click="onEdit(row)">修改</el-button>
          <el-button type="text" class="btn-err" @click="onDelete(row.id)"
            >删除</el-button
          >
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      hide-on-single-page
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page="search.page"
      :page-sizes="[16, 40, 80, 100, 200]"
      :page-size="search.size"
      layout="total, sizes, prev, pager, next, jumper"
      :total="total"
    >
    </el-pagination>
    <editFrom ref="editFrom" @call-get-userlists="onQuery"></editFrom>
    <addFrom ref="addFrom" @call-get-userlists="fetchPriceList"></addFrom>

    <el-dialog
      :width="'30%'"
      title="新增价格表"
      :visible.sync="dialogVisible"
      :append-to-body="true"
      :close-on-click-modal="false"
      losk-scroll="false"
    >
      <span>价格表名:</span>
      <el-input
        v-model="priceListName"
        placeholder="请输入价格表名称"
        style="width: 300px"
      />
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button
          type="primary"
          :loading="loading2"
          element-loading-text="正在生成中"
          @click="(dialogVisible = false), shengchen()"
          >确 定</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>
<script>
import editFrom from "./components/editForm";
import addFrom from "./components/addForm";
import { getlistMethod, getpaper, getSchoollist } from "@/request/api/business";
import { delPrice, getPrice } from "@/request/api/price";
import {
  delPriceitem,
  getPricelist,
  getPricelists,
  newpriceitem,
  updatePriceitems,
} from "@/request/api/priceitem";
import { getStore } from "@/utils/util";

export default {
  name: "price",
  components: { editFrom, addFrom },
  data() {
    return {
      mloading: false,
      addloading: false,
      dialogVisible: false,
      printingMethods: [],
      options: [],
      priceListName: "",
      loading2: false,
      loading: false,
      isSelectDisabled: true,
      isSelectDisabled1: true,
      selectedBranch: "", // 当前选中的分公司ID
      selectedPriceList: "", // 当前选中的价格表ID
      userId: "",
      branches: {
        id: "",
        name: "",
      }, // 分公司数据列表
      priceLists: {
        id: "",
        name: "",
      }, // 价格表数据列表
      search: {
        size: 16,
        page: 1,
        project: "",
        paperType: "",
      },

      total: 0,
      pages: "",

      tableData: [],
    };
  },
  created() {
    // 初始化数据，例如从服务器获取分公司和价格表列表
    this.fetchSchools();
    this.fetchpapertype();
    this.fetchtype();
    //保存做防抖
    this.debouncedSaveQuerys = this.debounce(this.saveQuerys.bind(this), 100); // 可以根据需要调整延迟时间

    // this.fetchPriceLists();
  },
  mounted() {
    this.fetchPriceLists();
  },
  methods: {
    //修改单价和加版单价时，阻止非法字符的输入
    validatePrice(row, field) {
      const value = row[field];
      if (isNaN(value)) {
        this.$message.warning(`单价必须是一个有效的数字`);
        row[field] = ""; // 清空无效的输入
      } else if (value < 0) {
        this.$message.warning(`单价不能小于零`);
        row[field] = ""; // 清空负数输入
      }
    },
    async fetchpapertype() {
      try {
        // 使用axios或其他HTTP客户端库来请求数据
        const res = await getpaper();
        // console.log(res.data);
        this.options = res.data;
      } catch (error) {
        // console.error("Failed to fetch grades", error);
      }
    },

    async fetchtype() {
      try {
        // 使用axios或其他HTTP客户端库来请求数据
        const res = await getlistMethod();
        // console.log(res.data);
        this.printingMethods = res.data;
      } catch (error) {
        // console.error("Failed to fetch grades", error);
      }
    },
    handlePriceChange() {
      this.isSelectDisabled1 = false;
    },

    //默认生成价格表项
    async shengchen() {
      this.addloading = true;
      try {
        let params = { userId: this.userId, priceListName: this.priceListName };
        await newpriceitem(params);
        this.fetchPriceList();
        // this.onQuery();
        this.$message.success("生成成功");
      } catch {
        //
      } finally {
        this.addloading = false;
      }
    },
    //防抖
    debounce(func, wait) {
      let timeout;
      return function (...args) {
        const context = this;
        clearTimeout(timeout);
        timeout = setTimeout(async () => {
          await func.apply(context, args);
        }, wait);
      };
    },

    //保存修改的单价和加版单价信息
    async saveQuerys() {
      if (this.tableData.length === 0) {
        this.$message.warning("请修改后再进行保存");
        return;
      }
      let data = JSON.parse(JSON.stringify(this.tableData));
      try {
        this.mloading = true;
        await updatePriceitems(data);
        this.$message.success("保存成功");
      } catch {
        // 处理错误
      } finally {
        this.mloading = false;
      }
    },
    onEdit(row) {
      const dd = this.userId;
      this.$refs.editFrom.inits(row, dd);
    },
    addQuery() {
      const row = this.userId;
      this.$refs.editFrom.init(row);
    },
    addQuerys() {
      const row = this.userId;
      this.$refs.addFrom.init(row);
    },

    //判断是否选择了学校
    mays() {
      if (this.userId !== "") {
        this.isSelectDisabled = false;
      }
    },

    //获取全部用户的价格表项
    async fetchPriceLists() {
      this.loading = true;
      try {
        // 使用axios或其他HTTP客户端库来请求数据
        const res = await getPricelists(this.selectedPriceList, this.search);
        // console.log(res);
        res.data.forEach((item) => {
          if (item.createTime) {
            item.formattedCreateTime = new Date(
              item.createTime
            ).toLocaleString();
          }
        });
        this.tableData = res.data.records;
        // console.log(res.data.total);
        this.total = res.data.total;
        this.pages = Math.ceil(this.total / this.search.size);
      } catch (error) {
        // console.error("Failed to fetch schools", error);
      } finally {
        this.loading = false;
      }
    },
    //获取学校信息
    async fetchSchools() {
      try {
        const userInfo = getStore({ name: "userinfo" });
        // 使用axios或其他HTTP客户端库来请求数据
        const params = {
          userId: userInfo.id,
          roleType: userInfo.roleType - 2,
        };
        // 使用axios或其他HTTP客户端库来请求数据
        const res = await getSchoollist(params);
        // console.log(res);
        this.branches = res.data.records; // 假设后端返回的数据直接赋值给schools
      } catch (error) {
        // console.error("Failed to fetch schools", error);
      }
    },

    handleSchoolChange() {
      this.fetchPriceList();
      this.selectedPriceList = "";
    },

    //获取当前用户的价格表
    async fetchPriceList() {
      try {
        // 使用axios或其他HTTP客户端库来请求数据
        // console.log(this.userId);
        const res = await getPrice(this.userId);
        // console.log(res);

        this.priceLists = res.data.records; // 假设后端返回的数据直接赋值给schools
      } catch (error) {
        // console.error("Failed to fetch schools", error);
      }
    },

    getNew() {
      this.search.page = 1;
      this.onQuery();
    },
    //搜索功能
    async onQuery() {
      try {
        // 使用axios或其他HTTP客户端库来请求数据
        // console.log(this.selectedPriceList);
        let params = {
          size: this.search.size,
          current: this.search.page,
          project: this.search.project,
          paperType: this.search.paperType,
        };
        const res = await getPricelist(this.selectedPriceList, params);
        // console.log(res.data);
        this.tableData = res.data.records;

        // console.log(res.data.total);
        this.total = res.data.total;
        this.pages = Math.ceil(this.total / this.search.size); // 假设后端返回的数据直接赋值给schools
      } catch (error) {
        // console.error("Failed to fetch schools", error);
      }
    },

    // 删除价格表项
    async onDelete(id) {
      // 使用Element UI的$confirm弹出确认框
      this.$confirm("确定要删除这条价格表项吗？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(async () => {
          try {
            await delPriceitem(id);
            // 成功删除后的处理
            // this.tableData = res.data.records; // 假设后端返回更新后的数据列表
            this.onQuery();
            this.$message.success("删除成功");
          } catch (error) {
            // console.error("删除失败", error);
            // this.$message.error("删除失败，请重试");
          }
        })
        .catch(() => {
          // 用户点击取消后的处理
          // this.$message.info("已取消删除");
        });
    },

    // 删除价格表
    async onDeletes() {
      // 如果selectedPriceList为空，直接提示并返回
      if (!this.selectedPriceList || this.selectedPriceList.length === 0) {
        this.$message.warning("请先选择要删除的价格表");
        return;
      }

      this.$confirm("确定要删除这个价格表吗？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(async () => {
          try {
            await delPrice(this.selectedPriceList);
            // 成功删除后的处理
            // this.tableData = res.data.records; // 假设后端返回更新后的数据列表
            this.$message.success("删除成功");
            this.selectedPriceList = "";
            this.fetchPriceList();
          } catch (error) {
            // this.$message.error("删除失败，请重试");
            // console.error("删除失败", error);
          }
        })
        .catch(() => {
          // 用户点击取消后的处理
          // this.$message.info("已取消删除");
        });
    },

    handleSizeChange(val) {
      this.search.size = val;
      const table = this.$refs.tableRef;
      const divData = table.bodyWrapper;
      divData.scrollTop = 0;
      this.onQuery(); // 页大小改变时重新加载数据
    },

    handleCurrentChange(val) {
      const table = this.$refs.tableRef;
      const divData = table.bodyWrapper;
      divData.scrollTop = 0;
      this.search.page = val;
      this.onQuery(); // 页码改变时重新加载数据
    },
  },
};
</script>
<style lang="scss" scoped>
.ms {
  margin-left: auto;
}
</style>
