<template>
  <div class="userOperation">
    <div class="icon-item" @click="toggleFullScreen(3)">
      <i class="el-icon-full-screen"></i>
    </div>
    <el-popover
      placement="bottom"
      :width="260"
      trigger="click"
      popper-class="popper-model"
    >
      <template #reference>
        <div class="avatar-img-box">
          <div class="avatar-img">
            <img v-if="userinfo.avatar" :src="userinfo.avatar" alt="" />
            <img v-else src="@/assets/common/avatar.png" alt="" />
          </div>
          <span>{{ userinfo.username }}</span>
        </div>
      </template>

      <div class="model-box">
        <div class="model-avatar-img">
          <img v-if="userinfo.avatar" :src="userinfo.avatar" alt="" />
          <img v-else src="@/assets/common/avatar.png" alt="" />
        </div>
        <div class="name">{{ userinfo.username }}</div>
        <div>
          {{ userinfo.tenantId | getName(roleTypeGroup) }}
        </div>
        <div class="model-btn">
          <el-button type="primary" @click="linkpeople" plain size="small">
            个人中心
          </el-button>
          <el-button type="text" plain size="small" @click="openOut()">
            退出登录
          </el-button>
        </div>
      </div>
    </el-popover>
  </div>
</template>
<script>
import { roleTypeGroup } from "@/utils/dictionaries";
import screenfull from "screenfull";
import { clearLogonStore } from "@/utils/util";
export default {
  name: "userOperation",
  data() {
    return {
      roleTypeGroup: roleTypeGroup,
      userinfo: {},
    };
  },
  created() {},
  methods: {
    linkpeople() {
      this.$router.push({
        path: "/account/index",
      });
    },
    openOut() {
      clearLogonStore();
      this.$router.push({
        path: "/login",
      });
    },
    toggleFullScreen() {
      if (!screenfull.enabled) {
        // 检查当前浏览器是否支持全屏
        screenfull.toggle(); // 切换全屏状态
      } else {
        alert("您的浏览器不支持全屏API！");
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.popper-model {
  .model-box {
    text-align: center;
    .model-btn {
      display: flex;
      justify-content: space-between;
      padding: 12px 10px 0;
    }
    .name {
      font-size: 18px;
    }
    .model-avatar-img {
      display: inline-flex;
      justify-content: center;
      align-items: center;
      box-sizing: border-box;
      text-align: center;
      overflow: hidden;
      width: 80px;
      height: 80px;
      border-radius: 4px;
      flex-shrink: 0;
      margin-right: 12px;
      img {
        display: block;
        height: 100%;
      }
    }
  }
}
.userOperation {
  flex-shrink: 0;
  display: flex;
  height: 50px;
  padding: 0 20px;
  .avatar-img-box {
    display: flex;
    align-items: center;
    padding: 0 10px;
    cursor: pointer;
    user-select: none;
    height: 50px;
    &:hover {
      background-color: #f5f5f5;
    }
    span {
      flex-shrink: 0;
    }
    .avatar-img {
      display: inline-flex;
      justify-content: center;
      align-items: center;
      box-sizing: border-box;
      text-align: center;
      overflow: hidden;
      width: 25px;
      height: 25px;
      border-radius: 4px;
      flex-shrink: 0;
      // margin-right: 12px;
      img {
        display: block;
        height: 100%;
      }
    }
  }
  @keyframes twinkle {
    0% {
      transform: scale(0);
    }

    80% {
      transform: scale(1.2);
    }

    to {
      transform: scale(1);
    }
  }
  .icon-item {
    height: 100%;
    width: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    &:hover {
      background-color: #f5f5f5;
      i {
        animation: twinkle 0.3s ease-in-out;
      }
    }
  }
}
</style>
