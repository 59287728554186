import { menuItem } from "./menu";

// 获取view下文件
const files = require.context("@/views/", true, /\.vue$/);
let keyArr = [];
files.keys().forEach((key) => {
  keyArr.push(key);
});
let routeArr = [];
// 循环菜单 获取需要添加的数组
menuItem.map((item) => {
  if (item.children && item.children.length > 0) {
    item.children.map((v) => {
      const obj = {
        path: v.url,
        name: v.url,
        meta: {
          title: v.name,
          url: v.url,
          keepAlive: true,
        },
      };
      if (keyArr.indexOf(`.${v.url}.vue`) != -1) {
        obj.component = (resolve) => require([`@/views${v.url}.vue`], resolve);
      } else {
        obj.component = (resolve) =>
          require([`@/views/developed/index.vue`], resolve);
      }
      routeArr.push(obj);
    });
  } else {
    const obj = {
      path: item.url,
      name: item.url,
      meta: {
        title: item.name,
        url: item.url,
        keepAlive: true,
      },
    };
    if (keyArr.indexOf(`.${item.url}.vue`) != -1) {
      obj.component = (resolve) => require([`@/views${item.url}.vue`], resolve);
    } else {
      obj.component = (resolve) =>
        require([`@/views/developed/index.vue`], resolve);
    }
    routeArr.push(obj);
  }
});

export { routeArr };
