<template>
  <div class="customer">
    <div class="filter-box">
      <el-input v-model="search.keyword" placeholder="请输入客户名查询">
        <i
          class="el-icon-search el-input__icon"
          slot="suffix"
          @click="getNewList()"
        >
        </i>
      </el-input>
      <el-button type="primary" icon="el-icon-search" @click="getNew()"
        >查询</el-button
      >
      <!-- <el-button
        type="primary"
        icon="el-icon-circle-plus-outline"
        @click="editFrom(row, 1)"
        >新增</el-button
      > -->
    </div>
    <el-table
      v-loading="loading"
      element-loading-text="拼命加载中"
      :data="tableData"
      height="580"
      style="width: 100%"
      stripe
      border
      ref="tableRef"
    >
      <el-table-column type="index" label="序号" align="center">
      </el-table-column>
      <el-table-column prop="schoolName" label="客户名" align="center">
      </el-table-column>
      <!-- <el-table-column prop="type" label="学段" align="center">
      </el-table-column> -->
      <el-table-column
        prop="formattedCreateTime"
        label="注册时间"
        align="center"
      >
      </el-table-column>
      <el-table-column prop="address" label="操作" align="center">
        <template slot-scope="{ row }">
          <el-button type="text" @click="editFrom(row, 0)">修改</el-button>
          <el-button type="text" @click="editGrade(row)">年级管理</el-button>
          <el-button type="text" @click="editSubject(row)">科目管理</el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      hide-on-single-page
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page="search.page"
      :page-sizes="[16, 40, 80, 100, 200]"
      :page-size="search.limit"
      layout="total, sizes, prev, pager, next, jumper"
      :total="total"
      :total-pages="pages"
    >
    </el-pagination>

    <editFrom ref="editFrom" @call-get-userlists="getNew"></editFrom>
    <gradeItem ref="gradeItem"></gradeItem>
    <subjectItem ref="subjectItem"></subjectItem>
    <addForm ref="addFrom" @call-get-userlists="getNew"></addForm>
  </div>
</template>
<script>
import editFrom from "./components/editFrom.vue";
import gradeItem from "./components/gradeItem.vue";
import subjectItem from "./components/subjectItem.vue";
import addForm from "./components/addForm.vue";
import { levelGroup } from "@/utils/dictionaries";
import { getCustomerlist } from "@/request/api/customer";

export default {
  name: "customer",
  components: { editFrom, gradeItem, subjectItem, addForm },
  data() {
    return {
      loading: false,
      search: {
        keyword: "",
        page: 1, // 当前页码
        limit: 16, // 每页显示数量
      },
      current: 1,
      total: 0,
      pages: 0,
      levelGroup: levelGroup,
      tableData: [],
    };
  },
  created() {
    // this.getNewList();
  },
  mounted() {},
  computed: {},
  methods: {
    editSubject(row) {
      this.$refs.subjectItem.init(row);
    },
    getNew() {
      this.search.page = 1;
      this.getNewList();
    },
    async getNewList() {
      this.loading = true;
      try {
        const params = {
          size: this.search.limit,
          current: this.search.page,
          keyword: this.search.keyword,
        };

        const res = await getCustomerlist(params);
        // console.log(res);
        res.data.records.forEach((item) => {
          if (item.createTime) {
            const date = new Date(item.createTime);
            item.formattedCreateTime = `${date.getFullYear()}年${(
              "0" +
              (date.getMonth() + 1)
            ).slice(-2)}月${("0" + date.getDate()).slice(-2)}日`;
          }
        });
        this.tableData = res.data.records;
        this.total = res.data.total;
        this.pages =
          res.data.pages || Math.ceil(this.total / this.search.limit);
      } catch (error) {
        // console.error("Error fetching customer list:", error);
      } finally {
        this.loading = false;
      }
      // this.$message({
      //   message: "获取成功！",
      //   type: "success",
      //   showClose: true,
      // });
    },

    editGrade(row) {
      if (row.userId == null) {
        //
        this.$message.warning("当前学校没有和用户关联");
      } else {
        this.$refs.gradeItem.init(row);
      }
    },
    editFrom(row, operation) {
      if (operation) {
        // console.log(data);
        this.$refs.editFrom.init(row);
      } else {
        this.$refs.addFrom.init(row);
      }
    },
    handleSizeChange(val) {
      const table = this.$refs.tableRef;
      const divData = table.bodyWrapper;
      divData.scrollTop = 0;
      this.search.limit = val;
      this.getNewList(); // 页大小改变时重新加载数据
    },

    handleCurrentChange(val) {
      const table = this.$refs.tableRef;
      const divData = table.bodyWrapper;
      divData.scrollTop = 0;
      this.search.page = val;
      this.getNewList(); // 页码改变时重新加载数据
    },
  },
};
</script>
<style lang="scss" scoped></style>
