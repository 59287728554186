import router from "@/router";
import { getStore, setStore } from "@/utils/util";
export default {
  state: {
    routingList: [],
    //顶部菜单有更新
    menuChange: 0,
  },
  getters: {
    // 获取顶部菜单列表
    GET_ROUTING_LIST(state) {
      return state.routingList;
    },
    GET_MENU_CHANGE(state) {
      return state.menuChange;
    },
  },
  actions: {},
  mutations: {
    SET_MENU_CHANGE(state) {
      state.menuChange = state.menuChange++;
    },
    // 设置顶部菜单列表
    SET_ROUTING_LIST(state, route) {
      let routingList = getStore({ name: "routingList" }) || [];
      let data = {
        meta: route.meta,
        path: route.path,
        title: route.meta.title,
      };
      let array = routingList.map((item) => item.path);
      state.routingList = routingList;
      if (array.indexOf(data.path) == -1 && !data.meta.notRouter) {
        if (route.meta.title) {
          state.routingList.push(data);
        }
      }
      setStore({ name: "routingList", content: state.routingList });
    },
    // 删除顶部菜单列表
    DET_ROUTING_LIST(state, data) {
      const { path, route } = data;
      let routingList = getStore({ name: "routingList" }) || [];
      routingList = routingList.filter((item) => item.path != path);
      if (route.path == path) {
        router.push({
          path: routingList[routingList.length - 1].path,
        });
      }
      state.routingList = routingList;
      setStore({ name: "routingList", content: state.routingList });
    },
  },
};
