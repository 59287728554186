<template>
  <div class="edit-form">
    <el-dialog
      title="添加价格表"
      :visible.sync="dialogVisible"
      width="380px"
      :close-on-click-modal="false"
    >
      <el-form :model="ruleForm" ref="ruleForm" label-width="70px">
        <el-form-item label="学校" prop="userId">
          <el-select
            clearable
            v-model="ruleForm.userId"
            placeholder="请选择学校"
            style="width: 100%"
            @change="fetchBranches"
          >
            <el-option
              v-for="branch in branches"
              :key="branch.id"
              :label="branch.schoolName"
              :value="branch.userId"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="表名" prop="name">
          <el-input v-model="ruleForm.name" placeholder="请输入表名"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取消</el-button>
        <el-button type="primary" @click="addPriceup">确定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { getSchoollist } from "@/request/api/business";
import { addPrice } from "@/request/api/price";
import { getStore } from "@/utils/util";

export default {
  name: "EditForm",
  data() {
    return {
      dialogVisible: false,
      ruleForm: { name: "", userId: "" },
      branches: [],
    };
  },
  created() {
    this.fetchBranches();
  },
  methods: {
    init() {
      this.dialogVisible = true;
    },
    async fetchBranches() {
      try {
        const userInfo = getStore({ name: "userinfo" });
        // 使用axios或其他HTTP客户端库来请求数据
        const params = {
          userId: userInfo.id,
          roleType: userInfo.roleType - 2,
        };
        const { data } = await getSchoollist(params);
        this.branches = data.records;
      } catch (error) {
        // 可以在这里处理错误，比如提示用户
        // console.error("Failed to fetch branches", error);
      }
    },
    async addPriceup() {
      try {
        await addPrice(this.ruleForm);
        this.$message.success("添加成功");
        this.$emit("call-get-userlists");
        this.dialogVisible = false;
      } catch (error) {
        // this.$message.error("添加失败，请重试");
        // console.error("Failed to add price", error);
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
