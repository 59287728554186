<template>
  <div class="operation">
    <div class="nav-tab">
      <el-scrollbar ref="navBoxRef" @scroll="setScroll">
        <div class="scrollbar-flex-content">
          <div
            class="scrollbar-item"
            :class="{
              'nav-tab-on': item.path == $route.meta.url,
            }"
            v-for="(item, index) in GET_ROUTING_LIST"
            :key="index"
            @click="linkTo(item.path)"
            :ref="item.path"
            :id="item.path"
          >
            {{ item.title }}
            <i
              class="el-icon-close"
              v-if="GET_ROUTING_LIST.length > 1"
              @click.stop="delItem(item)"
            ></i>
          </div>
          <div class="nav-active" ref="navRef"></div>
        </div>
      </el-scrollbar>
    </div>
    <userOperation></userOperation>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import userOperation from "./userOperation.vue";
export default {
  name: "operation",
  components: { userOperation },
  data() {
    return {};
  },
  computed: {
    ...mapGetters(["GET_ROUTING_LIST"]),
  },
  watch: {
    $route: {
      deep: true,
      handler() {
        this.$nextTick(() => {
          this.setNav();
        });
      },
    },
  },
  created() {},
  mounted() {
    this.setNav();
  },
  methods: {
    setScroll(event) {
      console.log(event.target);
    },
    linkTo(path) {
      this.$router.push({
        path: path,
      });
    },
    setNav() {
      let dom = this.$refs[this.$route.path];
      if (dom) {
        let { clientWidth, clientHeight, offsetLeft } = dom[0];
        this.$refs.navRef.style.width = clientWidth + "px";
        this.$refs.navRef.style.height = clientHeight + "px";
        this.$refs.navRef.style.left = offsetLeft + "px";
        if (!this.$refs.navBoxRef || !this.$refs.navBoxRef.wrap) return;
        let FclientWidth = this.$refs.navBoxRef.wrap.clientWidth;
        let FscrollLeft = this.$refs.navBoxRef.wrap.scrollLeft;

        //添加判断  如果 所选不在可视区域 就滚动滚动条
        if (
          offsetLeft + clientWidth > FclientWidth + FscrollLeft ||
          offsetLeft + clientWidth < FscrollLeft
        ) {
          this.$refs.navBoxRef.wrap.scrollLeft = offsetLeft;
        }
      }
    },
    delItem(to) {
      const data = {
        path: to.path,
        route: this.$route,
      };
      this.$store.commit("DET_ROUTING_LIST", data);
      this.$nextTick(() => {
        this.setNav();
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.operation {
  display: flex;
  height: 50px;
  width: 100%;
  background-color: #ffffff;
  .el-scrollbar {
    height: 100%;
  }
  .nav-tab {
    width: 100%;
  }
  .nav-active {
    position: absolute;
    height: 50px;
    background-color: #f5f5f5;
    transition: all 0.2s;
    -webkit-transition: all 0.2s;
  }
  ::v-deep .scrollbar-flex-content {
    display: flex;
    height: 100%;
    position: relative;
    // .nav-tab-on {
    // background-color: #f5f5f5;
    //}
    .scrollbar-item {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 0 20px;
      flex-shrink: 0;
      cursor: pointer;
      height: 50px;
      z-index: 1;
      transition: all 0.2s;
      user-select: none;
      -webkit-transition: all 0.2s;
      &:hover {
        background-color: #f5f5f5;
      }
      i {
        font-size: 12px;
        color: rgb(0, 0, 0);
        padding: 2px;
        margin: 2px 0 0 4px;
        &:hover {
          background-color: #409eff;
          border-radius: 50%;
          color: #ffffff;
        }
      }
    }
  }
}
</style>
