<template>
  <div class="classForm">
    <el-dialog
      title="班级管理"
      :visible.sync="Visible"
      :close-on-click-modal="false"
      width="1000px"
    >
      <el-table
        :data="tableData"
        :height="computedHeight"
        style="width: 100%"
        element-loading-text="拼命加载中"
        border
        v-loading="loading"
        id="ruleForm"
      >
        <el-table-column
          label="班级"
          prop="className"
          align="center"
        ></el-table-column>

        <el-table-column label="总人数" align="center">
          <template slot-scope="scope">
            <el-input
              v-model="scope.row.allCount"
              placeholder="请输入总人数"
            ></el-input>
          </template>
        </el-table-column>

        <!-- 动态渲染科目列 -->
        <el-table-column
          height="100"
          v-for="(item, index) in subjuectList"
          :key="index"
          :label="item.name"
          align="center"
        >
          <template slot-scope="{ row }">
            <el-input
              v-model="row[item.id]"
              :placeholder="`请输入${item.name}人数`"
            ></el-input>
          </template>
        </el-table-column>
      </el-table>
      <span slot="footer" class="dialog-footer">
        <el-button @click="Visible = false">取消</el-button>
        <el-button type="primary" @click="submit()">确定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { getsujectnum } from "@/request/api/clazz";
import { getSubjectMiddle, updateSubject } from "@/request/api/subject";

export default {
  name: "classItem",
  data() {
    return {
      loading: false,

      Visible: false,
      tableData: [], // 班级数据包含科目人数作为动态属性
      allSubjects: [], // 新增：用于存储所有班级的科目
      subjuectList: [], //全部的科目信息(含有id和科目名)
      sujectNum: [], //对应班级的科目信息
      validSubjectIds: [],
      userId: "",
    };
  },
  computed: {
    computedHeight() {
      const rowHeight = 50; // 每行的大致高度，可以根据实际情况调整
      const maxHeight = 580; // 最大高度限制
      const totalRows = this.tableData.length;
      let height = 50 + rowHeight * totalRows;

      // 确保高度不超过最大值
      if (height > maxHeight) {
        height = maxHeight;
      }

      return height;
    },
  },
  directives: {
    // focusOnKey: {
    //   bind(el, binding, vnode) {
    //     const instance = vnode.context; // Capture the Vue instance
    //     el.addEventListener("keydown", (event) => {
    //       switch (event.key) {
    //         case "ArrowUp":
    //           instance.moveFocus(-1);
    //           break;
    //         case "ArrowDown":
    //           instance.moveFocus(1);
    //           break;
    //         case "ArrowLeft":
    //           instance.moveFocus(-1, true);
    //           break;
    //         case "ArrowRight":
    //           instance.moveFocus(1, true);
    //           break;
    //       }
    //     });
    //   },
    // },
  },
  mounted() {
    // 在组件挂载后添加键盘事件监听器
    document.addEventListener("keydown", this.handleKeyDown);
  },
  beforeDestroy() {
    // 在组件销毁前移除监听器
    document.removeEventListener("keydown", this.handleKeyDown);
  },
  methods: {
    handleKeyDown(event) {
      // 根据按键执行相应逻辑
      if (event.keyCode === 13) {
        this.nextInput(event);
      }
    },
    nextInput() {
      const ruleForm = document.getElementById("ruleForm");
      const inputs = ruleForm.getElementsByClassName("el-input__inner");
      const focusedElement = document.activeElement;

      if (focusedElement.tagName == "INPUT") {
        // 遍历每一个input 看是否都有值
        const result = [...inputs].every((item) => item.value);
        if (!result) {
          // 找出那个没有值的
          const emptyInput = [...inputs].find((item) =>
            [null, undefined, ""].includes(item.value)
          );
          emptyInput.focus();
        } else {
          const elIndex = [...inputs].findIndex((item) => {
            return item.value == focusedElement.value;
          });
          if (
            ![null, undefined, ""].includes(elIndex) &&
            elIndex < inputs.length - 1
          ) {
            inputs[elIndex + 1].focus();
          }
        }
      }
    },
    //快捷键，实现通过上下左右键，移动输入位置
    moveFocus(direction, isHorizontal = false) {
      if (!isHorizontal) {
        // 动态获取列数
        const firstRowInputs = this.$el.querySelectorAll(
          "tr:first-child input"
        );
        const numColumns = firstRowInputs.length;

        // 上下键逻辑，保持列位置不变
        const inputs = this.$el.querySelectorAll("tr input"); // 获取所有班级的输入框
        let currentInput = document.activeElement;
        if (!currentInput) {
          inputs[0]?.focus();
          return;
        }

        let currentRowIndex = -1;
        let currentColIndex = -1;
        for (let i = 0; i < inputs.length; i++) {
          if (inputs[i].isSameNode(currentInput)) {
            currentRowIndex = Math.floor(i / numColumns);
            currentColIndex = i % numColumns;
            break;
          }
        }

        // 计算新的行索引
        const rowCount = this.$el.querySelectorAll(
          ".el-table__body-wrapper tbody tr"
        ).length;
        const newRowIndex = (currentRowIndex + direction + rowCount) % rowCount;

        // 获取新行
        const tableRows = this.$el.querySelectorAll(
          ".el-table__body-wrapper tbody tr"
        );
        const newInputRow = tableRows[newRowIndex];

        if (newInputRow) {
          // 在新行中找到对应列的输入框
          const newInput =
            newInputRow.querySelectorAll("input")[currentColIndex];
          if (newInput) {
            newInput.focus();
          } else {
            // console.warn("");
          }
        } else {
          // console.warn("");
        }
      } else {
        // 左右键逻辑，切换当前班级内的输入框
        const inputs = this.$el.querySelectorAll("tr input"); // 获取所有班级的输入框
        let currentIndex = Array.from(inputs).indexOf(document.activeElement);
        currentIndex =
          (currentIndex + direction + inputs.length) % inputs.length;
        inputs[currentIndex]?.focus();
      }
    },

    async init(rows, userId) {
      this.Visible = true;
      this.userId = userId;
      // console.log(this.userId);
      this.loading = true;
      this.tableData = rows.classes.slice(); // 创建班级数据的副本，避免直接修改原数据

      for (const classRow of this.tableData) {
        await this.fetchAndAssignSubjectCounts(classRow.classId, classRow); // 直接传入当前班级对象以更新
      }
      this.tableData = JSON.parse(JSON.stringify(this.tableData));
      this.loading = false;
    },

    // 获取科目的人数信息
    async fetchAndAssignSubjectCounts(classId, classRow) {
      try {
        // 检查 classId 是否为空或null
        // if (classId === null || classId === "") {
        //   this.$message.warning("该年级信息和班级信息还未进行录入！");
        //   return; // 直接返回，不执行后续操作
        // }

        // 获取到所有的科目信息
        // const res = await getSubject();
        const aa = await getSubjectMiddle(this.userId);
        const newData = aa.data.map((item) => {
          return {
            id: item.subjectId,
            name: item.subjectName,
          };
        });
        // console.log(newData);
        const res = newData;

        // 确保 classId 有效时才执行获取科目人数的操作
        let result;
        if (classId) {
          result = await getsujectnum(classId);

          // 赋值
          this.sujectNum = result.data;
          this.subjuectList = res;
          this.validSubjectIds = this.subjuectList.map((subject) => subject.id);

          // 对字符进行拼接
          this.subjuectList.forEach((subject) => {
            // 初始化默认值
            subject.count = "";
            // 找到匹配的科目计数
            const matchedCount = result.data.find(
              (item) => item.subject_id === subject.id
            );
            if (matchedCount) {
              // 如果找到匹配项，则覆盖默认值
              subject.count = matchedCount.count;
            }
            classRow[subject.id] = subject.count;
          });
        }
      } catch (error) {
        // this.$message.error(`获取班级 ${classId} 科目信息失败`);
      }
    },

    // 提交按钮
    async submit() {
      this.loading = true;
      const formattedData = this.tableData.map((classItem) => {
        // 先提取allCount，避免在后续循环中处理
        const allCount = classItem.allCount;

        // 初始化需要的结构
        const classData = {
          classId: classItem.classId,
          allCount: Number(allCount),
          counts: [],
          subjectIds: [],
        };

        // 假设科目ID是从1到10的整数
        // const validSubjectIds = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];
        const validSubjectIds = this.validSubjectIds;

        for (const key in classItem) {
          const subjectId = Number(key);
          if (subjectId && validSubjectIds.includes(subjectId)) {
            // 确保key是有效的科目ID，并且在1到10的范围内
            classData.subjectIds.push(subjectId);
            classData.counts.push(Number(classItem[key])); // 转换为数值类型
          }
        }

        return classData;
      });

      console.log("格式化后的数据:", formattedData);
      try {
        let data = JSON.parse(JSON.stringify(formattedData));
        // await new Promise((resolve) => setTimeout(resolve, 1000)); // 延迟1秒
        await updateSubject(data);
        this.loading = false;
        this.$message.success("修改成功");
        this.Visible = false;
        this.$emit("call-get-userlists");
      } catch (error) {
        this.loading = false;
        // this.$message.error("修改失败");
      }
    },
  },
};
</script>
