import { getStore } from "@/utils/util";
const menuItem = [
  {
    name: "客户管理",
    url: "/customer/list",
    icon: require("@/assets/menu/icon_user.png"),
    icon_on: require("@/assets/menu/icon_user_on.png"),
  },
  {
    name: "业务列表",
    url: "/business/list",
    icon: require("@/assets/menu/icon_busines.png"),
    icon_on: require("@/assets/menu/icon_busines_on.png"),
  },
  {
    name: "价格列表",
    url: "/price/list",
    icon: require("@/assets/menu/icon_price.png"),
    icon_on: require("@/assets/menu/icon_price_on.png"),
  },
  {
    name: "统计报表",
    url: "/statistical/list",
    icon: require("@/assets/menu/icon_start.png"),
    icon_on: require("@/assets/menu/icon_start_on.png"),
  },
  {
    name: "系统管理",
    url: "/system",
    icon: require("@/assets/menu/icon_system.png"),
    icon_on: require("@/assets/menu/icon_system_on.png"),
    children: [
      { name: "数据迁移", url: "/migrateData/list" },
      { name: "用户管理", url: "/user/list" },
      { name: "日志管理", url: "/log/list" },
      { name: "科目管理", url: "/subject/list" },
    ],
  },
  {
    name: "账户信息",
    url: "/account/index",
    icon: require("@/assets/menu/icon_info.png"),
    icon_on: require("@/assets/menu/icon_info_on.png"),
  },
];
// 超级管理员
const roleAdmin = [
  "/customer/list",
  "/business/list",
  "/price/list",
  "/statistical/list",
  "/account/index",
  "/system",
];
// 学校管理员
const roleSchoolAdmin = [
  "/business/list",
  "/price/list",
  "/statistical/list",
  "/account/index",
];
// 文印室
const rolePrinting = ["/business/list", "/account/index"];
// 根据权限列表获取菜单
export const getMenu = () => {
  let userinfo = getStore({ name: "userinfo" });
  if (!userinfo) return [];
  // 用户角色
  let { roleType } = userinfo;
  // 根据角色获取菜单
  let roleArr = [];
  let returnArr = [];

  roleArr =
    roleType == 1 ? roleAdmin : roleType == 2 ? roleSchoolAdmin : rolePrinting;
  returnArr = menuItem.filter((item) => {
    if (roleArr.indexOf(item.url) != -1) {
      return item;
    }
  });
  return returnArr;
};

export { menuItem };
