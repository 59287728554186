<template>
  <div class="user">
    <div class="filter-box">
      <el-input v-model="searchs.keyword" placeholder="请输入姓名进行搜索">
        <i
          class="el-icon-search el-input__icon"
          slot="suffix"
          @click="getNew()"
        >
        </i>
      </el-input>
      <el-button type="primary" icon="el-icon-search" @click="getNew()"
        >查询</el-button
      >
      <el-button
        type="primary"
        icon="el-icon-circle-plus-outline"
        @click="editFrom(row, 1)"
        >新增</el-button
      >
    </div>

    <el-table
      v-loading="loading"
      element-loading-text="拼命加载中"
      :data="tableData"
      height="580"
      style="width: 100%"
      stripe
      border
      ref="tableRef"
    >
      <el-table-column prop="username" label="用户名" align="center">
      </el-table-column>
      <!-- <el-table-column prop="name" label="姓名" align="center">
      </el-table-column> -->
      <el-table-column prop="roleType" label="角色" align="center">
        <template slot-scope="{ row }">
          {{ formatRoleType(row.roleType) }}
        </template>
      </el-table-column>
      <!-- <el-table-column prop="type" label="学段" align="center">
        
      </el-table-column> -->
      <el-table-column
        prop="schoolName"
        label="学校"
        align="center"
        show-overflow-tooltip
      >
      </el-table-column>
      <el-table-column
        prop="phone"
        label="手机号"
        align="center"
        show-overflow-tooltip
      >
      </el-table-column>
      <el-table-column
        prop="formattedCreateTime"
        label="注册时间"
        align="center"
      >
      </el-table-column>
      <el-table-column prop="address" label="操作" align="center" width="160">
        <template slot-scope="{ row }">
          <el-button type="text" @click="rePassword(row)">重置密码</el-button>
          <el-button type="text" @click="editFrom(row, 0)">修改</el-button>
          <el-button type="text" class="btn-err" @click="delForm(row)"
            >删除</el-button
          >
        </template>
      </el-table-column>
    </el-table>
    <editFrom ref="editFrom" @call-get-userlists="getUserlists"></editFrom>
    <addFrom ref="addFrom" @call-get-userlists="getUserlists"></addFrom>
    <checkPassword
      ref="checkPassword"
      @call-get-userlists="getUserlists"
    ></checkPassword>
    <el-pagination
      hide-on-single-page
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page="searchs.current"
      :page-sizes="[16, 40, 80, 100, 200]"
      :page-size="searchs.size"
      layout="total, sizes, prev, pager, next, jumper"
      :total="total"
      :total-pages="pages"
    >
    </el-pagination>
  </div>
</template>
<script>
import checkPassword from "./components/checkPassword.vue";
import editFrom from "./components/editFrom.vue";
import addFrom from "./components/addFrom.vue";
import { levelGroup } from "@/utils/dictionaries";
import { getUserlist, remakePassword } from "@/request/api/login";
export default {
  name: "user",
  components: { editFrom, addFrom, checkPassword },
  data() {
    return {
      loading: false,
      searchs: {
        current: 1, // 当前页码
        size: 16, // 每页显示数量
        keyword: "",
      },
      total: 0,
      pages: "",
      levelGroup: levelGroup,
      tableData: [],
    };
  },
  created() {},
  mounted() {
    this.getUserlists();
    this.formatRoleType(this.tableData.roleType);
  },
  methods: {
    formatRoleType(roleType) {
      switch (roleType) {
        case -1:
          return "超级管理员";
        case 0:
          return "院校管理员";
        case 1:
          return "文印室";
        default:
          return roleType; // 如果没有匹配，返回原始值
      }
    },
    async rePassword(row) {
      try {
        // 显示确认弹窗
        const confirmResult = await this.$confirm(
          "此操作将重置用户的密码，是否继续？",
          "提示",
          {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning",
          }
        ).catch((err) => err);

        // 如果用户点击确定，则继续执行重置密码操作
        if (confirmResult !== "confirm") {
          return;
        }

        await remakePassword({ userId: row.id });
        this.$message({
          message: "重置密码成功！",
          type: "success",
        });
      } catch (error) {
        // 处理错误
        // console.error(error);
      }
    },
    // 删除用户
    async delForm(row) {
      this.$refs.checkPassword.init(row);
    },

    getNew() {
      this.searchs.current = 1;
      this.getUserlists();
    },

    // 获取用户列表
    async getUserlists() {
      this.loading = true; // 在请求开始时设置loading为true
      try {
        const res = await getUserlist(this.searchs);

        // 新增逻辑：转换角色类型数字为文本
        res.data.records.forEach((item) => {
          if (item.createTime) {
            const date = new Date(item.createTime);
            item.formattedCreateTime = `${date.getFullYear()}年${(
              "0" +
              (date.getMonth() + 1)
            ).slice(-2)}月${("0" + date.getDate()).slice(-2)}日`;
          }
          // 转换角色类型;
          // switch (item.roleType) {
          //   case -1:
          //     item.roleType = "超级管理员";
          //     break;
          //   case 0:
          //     item.roleType = "院校管理员";
          //     break;
          //   case 1:
          //     item.roleType = "文印室";
          //     break;
          //   default:
          //     // 如果有其他未预料到的值，可以考虑设置一个默认文本或者保留原值
          //     break;
          // }
        });

        this.tableData = res.data.records;
        this.total = res.data.total;
        this.pages = res.data.pages;
      } catch (error) {
        // console.error("Error fetching user list:", error);
      } finally {
        this.loading = false; // 请求完成（无论成功或失败）后设置loading为false
      }
    },
    editFrom(data, operation) {
      if (operation) {
        // console.log(data);
        this.$refs.editFrom.init(data);
      } else {
        this.$refs.addFrom.init(data);
      }
    },
    handleSizeChange(val) {
      const table = this.$refs.tableRef;
      const divData = table.bodyWrapper;
      divData.scrollTop = 0;
      this.searchs.size = val;
      this.getUserlists(); // 页大小改变时重新加载数据
    },

    handleCurrentChange(val) {
      const table = this.$refs.tableRef;
      const divData = table.bodyWrapper;
      divData.scrollTop = 0;
      this.searchs.current = val;
      this.getUserlists(); // 页码改变时重新加载数据
      // this.searchs.current = 1;
    },
  },
};
</script>
<style lang="scss" scoped></style>
