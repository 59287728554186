// 根据key获取需要显示的字段
const getName = (val, list, value, label) => {
  value = value || "value";
  label = label || "label";
  let str = "";
  list.map((item) => {
    if (item[value] == val) str = item[label];
  });
  if (!str) str = "-";
  return str;
};
export default { getName };
