<template>
  <div class="editFrom">
    <el-dialog
      title="新增客户信息"
      :visible.sync="dialogVisible"
      width="380px"
      :close-on-click-modal="false"
    >
      <el-form
        :model="ruleForm"
        :rules="rules"
        ref="ruleForm"
        label-width="70px"
      >
        <el-form-item label="客户名" prop="schoolName">
          <el-input
            v-model="ruleForm.schoolName"
            placeholder="请输入客户名"
          ></el-input>
        </el-form-item>
        <el-form-item label="学段" prop="type">
          <el-select
            v-model="ruleForm.type"
            placeholder="请选择学段"
            style="width: 100%"
          >
            <el-option
              :label="item.label"
              :value="item.label"
              v-for="(item, index) in levelGroup"
              :key="index"
            ></el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="addCustomerup()"> 确 定 </el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import { addCustomer } from "@/request/api/customer";
import { levelGroup } from "@/utils/dictionaries";
export default {
  name: "editFrom",
  data() {
    return {
      dialogVisible: false,
      levelGroup: levelGroup,
      ruleForm: {
        schoolName: "",
        type: "",
      },
      rules: {
        // 修正字段名以匹配ruleForm中的字段
        schoolName: [
          { required: true, message: "请输入客户名", trigger: "blur" },
          {
            min: 2,
            max: 30,
            message: "长度在 2 到 30 个字符",
            trigger: "blur",
          },
        ],
        // 确保字段名匹配，并根据实际字段使用正确的验证逻辑
        type: [{ required: true, message: "请选择学段", trigger: "change" }],
      },
    };
  },
  created() {},
  methods: {
    init() {
      this.dialogVisible = true;
    },
    addCustomerup() {
      // 在提交之前，先进行表单验证
      this.$refs.ruleForm.validate(async (valid) => {
        if (valid) {
          this.dialogVisible = false;
          let data = JSON.parse(JSON.stringify(this.ruleForm));

          try {
            await addCustomer(data);
            this.$message({
              message: "添加成功！",
              type: "success",
              showClose: true,
            });
            // 调用父组件的刷新列表方法
            this.ruleForm = "";
            this.$emit("call-get-userlists");
          } catch (error) {
            this.dialogVisible = true;
          }
        } else {
          // 表单验证未通过，可给出提示或直接返回，无需关闭对话框
          this.$message({
            message: "请填写完整信息后再提交",
            type: "warning",
            duration: 1500,
          });
          return false;
        }
      });
    },
  },
};
</script>
<style lang="scss" scoped></style>
