<template>
  <el-dialog
    title="快捷录入班级"
    :visible.sync="Visible"
    @close="closeDialog"
    :close-on-click-modal="false"
    width="400px"
  >
    <el-form label-width="80px">
      <el-form-item label="起始班级">
        <el-input
          v-model.number="startClass"
          placeholder="请输入起始班级，默认1"
          @input="updateSelectedRange"
        ></el-input>
      </el-form-item>
      <el-form-item label="结束班级">
        <el-input
          v-model.number="endClass"
          placeholder="请输入结束班级"
          @input="updateSelectedRange"
        ></el-input>
      </el-form-item>
    </el-form>
    <!-- 移除了复选框组 -->
    <span slot="footer" class="dialog-footer">
      <el-button @click="closeDialog">取消</el-button>
      <el-button type="primary" @click="confirmSelectedClasses">确定</el-button>
    </span>
  </el-dialog>
</template>

<script>
import { addclazz } from "@/request/api/clazz";

export default {
  name: "quickClassSelector",

  data() {
    return {
      Visible: false,
      startClass: 1,
      endClass: "",
      mydata: { classNumber: [], userId: "", gradeName: "" },
    };
  },

  methods: {
    autoSelectClasses() {
      // 当起始班级更改时，根据新的起始班级更新selectedClasses
      this.mydata.classNumber = this.classes.filter(
        (classNum) => classNum >= this.startClass
      );
    },

    // 初始化时也可以调用autoSelectClasses以确保开始时的状态正确
    init(row, mm) {
      this.Visible = true;
      this.mydata.gradeName = row.gradeName;
      console.log(mm);
      this.mydata.userId = mm;
      this.autoSelectClasses();
    },
    updateSelectedRange() {
      // 当起始班级或结束班级更改时，直接根据这两个值填充mydata.classNumber
      if (this.startClass !== undefined && this.endClass !== undefined) {
        const start = Math.min(this.startClass, this.endClass);
        const end = Math.max(this.startClass, this.endClass);
        this.mydata.classNumber = Array.from(
          { length: end - start + 1 },
          (_, i) => start + i
        );
      }
    },

    async addclazzsup() {
      try {
        this.addGradeDialogVisible = false;
        let data = JSON.parse(JSON.stringify(this.mydata));
        // console.log(data);
        // 使用axios或其他HTTP客户端库来请求数据
        await addclazz(data); // 更正函数名称
        this.$emit("call-get-userlists");
        this.$message.success("添加成功");
        // this.dialogVisible = false;
        // console.log(res.data);
      } catch (error) {
        // console.error("Failed to fetch printing methods", error); // 更正错误信息
      }
    },

    confirmSelectedClasses() {
      // 判断是否为正整数，起始班级不大于结束班级，且结束班级不超过30
      // if (
      //   Number.isInteger(this.startClass) &&
      //   Number.isInteger(this.endClass) &&
      //   this.startClass > 0 &&
      //   this.endClass > 0 &&
      //   this.startClass <= this.endClass &&
      //   this.endClass <= 30
      // ) {
      // 直接使用计算好的mydata.classNumber，无需额外处理
      this.$emit("selected-classes", this.mydata.classNumber.join(","));
      console.log(this.mydata);
      this.addclazzsup();
      this.closeDialog();
      // } else {
      //   this.$message.error(
      //     "请输入有效的正整数，且起始班级需小于等于结束班级，结束班级不能超过30"
      //   );
      // }
    },
    closeDialog() {
      this.startClass = 1;
      this.endClass = "";
      this.Visible = false;
    },
  },
  created() {},
};
</script>
<style scoped></style>
