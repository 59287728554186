import request from "@/request/axios";

//批量更新年级和年份

export const updateyears = ({ newGradeName, newYear }, data) => {
  // 构建查询参数对象

  return request({
    url: "/report/batchUpdate",
    method: "put",
    params: {
      newGradeName,
      newYear,
    },
    data,
  });
};
