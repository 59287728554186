<template>
  <div class="classFrom">
    <el-dialog
      :title="`${schoolName} - 年级管理`"
      :visible.sync="dialogVisible"
      losk-scroll="false"
      class="showAll_dialog"
      :append-to-body="true"
      :close-on-click-modal="false"
      border
      width="1000px"
    >
      <el-button type="primary" @click="addItem()">新增</el-button>
      <div class="table-container">
        <el-table
          :data="formattedTableDataWithCombinedClasses"
          style="width: 100%"
          :height="tableHeight"
          v-loading="loading"
          element-loading-text="拼命加载中"
          border
        >
          <el-table-column prop="gradeName" label="年级" align="center">
            <template slot-scope="{ row }">
              <el-input
                v-model="row.gradeName"
                size="small"
                @blur="saveGradeName(row)"
              >
              </el-input>
            </template>
          </el-table-column>

          <el-table-column label="班级" align="center">
            <template slot-scope="{ row }">
              <!-- 移除readonly属性，添加@change事件监听 -->
              <el-input
                v-model="row.combinedClassName"
                placeholder="班级列表"
                @change="saveClassList(row)"
              ></el-input>
            </template>
          </el-table-column>

          <el-table-column
            prop="address"
            label="操作"
            width="300"
            align="center"
          >
            <template slot-scope="{ row }">
              <!-- <el-button type="text" @click="saveClazz(row)"> 保存 </el-button> -->
              <el-button type="text" @click="delFrom(row)"> 删除 </el-button>
              <el-button
                type="text"
                :disabled="isButtonDisabled"
                @click="editFrom(row)"
              >
                修改班级信息
              </el-button>
              <el-button
                type="text"
                @click="openquickClassSelector(row)"
                :disabled="isButtonDisabled1"
              >
                快捷录入班级
              </el-button>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button v-loading="addlogding" type="primary" @click="addclazzon()"
          >保存</el-button
        >
      </span>
    </el-dialog>

    <classItem ref="classItem" @call-get-userlists="fetchGrades"></classItem>
    <quickClassSelector
      ref="quickClassSelector"
      @call-get-userlists="fetchGrades"
    ></quickClassSelector>
  </div>
</template>
<script>
import { getClassList, getGradelist } from "@/request/api/business";
import classItem from "./classItem";
import quickClassSelector from "./quickClassSelector.vue";
import { upGradeandclass } from "@/request/api/subject";
import { addclazz } from "@/request/api/clazz";
export default {
  name: "classFrom",
  components: { classItem, quickClassSelector },
  data() {
    return {
      addlogding: false,
      isButtonDisabled: false,
      isButtonDisabled1: false,
      loading: false,
      formattedTableData: [], // 用于直接绑定到表格的数据
      dialogVisible: false,
      indexSchool: {},
      grades: [], // 存储年级信息
      classesByGradeId: {}, // 以年级ID为键，存储该年级下的班级信息
      tableData: [],
      addGradeDialogVisible: false,
      newGradeForm: {
        gradeName: "",
        classNumber: "",
        userId: "",
      },
      userId: "",
      focusedElement: null,
      focusedElementIndex: 0, // 追踪当前聚焦的输入框索引
      schooldName: "",
      nextGradeId: 9000,
      tableHeight: 300,
      schoolName: "",
    };
  },
  created() {},
  computed: {
    formattedTableDataWithCombinedClasses() {
      return this.formattedTableData.map((grade) => {
        // 提取数字并转换为数值类型，然后对班级名称进行排序
        const sortedClasses = grade.classes
          .map((cls) => ({
            ...cls,
            classNameNoSuffix: cls.className.replace("班", ""),
            classNumber: parseInt(cls.className.match(/\d+/), 10),
          }))
          .sort((a, b) => a.classNumber - b.classNumber)
          .map((cls) => ({ ...cls, className: cls.className }));

        // 重新组合年级信息，使用排序后的班级列表
        return {
          ...grade,
          classes: sortedClasses,
          combinedClassName: sortedClasses
            .map((cls) => cls.className.replace("班", ""))
            .join("."),
        };
      });
    },
  },
  mounted() {
    this.formattedTableData.forEach((grade) => {
      grade.editableGradeName = grade.gradeName; // 初始值与原年级名称相同
    });
  },

  methods: {
    //保存修改
    async saveClazz(row) {
      try {
        // 从combinedClassName中提取classNumber
        const classNumbers = row.combinedClassName.split(".").map(String);

        // 构建新的JSON格式
        const processedRow = {
          classNumber: classNumbers,
          gradeName: row.gradeName,
          useId: this.userId,
        };

        console.log(processedRow);
        let data = JSON.parse(JSON.stringify(processedRow));
        await addclazz(data);
        this.$message.success("保存成功!");
      } catch (error) {
        // console.error("保存失败:", error);
        // this.$message.error("保存失败，请检查数据格式!");
      }
    },

    //删除

    delFrom(row) {
      this.$confirm("此操作将永久删除该年级及下属班级, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          // 找到并删除对应的年级数据
          const index = this.formattedTableData.findIndex(
            (g) => g.gradeId === row.gradeId
          );
          if (index !== -1) {
            this.formattedTableData.splice(index, 1);
            this.$message({ type: "success", message: "删除成功!" });
          }
        })
        .catch(() => {
          this.$message({ type: "info", message: "已取消删除" });
        });
    },
    saveClassList(row) {
      const index = this.formattedTableData.findIndex(
        (grade) => grade.gradeId === row.gradeId
      );

      if (index !== -1) {
        // 分割用户输入的班级名称字符串，去除可能的空白字符，并过滤掉空字符串
        const classNames = row.combinedClassName
          .split(".")
          .map((name) => name.trim())
          .filter((name) => name);

        // 创建一个映射表来跟踪已存在的className及其classId
        const existingClassesMap = this.formattedTableData[
          index
        ].classes.reduce((acc, cls) => {
          acc[cls.className] = cls.classId;
          return acc;
        }, {});

        // 根据新的classList更新或创建classes数组
        const updatedClasses = classNames.map((className) => {
          // 如果className已经存在，则直接使用其原有的classId
          if (existingClassesMap[className]) {
            return {
              className: className + "班",
              classId: existingClassesMap[className],
            };
          } else {
            // 否则，创建一个新的class对象，classId留空
            return {
              className: className + "班",
              classId: "", // 新增班级的classId留空
            };
          }
        });

        // 更新该年级的classes数组
        this.formattedTableData[index].classes = updatedClasses;
        this.formattedTableData[index].combinedClassName =
          row.combinedClassName; // 更新combinedClassName
        // for (const item of row.classes) {
        //   if (!item.classId || item.classId == 0) {
        //     this.isButtonDisabled = true;
        //     // this.$message.warning("请先保存修改！");
        //     return; // 停止执行后续代码
        //   } else {
        //     this.isButtonDisabled = false;
        //   }
        // }
        // console.log("班级列表已根据输入更新，新班级的classId留空");
      }
    },
    saveGradeName(row) {
      // 找到对应年级在formattedTableData中的索引
      const index = this.formattedTableData.findIndex(
        (g) => g.gradeId === row.gradeId
      );
      if (index !== -1) {
        // 更新该年级的gradeName
        this.formattedTableData[index].gradeName = row.gradeName;
        console.log("年级名称更新为:", row.gradeName);
        // 这里可以添加调用后端API的逻辑来持久化变更
      }
      // for (const item of row.classes) {
      //   if (!item.classId || item.classId == 0) {
      //     this.isButtonDisabled = true;
      //     // this.$message.warning("请先保存修改！");
      //     return; // 停止执行后续代码
      //   } else {
      //     this.isButtonDisabled = false;
      //   }
      // }
    },
    //提交所以的数据
    async addclazzon() {
      this.addlogding = true;
      try {
        this.addGradeDialogVisible = false;
        await this.$nextTick();
        let formdata = [...this.formattedTableData]; // 使用扩展运算符创建数组的浅拷贝
        formdata.forEach((item) => {
          item.userId = this.userId; // 在每个对象中添加userId属性
        });
        let data = JSON.parse(JSON.stringify(formdata));
        // console.log(data);
        await upGradeandclass(data); // 更正函数名称
        this.$message.success("修改成功");
        this.isButtonDisabled = false;
        this.isButtonDisabled1 = false;
        this.fetchGrades();
      } catch (error) {
        // console.error("Failed to fetch printing methods", error); // 更正错误信息
      } finally {
        this.addlogding = false;
      }
    },

    confirmAddGrade() {
      // 验证输入
      if (
        !this.newGradeForm.gradeName ||
        !this.newGradeForm.classesInput.trim()
      ) {
        this.$message.error("年级名称和班级列表都必须填写");
        return;
      }
    },
    addItem() {
      // 新年级默认设置
      const newGrade = {
        gradeId: this.nextGradeId++,
        gradeName: "", // 默认年级名称
        classes: [].map((name) => ({
          className: name,
          classId: "", // 将classId初始化为空字符串
          allCount: 20,
        })),
      };
      // 将新年级数据推入到当前表格数据中
      // this.isButtonDisabled = true;
      this.formattedTableData.push(newGrade);
    },

    openquickClassSelector(row) {
      console.log(row);

      if (!row.gradeName) {
        this.isButtonDisabled1 = true;
        this.$message.warning("该条年级信息未进行录入！");
        return; // 停止执行后续代码
      } else {
        this.isButtonDisabled = false;
      }

      const mm = this.userId;
      this.$refs.quickClassSelector.init(row, mm);
    },

    editFrom(row) {
      console.log(row);

      // 检查 row 中的数组是否存在 classId 为 0 或空的情况
      for (const item of row.classes) {
        if (!item.classId || item.classId == 0) {
          this.isButtonDisabled = true;
          this.$message.warning("该条班级信息未进行录入！");
          return; // 停止执行后续代码
        } else {
          this.isButtonDisabled = false;
        }
      }
      // 如果所有 classId 都不是 0 或空，则继续执行 init 方法
      this.$refs.classItem.init(row, this.userId);
    },
    init(row) {
      this.dialogVisible = true;
      this.userId = row.userId;
      this.schoolName = row.schoolName;

      this.fetchGrades();
    },
    async fetchGrades() {
      this.loading = true;
      try {
        if (this.userId == null) {
          // this.$message.warning("当前学校没有和用户关联");
        } else {
          const res = await getGradelist(this.userId);
          const gradePromises = res.data.map(async (grade) => {
            const classRes = await getClassList({
              userId: this.userId,
              gradeId: grade.id,
            });
            // console.log(classRes.data);

            // 直接在映射时加入allCount
            const classesWithCount = classRes.data.map((clazz) => ({
              className: clazz.name,
              classId: clazz.id,
              allCount: clazz.allCount, // 添加allCount到班级对象
            }));
            // console.log(classesWithCount);

            // 现在，sortedClasses 已经包含了allCount
            return {
              gradeName: grade.name,
              gradeId: grade.id,
              classes: classesWithCount,
            };
          });
          // console.log(gradePromises);
          // 更新表格数据，此时每个班级对象应已包含allCount
          this.formattedTableData = await Promise.all(gradePromises);
          // console.log(this.formattedTableData);
        }
      } catch (error) {
        // console.error("Failed to fetch grades or classes", error);
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.table-container {
  min-height: 200px; /* 设置一个最小高度 */
  max-height: 500px; /* 设置最大高度 */
  overflow-y: auto; /* 当内容超过最大高度时，出现滚动条 */
}
</style>
