<template>
  <div class="user">
    <div class="filter-box">
      <span>学校:</span>
      <el-select
        v-model="search.userId"
        placeholder="请选择学校"
        @change="handleSchoolChange(), mays()"
        @keyup.native="handleKeyup"
      >
        <el-option
          v-for="school in schools"
          :key="school.id"
          :label="school.schoolName"
          :value="school.userId"
        >
        </el-option>
      </el-select>
      <span>年级:</span>
      <el-select
        title="请先选择学校，才能选择年级"
        v-model="search.gradeId"
        placeholder="请选择年级"
        @change="handleGradeChange"
        @keyup.native="handleKeyup"
        :disabled="isSelectDisabled"
      >
        <el-option
          v-for="grade in grades"
          :key="grade.id"
          :label="grade.name"
          :value="grade.id"
        >
        </el-option>
      </el-select>
      <span class="time">起止时间：</span>

      <el-date-picker
        :clearable="false"
        v-model="time"
        type="daterange"
        @change="handleDateChange"
        range-separator="至"
        start-placeholder="开始日期"
        end-placeholder="结束日期"
        class="m1"
      >
      </el-date-picker>

      <!-- <el-date-picker
        v-model="search.startTime"
        type="date"
        class="custom-date-picker"
        placeholder="选择开始时间"
      >
      </el-date-picker> 
      <el-date-picker
        v-model="search.endTime"
        type="date"
        class="custom-date-picker"
        placeholder="选择结束时间"
      >
      </el-date-picker> -->

      <el-button
        type="primary"
        :disabled="!isQueryEnabled"
        icon="el-icon-search"
        @click="getNew()"
        >查询</el-button
      >
      <el-button
        v-loading="loadingv"
        :disabled="!isQueryEnabled"
        element-loading-text="数据迁移中，请耐心等待...."
        type="primary"
        @click="move()"
        ><i class="el-icon-data-line"></i>数据迁移</el-button
      >
    </div>
    <el-table
      v-loading="loading"
      element-loading-text="拼命加载中"
      :data="tableData"
      height="580"
      style="width: 100%"
      stripe
      border
      ref="tableRef"
    >
      <el-table-column
        prop="formattedCreateTime"
        label="时间"
        width="200"
        align="center"
      >
      </el-table-column>

      <el-table-column prop="userName" label="客户名称" align="center">
      </el-table-column>

      <el-table-column prop="gradeName" label="年级" align="center">
      </el-table-column>

      <el-table-column prop="classList" label="班级" align="center">
      </el-table-column>

      <el-table-column prop="content" label="内容" align="center">
      </el-table-column>

      <el-table-column prop="paperType" label="纸张类型" align="center">
      </el-table-column>

      <el-table-column prop="singleDoubleSided" label="单/双面" align="center">
      </el-table-column>

      <el-table-column prop="project" label="印刷方式" align="center">
      </el-table-column>

      <el-table-column prop="paperType" label="纸张类型" align="center">
      </el-table-column>

      <el-table-column prop="extraUnitPrice" label="加版单价" align="center">
      </el-table-column>
      <el-table-column prop="unitPrice" label="单价" align="center">
      </el-table-column>
      <el-table-column prop="totalPrice" label="总金额" align="center">
      </el-table-column>

      <!-- <el-table-column
        prop="formattedCreateTime"
        label="时间"
        align="center"
        width="160"
      >
      </el-table-column> -->

      <!-- <el-table-column prop="address" label="操作" align="center" width="160">
        <template slot-scope="{ row }">
          <el-button type="text" @click="ups(row)">修改</el-button>
          <el-button type="text" class="btn-err" @click="delForm(row)"
            >删除</el-button
          >
        </template>
      </el-table-column> -->
    </el-table>
    <el-pagination
      hide-on-single-page
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page="search.current"
      :page-sizes="[16, 100, 200, 300, 400]"
      :page-size="search.size"
      layout="total, sizes, prev, pager, next, jumper"
      :total="total"
    >
    </el-pagination>
  </div>
</template>

<script>
import {
  delBusiness,
  getBusinesslist,
  getGradelist,
  getSchoollist,
} from "@/request/api/business";
import { getstuta } from "@/request/api/login";
import { movedata } from "@/request/api/move";
import { getStore } from "@/utils/util";
export default {
  name: "business",
  data() {
    return {
      isQueryEnabled: false,
      loadingv: false,
      time: "",
      isSelectDisabled: true,
      loading: false,
      search: {
        current: 1, // 当前页码
        size: 16, // 每页显示数量
        startTime: "",
        endTime: "",
        userId: "",
        gradeId: "",
      },
      priceListId: "",
      grades: [],
      schools: [],
      mydata: { ids: [] },

      total: 0,
      tableData: [], // 这里需要初始化数据，例如通过API请求
    };
  },
  created() {
    this.fetchSchools();
  },
  mounted() {
    // this.getNewList();
  },
  beforeDestroy() {
    // 组件销毁前清除定时器
    clearInterval(this.timer);
  },
  watch: {
    "search.gradeId": {
      handler(newVal, oldVal) {
        if (newVal !== oldVal && this.search.userId !== "") {
          this.checkAllChanged();
        }
      },
      immediate: false,
      deep: true,
    },
    "search.userId": {
      handler(newVal, oldVal) {
        if (newVal !== oldVal && this.search.gradeId !== "") {
          this.checkAllChanged();
        }
      },
      immediate: false,
      deep: true,
    },
    time: {
      handler(newVal, oldVal) {
        if (newVal !== oldVal) {
          this.checkAllChanged();
        }
      },
      immediate: false,
      deep: true,
    },
  },

  methods: {
    checkAllChanged() {
      if (
        this.search.gradeId !== "" &&
        this.search.userId !== "" &&
        this.time !== ""
      ) {
        this.isQueryEnabled = true;
      }
    },
    async fetchData() {
      try {
        const response = await getstuta({ taskId: this.taskId });
        if (response.msg == 1) {
          this.$alert("数据迁移已经完成", "提示", {
            confirmButtonText: "确定",
            callback: () => {
              this.$message({
                type: "success",
                message: "迁移成功！",
              });
            },
          });
          this.isQueryEnabled = true;
          this.loadingv = false;
          this.getNewList();
          clearInterval(this.timer);
        } else if (response.msg == 2) {
          this.$alert(response.data, "提示", {
            confirmButtonText: "确定",
            callback: () => {
              this.$message({
                type: "success",
                message: "取消成功",
              });
            },
          });
          this.isQueryEnabled = true;
          this.loadingv = false;
          clearInterval(this.timer);
        }
      } catch (error) {
        //
      }
    },
    //打开添加业务的弹窗

    up() {
      this.$refs.addBusinessup.init(); // 假设addBusiness组件需要一个空的初始化
    },

    ups(row) {
      this.$refs.addBusinessup.inits(row); // 假设addBusiness组件需要一个空的初始化
    },

    //判断状态
    async move() {
      this.loadingv = true;
      try {
        // let data = JSON.parse(JSON.stringify(this.mydata));
        // console.log(data);
        const params = {
          size: this.search.size,
          current: this.search.current,
          userId: this.search.userId,
          gradeId: this.search.gradeId,
        };
        if (this.search.endTime && this.search.startTime) {
          params.endTime = this.formatDate(this.search.endTime);
          params.startTime = this.formatDate(this.search.startTime);
        }
        let data = JSON.parse(JSON.stringify(params));
        this.isQueryEnabled = true;
        const res = await movedata(data);
        this.taskId = res.data;

        this.timer = setInterval(this.fetchData, 3000);
        // this.moves();
      } catch {
        // console.log(err);
      }
    },

    //判断是否选择了学校
    mays() {
      if (this.tableData.userId !== "") {
        this.isSelectDisabled = false;
      }
    },
    handleGradeChange() {
      this.tableData = [];
    },
    handleDateChange() {
      this.tableData = [];
    },

    handleSchoolChange() {
      this.fetchGrades();
      this.search.gradeId = "";
      this.tableData = [];
    },

    //业务删除功能
    async delForm(row) {
      // console.log(row.id);
      const confirmed = await this.$confirm(
        "此操作将永久删除该用户, 是否继续?",
        "提示",
        {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        }
      );

      // 如果确认删除
      if (confirmed) {
        delBusiness(row.id)
          .then(() => {
            this.$message.success("删除成功！");
            // 从表格数据中移除已删除的项
            this.tableData = this.tableData.filter(
              (item) => item.id !== row.id
            );
            // 重新加载数据以获取最新的列表
            this.getNewList();
          })
          .catch(() => {
            // 错误消息
            // this.$message.error("删除失败，请重试！");
          });
      }
    },
    // 获取学校下拉框
    async fetchSchools() {
      try {
        const userInfo = getStore({ name: "userinfo" });
        // 使用axios或其他HTTP客户端库来请求数据
        const params = {
          userId: userInfo.id,
          roleType: userInfo.roleType - 2,
        };
        const res = await getSchoollist(params);
        // console.log(res);
        this.schools = res.data.records; // 假设后端返回的数据直接赋值给schools
      } catch (error) {
        // console.error("Failed to fetch schools", error);
      }
    },

    // 获取班级下拉框
    async fetchGrades() {
      try {
        // 使用axios或其他HTTP客户端库来请求数据
        const res = await getGradelist(this.search.userId);
        // this.grades = res.data; // 假设后端返回的数据直接赋值给grades
        this.grades = [...res.data, { id: 0, name: "部门" }];
      } catch (error) {
        // console.error("Failed to fetch grades", error);
      }
    },

    //时间处理
    formatDate(date) {
      if (date) {
        let d = new Date(date);
        return `${d.getFullYear()}-${("0" + (d.getMonth() + 1)).slice(-2)}-${(
          "0" + d.getDate()
        ).slice(-2)}`;
      }
      return ""; // 如果date为空，则返回空字符串
    },
    getNew() {
      this.search.current = 1;
      this.getNewList();
    },
    //获取业务数据
    async getNewList() {
      this.loading = true;
      try {
        const [startTime, endTime] = this.time;

        this.search.startTime = startTime ? this.formatDate(startTime) : "";
        this.search.endTime = endTime ? this.formatDate(endTime) : "";

        const params = {
          ...this.search,
          ...(startTime && { startTime: this.search.startTime }),
          ...(endTime && { endTime: this.search.endTime }),
        };

        const res = await getBusinesslist(params);
        res.data.records.forEach((item) => {
          if (item.date) {
            const date = new Date(item.date);
            item.formattedCreateTime = `${date.getFullYear()}年${(
              "0" +
              (date.getMonth() + 1)
            ).slice(-2)}月${("0" + date.getDate()).slice(-2)}日`;
          }
        });
        this.tableData = res.data.records;
        this.$nextTick(() => {
          this.$refs.tableRef.bodyWrapper.scrollTop = 0;
        });
        this.total = res.data.total;
      } catch (error) {
        // console.error("Error fetching business list:", error);
      } finally {
        this.loading = false;
      }
    },

    handleSizeChange(val) {
      this.search.size = val;
      this.getNewList(); // 页大小改变时重新加载数据
    },

    handleCurrentChange(val) {
      this.search.current = val;
      this.getNewList(); // 页码改变时重新加载数据
      // this.search.current = 1;
    },
  },
};
</script>
<style lang="scss" scoped>
span {
  margin-right: 1%;
}
.custom-date-picker {
  width: 150px; /* 自定义宽度 */
}
.m1 {
  margin-right: 15px;
}
</style>
