<template>
  <div class="subjectItem">
    <el-dialog
      title="科目管理"
      :visible.sync="dialogVisible"
      :close-on-click-modal="false"
      width="480px"
    >
      <div>
        <el-checkbox-group v-model="selectedSubjects" v-loading="loading">
          <el-checkbox
            v-for="subject in subjects"
            :key="subject.id"
            :label="subject.id"
            :value="subject"
            >{{ subject.name }}</el-checkbox
          >
        </el-checkbox-group>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="SubjectChange()"> 确 定 </el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import {
  addSubjectMiddle,
  getSubject,
  getSubjectMiddle,
} from "@/request/api/subject";

export default {
  name: "subjectItem",
  data() {
    return {
      loading: true,
      dialogVisible: false,
      checkList: [],
      keyword: "",
      subjects: [], // 新增：用于存储API返回的科目数据
      selectedSubjects: [], // 如果需要记录选中的科目，可以初始化为空数组
      userId: "",
    };
  },
  created() {
    this.fetchsubject();
    // this.dialogVisible = true;
  },

  // 在现有的methods对象内新增以下方法
  methods: {
    async SubjectChange() {
      try {
        let params = {
          userId: this.userId,
          subjectIds: this.selectedSubjects,
        };
        let data = JSON.parse(JSON.stringify(params));
        console.log("已选择的科目:", data);
        await addSubjectMiddle(data);
        this.$message.success("修改成功！");
        this.dialogVisible = false; // 关闭对话框
        this.selectedSubjects = [];
      } catch (error) {
        // console.log(error)d
      }
    },

    init(row) {
      // this.fetchsubject();
      this.userId = row.userId;
      this.fetchsubjectback();
      this.dialogVisible = true;
    },

    async fetchsubject() {
      try {
        const res = await getSubject();
        // console.log(res.data);
        this.subjects = res.data;
      } catch (error) {
        // 可以在这里处理错误，比如提示用户
      }
    },
    //返回已经选择了的数据
    async fetchsubjectback() {
      this.loading = true;
      try {
        const res = await getSubjectMiddle(this.userId);
        const selectedSubjectIds = res.data.map((item) => item.subjectId);
        // 将这个数组赋值给 selectedSubjects，这将触发界面更新
        this.selectedSubjects = selectedSubjectIds;
      } catch (error) {
        // 可以在这里处理错误，比如提示用户
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.subjectItem {
  .el-input {
    width: 180px;
    margin-bottom: 18px;
  }
}
</style>
