<template>
  <div class="user">
    <div class="filter-box">
      <el-input
        v-model="searchs.keyword"
        placeholder="请输入搜索标题"
        @input="fetchLogs()"
      >
        <i class="el-icon-search el-input__icon" slot="suffix"></i>
      </el-input>

      <span class="time">起始时间：</span>
      <el-date-picker
        v-model="time"
        type="daterange"
        range-separator="至"
        start-placeholder="开始日期"
        end-placeholder="结束日期"
        class="m1"
      >
      </el-date-picker>
      <!-- <el-date-picker
        v-model="searchs.startTime"
        type="date"
        placeholder="选择开始时间"
      >
      </el-date-picker>
      <el-date-picker
        v-model="searchs.endTime"
        type="date"
        placeholder="选择结束时间"
      >
      </el-date-picker> -->
      <el-button type="primary" icon="el-icon-search" @click="getNewList()"
        >查询</el-button
      >
    </div>
    <el-table
      v-loading="loading"
      element-loading-text="拼命加载中"
      :data="tableData"
      height="580"
      style="width: 100%"
      stripe
      border
      ref="tableRef"
    >
      <el-table-column prop="id" label="序号" align="center"> </el-table-column>
      <el-table-column prop="title" label="标题" align="center">
      </el-table-column>
      <el-table-column prop="type" label="类型" align="center">
        <template slot-scope="scope">
          <span v-if="scope.row.type === -1">超级管理员</span>
          <span v-else-if="scope.row.type === 0">院校管理员</span>
          <span v-else-if="scope.row.type === 1">文印室</span>
          <span v-else>未知类型</span>
        </template>
      </el-table-column>

      <el-table-column prop="createBy" label="操作用户" align="center">
      </el-table-column>
      <el-table-column prop="remoteAddr" label="操作ip" align="center">
      </el-table-column>
      <el-table-column prop="method" label="方法" align="center">
      </el-table-column>
      <el-table-column prop="formattedCreateTime" label="时间" align="center">
      </el-table-column>
    </el-table>
    <el-pagination
      hide-on-single-page
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page="searchs.current"
      :page-sizes="[16, 100, 200, 300, 400]"
      :page-size="searchs.size"
      layout="total, sizes, prev, pager, next, jumper"
      :total="total"
    >
    </el-pagination>
  </div>
</template>
<script>
import { getLoglists } from "@/request/api/log";

export default {
  name: "log",
  data() {
    return {
      loading: false,
      searchs: {
        current: 1, // 当前页码
        size: 16, // 每页显示数量
        keyword: "",
        startTime: "", // 初始化为空字符串或设定一个默认日期
        endTime: "", // 同上
      },
      startTime: "", // 初始化为空字符串或设定一个默认日期
      endTime: "", // 同上
      pages: "",
      time: "",
      current: 1,
      total: 0,
      tableData: [],
    };
  },
  created() {},
  mounted() {
    this.fetchLogs();
  },
  watch: {
    time: {
      handler() {
        this.fetchLogs();
      },
      deep: true,
    },
  },
  methods: {
    //时间格式转换
    formatDate(date) {
      if (date) {
        let d = new Date(date);
        return `${d.getFullYear()}-${("0" + (d.getMonth() + 1)).slice(-2)}-${(
          "0" + d.getDate()
        ).slice(-2)}`;
      }
      return ""; // 如果date为空，则返回空字符串
    },
    async fetchLogs() {
      this.loading = true;
      try {
        // 直接从 time 数组提取开始和结束日期
        const [startTime, endTime] = this.time;

        // 如果有值，才进行格式化（取决于后端接口是否需要特定格式）
        this.searchs.startTime = startTime ? this.formatDate(startTime) : "";
        this.searchs.endTime = endTime ? this.formatDate(endTime) : "";

        // 确保只有当 startTime 或 endTime 存在且有效时才传递给后端
        const searchParams = {
          ...this.searchs,
          ...(startTime && { startTime: this.searchs.startTime }),
          ...(endTime && { endTime: this.searchs.endTime }),
        };

        const res = await getLoglists(searchParams);

        // 先保存原始数据，以防后续需要
        // this.rawTableData = res.data.records.slice();

        // 遍历并格式化createTime
        res.data.records.forEach((item) => {
          if (item.createTime) {
            item.formattedCreateTime = new Date(
              item.createTime
            ).toLocaleString();
          }
        });

        this.tableData = res.data.records;
        this.total = res.data.total;
        this.pages = res.data.pages || Math.ceil(this.total / this.search.size);

        // this.$message({
        //   message: "获取成功！",
        //   type: "success",
        //   showClose: true,
        // });
      } catch (error) {
        console.error("Error fetching logs:", error);
      } finally {
        this.loading = false;
      }
    },

    handleSizeChange(val) {
      this.searchs.size = val;
      this.fetchLogs(); // 页大小改变时重新加载数据
    },
    getNewList() {
      const table = this.$refs.tableRef;
      const divData = table.bodyWrapper;
      divData.scrollTop = 0;
      this.searchs.current = 1;
      this.fetchLogs();
    },
    handleCurrentChange(val) {
      const table = this.$refs.tableRef;
      const divData = table.bodyWrapper;
      divData.scrollTop = 0;
      this.searchs.current = val;
      this.fetchLogs(); // 页码改变时重新加载数据
      // this.searchs.current = 1;
    },
  },
};
</script>
<style lang="scss" scoped>
.time {
  margin-left: 5px;
}
.m1 {
  margin-right: 10px;
}
</style>
