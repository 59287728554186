<template>
  <div class="login">
    <div class="landscape"></div>
    <div class="filter"></div>
    <canvas id="loginBg" :key="cavansKey"></canvas>
    <div class="login-from">
      <div class="login-box">
        <div class="login-title">业务管理系统</div>
        <el-form :model="loginForm" status-icon :rules="rules" ref="loginForm">
          <el-form-item label="" prop="username">
            <el-input
              type="text"
              v-model="loginForm.username"
              placeholder="请输入用户名"
              autocomplete="on"
              @keyup.enter.native="submit"
              prefix-icon="el-icon-user-solid"
            >
            </el-input>
          </el-form-item>
          <el-form-item label="" prop="password">
            <el-input
              type="password"
              v-model="loginForm.password"
              placeholder="请输入密码"
              autocomplete="on"
              show-password
              @keyup.enter.native="submit"
              prefix-icon="el-icon-lock"
            >
            </el-input>
          </el-form-item>
        </el-form>
        <el-button type="primary" :loading="btnLoading" @click="submit()"
          >登录</el-button
        >
      </div>
    </div>
    <Vcode :show="isShow" @success="success" @close="close" />
  </div>
</template>
<script>
// import { initView, destroyView } from "@/utils/loginBg";
import { userLogin } from "@/request/api/login.js";
import { setStore } from "@/utils/util";
import md5 from "js-md5";
import Vcode from "vue-puzzle-vcode";
export default {
  name: "login",
  components: { Vcode },
  data() {
    var validateName = (rule, value, callback) => {
      if (!value) {
        return callback(new Error("请输入用户名"));
      }
      callback();
    };
    var validatePass = (rule, value, callback) => {
      if (!value) {
        return callback(new Error("请输入密码"));
      }
      callback();
    };
    return {
      isShow: false,
      btnLoading: false,
      loginForm: {
        username: "",
        password: "",
      },
      rules: {
        username: [{ validator: validateName, trigger: "blur" }],
        password: [{ validator: validatePass, trigger: "blur" }],
      },
      cavansKey: 1,
    };
  },
  created() {
    this.cavansKey = new Date().getTime();
  },
  // mounted() {
  //   initView();
  // },
  // destroyed() {
  //   destroyView();
  // },
  methods: {
    // 用户通过了验证
    success() {
      this.isShow = false; // 通过验证后，需要手动隐藏模态框
      this.subLogin();
    },

    // 用户点击遮罩层，应该关闭模态框
    close() {
      this.isShow = false;
      this.btnLoading = false;
    },
    subLogin() {
      let data = JSON.parse(JSON.stringify(this.loginForm));
      this.btnLoading = true;
      data.password = md5(data.password);

      // 更新下最后一次请求时间  不要被拦截
      setStore({ name: "lastRequestTime", content: new Date().getTime() });
      userLogin(data)
        .then((res) => {
          res.data.roleType = res.data.roleType + 2;
          console.log(res.data);
          setStore({
            name: "userinfo",
            content: res.data,
          });
          setStore({
            name: "token",
            content: res.data.token,
          });
          this.$message({
            message: "登录成功，即将跳转到主页！",
            type: "success",
            showClose: true,
          });
          setTimeout(() => {
            this.$router.push({
              path: "/business/list",
            });
            this.btnLoading = false;
          }, 1000);
        })
        .catch(() => {
          this.btnLoading = false;
        });
    },

    submit() {
      this.btnLoading = true;
      this.$refs.loginForm.validate((valid) => {
        if (valid) {
          this.subLogin();
          // this.isShow = true;
        } else {
          this.btnLoading = false;
          return false;
        }
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.login {
  margin: 0;
  overflow: hidden;
  width: 100vw;
  height: 100vh;
  background: black;
  background: linear-gradient(to bottom, #000000 0%, #5788fe 100%);
  position: relative;
  .login-from {
    position: absolute;
    left: 0;
    top: 0;
    width: 100vw;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    .el-button {
      width: 100%;
    }
    .login-box {
      width: 320px;
      padding: 24px;
      text-align: center;
      background-color: rgba(0, 0, 0, 0.5);
      border-radius: 8px;
      ::v-deep {
        .el-input__inner {
          background-color: transparent;
          border-color: #ffffff;
          outline: none;
        }
      }
      .login-title {
        font-size: 18px;
        margin-bottom: 18px;
        color: #ffffff;
      }
    }
  }
  .filter {
    width: 100vw;
    height: 100vh;
    position: absolute;
    top: 0;
    left: 0;
    background: #fe5757;
    animation: colorChange 30s ease-in-out infinite;
    animation-fill-mode: both;
    mix-blend-mode: overlay;
  }
  @keyframes colorChange {
    0%,
    100% {
      opacity: 0;
    }
    50% {
      opacity: 0.9;
    }
  }
  .landscape {
    position: absolute;
    bottom: 0px;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-image: url("@/assets/login/xkbg.png");
    background-size: 1000px 250px;
    background-repeat: repeat-x;
    background-position: center bottom;
  }
}
</style>
