<template>
  <div class="user">
    <div class="select-and-buttons">
      <el-button type="primary" @click="addForm()">
        <i class="el-icon-plus"></i> 添加新科目</el-button
      >
    </div>
    <el-table :data="tableData" stripe style="width: 100%" border>
      <el-table-column type="index" label="序号" align="center">
      </el-table-column>
      <el-table-column prop="name" label="科目名称" align="center">
      </el-table-column>

      <el-table-column
        prop="formattedCreateTime"
        label="创建时间"
        align="center"
      >
      </el-table-column>
      <el-table-column prop="address" label="操作" align="center" width="160">
        <template slot-scope="{ row }">
          <!-- <el-button type="text" @click="editFrom(false)">修改</el-button> -->
          <el-button type="text" class="btn-err" @click="deleteSubject(row)"
            >删除</el-button
          >
        </template>
      </el-table-column>
    </el-table>
    <el-dialog
      title="添加科目"
      :visible.sync="dialogVisible"
      width="380px"
      :close-on-click-modal="false"
    >
      <el-form
        :model="ruleForm"
        ref="ruleForm"
        label-width="70px"
        :rules="rules"
      >
        <el-form-item label="科目名" prop="name">
          <el-input
            v-model="ruleForm.name"
            placeholder="请输入科目名"
          ></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="validateForm()"> 确 定 </el-button>
      </span>
    </el-dialog>
    <el-pagination
      hide-on-single-page
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page="current"
      :page-sizes="[16, 40, 80, 100, 200]"
      :page-size="size"
      layout="total, sizes, prev, pager, next, jumper"
      :total="tableData.length"
    >
    </el-pagination>
  </div>
</template>
<script>
import { addSubject, delSubject, getSubject } from "@/request/api/subject";

export default {
  name: "price",
  data() {
    return {
      dialogVisible: false,
      search: {
        keyword: "",
      },
      current: 1,
      size: 16,
      tableData: [],
      ruleForm: { name: "" },
      rules: {
        name: [
          {
            // 科目名验证规则
            required: true, // 必填项
            message: "科目名不能为空", // 验证失败时的提示信息
            trigger: "blur", // 触发验证的时机，这里是在失去焦点时触发
          },
        ],
      },
    };
  },
  created() {
    // 初始化数据，例如从服务器获取分公司和价格表列表
    this.fetchsubject();
  },
  mounted() {},
  methods: {
    formatDate(date) {
      if (date) {
        let d = new Date(date);
        return `${d.getFullYear()}-${("0" + (d.getMonth() + 1)).slice(-2)}-${(
          "0" + d.getDate()
        ).slice(-2)}`;
      }
      return ""; // 如果date为空，则返回空字符串
    },
    async fetchsubject() {
      try {
        const res = await getSubject();
        // console.log(res.data);
        res.data.forEach((item) => {
          if (item.createTime) {
            item.formattedCreateTime = new Date(
              item.createTime
            ).toLocaleString();
          }
        });
        this.tableData = res.data;
      } catch (error) {
        // 可以在这里处理错误，比如提示用户
      }
    },

    async deleteSubject(row) {
      const confirmed = await this.$confirm(
        "此操作将永久删除该科目, 是否继续?",
        "提示",
        {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        }
      );
      if (confirmed) {
        try {
          await delSubject(row.id);
          this.$message.success("科目删除成功");
          this.fetchsubject();
        } catch {
          //
        }
      }
    },

    validateForm() {
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          // console.log("表单验证通过！");
          // 在这里执行表单提交等后续操作
          this.submit();
        } else {
          // console.log("表单验证未通过！");
          this.$message({
            message: "请填写完整信息后再提交",
            type: "warning",
            duration: 1500,
          });
          return false;
        }
      });
    },
    async submit() {
      try {
        let data = JSON.parse(JSON.stringify(this.ruleForm));
        await addSubject(data);

        this.$message.success("科目添加成功");
        this.fetchsubject();
      } catch {
        //
      } finally {
        this.dialogVisible = false;
      }
    },
    addForm() {
      this.dialogVisible = true;
    },

    handleSizeChange() {
      // console.log(`每页 ${val} 条`);
    },
    handleCurrentChange() {
      // console.log(`当前页: ${val}`);
    },
  },
};
</script>
<style lang="scss" scoped>
.select-and-buttons {
  display: flex;
  justify-content: flex-end;
}
</style>
