import request from "@/request/axios";

// 获取业务报告
export const getStatical = ({
  size,
  current,
  keyword,
  userId,
  gradeId,
  startTime,
  endTime,
}) => {
  return request({
    url: "/business-report/list",
    method: "get",
    params: {
      size,
      current,
      keyword,
      userId,
      gradeId,
      startTime,
      endTime,
    },
  });
};

// 添加业务报告
export const addStatical = (data) => {
  return request({
    url: "/price-list/add",
    method: "post",
    params: data,
  });
};

// 删除业务报告
export const delStatical = (id) => {
  return request({
    url: `/price-list/delete/${id}`,
    method: "delete",
  });
};

// 复核业务报告
export const heStatical = (data) => {
  return request({
    url: "/report/review",
    method: "put",
    params: data,
  });
};

// 更新表
export const updateStatical = (data) => {
  return request({
    url: "/report/update",
    method: "put",
    data,
  });
};

//批量更新年级和年份

export const updateyear = ({ newGradeId, newYear }, data) => {
  // 构建查询参数对象

  return request({
    url: "/business-report/batchupdate",
    method: "put",
    params: {
      newGradeId,
      newYear,
    }, // 如果API设计中这些参数应该在querystring中
    data, // integers数组直接作为body
  });
};

// 获取业务报告
export const dateSplit = (data) => {
  return request({
    url: "/report/dateSplit",
    method: "get",
    params: data,
  });
};
