import request from "@/request/axios";

// 获取全部价格列表项
export const getPricelists = ({ size, page }) => {
  return request({
    url: "/price-item/list",
    method: "get",
    params: {
      size,
      page,
    },
  });
};

// 获取当前用户的价格列表项
export const getPricelist = (priceListId, data) => {
  return request({
    url: `/price-item/list/${priceListId}`,
    method: "get",
    params: data,
  });
};

// 添加价格表项
export const addPriceitem = (data) => {
  return request({
    url: "/price-item/add",
    method: "post",
    data,
  });
};

// 删除价格表项
export const delPriceitem = (id) => {
  return request({
    url: `/price-item/delete/${id}`,
    method: "delete",
  });
};

// 更新价格表项
export const updatePriceitem = (data) => {
  return request({
    url: "/price-item/update",
    method: "put",
    data,
  });
};

//实时修改价格表项
export const updatePriceitems = (data) => {
  return request({
    url: "/price-item/bulk-update",
    method: "put",
    data,
  });
};

//新增默认价格表
export const newpriceitem = (data) => {
  return request({
    url: "/price-item/default",
    method: "post",
    params: data,
  });
};
