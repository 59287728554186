<template>
  <div class="user">
    <div class="select-and-buttons">
      <span class="spand">学校：</span>
      <el-select
        filterable
        v-model="search.userId"
        placeholder="请选择学校"
        @change="handleSchoolChange(), mays()"
        @keyup.native="handleKeyup"
      >
        <el-option
          v-for="school in schools"
          :key="school.id"
          :label="school.schoolName"
          :value="school.userId"
        >
        </el-option>
      </el-select>

      <span class="spand">年级：</span>
      <el-select
        filterable
        clearable
        @change="handleGradeChange()"
        title="请先选择学校，才能选择年级"
        v-model="search.gradeId"
        placeholder="请选择年级"
        @keyup.native="handleKeyup"
        :disabled="isSelectDisabled"
      >
        <el-option
          v-for="grade in grades"
          :key="grade.id"
          :label="grade.name"
          :value="grade.id"
        >
        </el-option>
      </el-select>
      <span class="spand">起止时间：</span>

      <el-date-picker
        :clearable="false"
        v-model="time"
        type="daterange"
        range-separator="至"
        start-placeholder="开始日期"
        end-placeholder="结束日期"
        @change="handleDateChange"
      >
      </el-date-picker>

      <!-- <el-date-picker
        v-model="search.startTime"
        type="date"
        class="custom-date-picker"
        placeholder="选择开始时间"
      >
      </el-date-picker>

      <el-date-picker
        v-model="search.endTime"
        type="date"
        class="custom-date-picker"
        placeholder="选择结束时间"
      >
      </el-date-picker> -->

      <el-button
        class="btn-left"
        type="primary"
        icon="el-icon-search"
        @click="getNew()"
        :disabled="!isQueryEnabled"
        >查询</el-button
      >

      <el-dialog
        :title="'选择价格表'"
        :visible.sync="dialogVisible1"
        width="30%"
      >
        <div slot="footer" class="dialog-footer">
          <el-button @click="dialogVisible1 = false">取消</el-button>
          <el-button
            type="primary"
            @click="(dialogVisible1 = false), heStaticalup()"
            >确认</el-button
          >
        </div>

        <span>价格表：</span>
        <el-select
          filterable
          clearable
          v-model="selectedPriceList"
          placeholder="请选择价格表"
        >
          <el-option
            v-for="priceList in priceLists"
            :key="priceList.id"
            :label="priceList.name"
            :value="priceList.id"
          ></el-option>
        </el-select>
      </el-dialog>

      <div class="button-group">
        <el-button
          type="primary"
          v-loading="heloading"
          element-loading-text="复核中"
          @click="mmsa"
          icon="el-icon-menu"
          :disabled="!isQueryEnabled"
          >复核
        </el-button>
        <el-button type="primary" icon="el-icon-refresh" @click="updateyearup()"
          >批量更新年级和年份</el-button
        >
        <!-- <el-button
          type="primary"
          icon="el-icon-close"
          @click="toggleSelection()"
          >取消选择</el-button
        > -->

        <el-popover
          class="btn-left"
          placement="bottom"
          width="200"
          trigger="hover"
        >
          <!-- 弹出的内容 -->
          <div>导出原始数据</div>
          <el-button
            v-loading="loadinga"
            slot="reference"
            type="primary"
            icon="el-icon-document"
            @click="openBothUrls"
            :disabled="!isQueryEnabled"
          >
          </el-button>
        </el-popover>
        <el-popover
          class="btn-left"
          placement="bottom"
          width="200"
          trigger="hover"
        >
          <!-- 为第二个按钮添加提示 -->
          <div>导出班级消费明细</div>
          <el-button
            v-loading="loadingb"
            slot="reference"
            type="primary"
            icon="el-icon-document"
            @click="downloadGradeOneBReport"
            :disabled="!isQueryEnabled"
          ></el-button>
        </el-popover>

        <el-popover
          class="btn-left"
          placement="bottom"
          width="200"
          trigger="hover"
        >
          <!-- 为第三个按钮添加提示 -->
          <div>导出每个班级的消费汇总</div>
          <el-button
            v-loading="loadingc"
            slot="reference"
            type="primary"
            icon="el-icon-document"
            @click="downloadGradeOneBReport3"
            :disabled="!isQueryEnabled"
          ></el-button>
        </el-popover>

        <el-popover
          class="btn-left"
          placement="bottom"
          width="200"
          trigger="hover"
        >
          <!-- 为第四个按钮添加提示 -->
          <div>按业务类型导出</div>
          <el-button
            v-loading="loadingd"
            slot="reference"
            type="primary"
            icon="el-icon-document"
            @click="downloadGradeOneBReport4"
            :disabled="!isQueryEnabled"
          ></el-button>
        </el-popover>
      </div>
    </div>

    <div class="batch-update-group">
      <el-dialog
        title="编辑年级与年份"
        :visible.sync="dialogVisible"
        width="30%"
      >
        <div>
          <span>新年级：</span>
          <el-input
            v-model="newGradeId"
            placeholder="请输入新年级"
            class="inline-input"
          ></el-input>

          <!-- 新年份输入框 -->
          <span>新年份：</span>
          <el-date-picker
            v-model="selectedDate"
            type="year"
            placeholder="选择年份"
            class="inline-input"
            @change="onYearChange"
          ></el-date-picker>
        </div>

        <!-- 底部操作区域 -->
        <span slot="footer" class="dialog-footer">
          <el-button @click="dialogVisible = false">取消</el-button>
          <el-button type="primary" @click="handleSubmit">确认</el-button>
        </span>
      </el-dialog>
    </div>

    <el-table
      :data="tableData"
      ref="multipleTable"
      v-loading="loading"
      height="580"
      element-loading-text="拼命加载中"
      style="width: 100%"
      @selection-change="handleSelectionChange"
      stripe
      border
    >
      <el-table-column
        fixed
        type="selection"
        width="55"
        label="选择"
      ></el-table-column>
      <el-table-column
        prop="formattedCreateTime"
        label="日期"
        align="center"
        width="150"
      >
      </el-table-column>
      <el-table-column prop="customerName" label="客户名" align="center">
      </el-table-column>

      <el-table-column prop="gradeName" label="年级" align="center">
      </el-table-column>

      <el-table-column prop="classList" label="班级" align="center">
      </el-table-column>
      <el-table-column prop="content" label="内容" align="center">
      </el-table-column>

      <el-table-column prop="project" label="项目" align="center">
      </el-table-column>
      <el-table-column prop="useCount" label="用份数" align="center">
      </el-table-column>
      <el-table-column prop="quantity" label="数量" align="center">
      </el-table-column>
      <el-table-column prop="paperType" label="纸型" align="center">
      </el-table-column>
      <el-table-column prop="singleDoubleSided" label="单双面" align="center">
      </el-table-column>
      <el-table-column prop="isExtraPrint" label="是否加版" align="center">
      </el-table-column>
      <el-table-column prop="extraUnitPrice" label="加版单价" align="center">
      </el-table-column>
      <el-table-column prop="unitPrice" label="单价" align="center">
      </el-table-column>
      <el-table-column prop="totalPrice" label="总金额" align="center">
      </el-table-column>

      <el-table-column
        prop="address"
        label="操作"
        align="center"
        width="160"
        fixed="right"
      >
        <template slot-scope="{ row }">
          <el-button type="text" @click="editFrom(row)">修改</el-button>
          <el-button type="text" class="btn-err" @click="delForm(row)"
            >删除</el-button
          >
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      hide-on-single-page
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page="search.current"
      :page-sizes="[16, 40, 80, 100, 200]"
      :page-size="search.size"
      layout="total, sizes, prev, pager, next, jumper"
      :total="total"
    >
    </el-pagination>

    <editForm ref="editForm" @call-get-userlists="fetchstatical"></editForm>
  </div>
</template>
<script>
import { getGradelist, getSchoollist } from "@/request/api/business";
import { getstuta, getUserByid } from "@/request/api/login";
import { getPrice } from "@/request/api/price";
import { heStatical } from "@/request/api/statistical";
import editForm from "./components/editForm.vue";
import { getmove } from "@/request/api/move";
import { getStore } from "@/utils/util";
import { updateyears } from "@/request/api/newStatical";
import {
  delscipt,
  export1A,
  export2A,
  export3A,
  export4A,
} from "@/request/api/export";
import { downloadBlob } from "@/utils/util";

export default {
  name: "price",
  components: {
    editForm,
  },

  data() {
    return {
      taskId: "",
      loadinga: false,
      loadingb: false,
      loadingc: false,
      loadingd: false,
      heloading: false,
      isQueryEnabled: false,
      time: "",
      selectedDate: "",
      dialogVisible: false,
      dialogVisible1: false,
      loading: false,
      isSelectDisabled: true,
      selectedBranch: "", // 当前选中的分公司ID
      selectedPriceList: "", // 当前选中的价格表ID
      schools: [], // 学校数据列表
      grades: [], // 年级数据列表
      newGradeId: "", // 用户选择的新年级ID
      newYear: "", // 用户选择的新年份
      branches: [],
      userId: "", // 当前登录用户的ID

      priceLists: [], // 价格表数据列表
      search: {
        gradeId: "",
        userId: "",
        // keyword: "",
        endTime: "",
        startTime: "",
        current: 1,
        size: 16,
      },
      id: [], //勾选的id
      total: 0,

      tableData: [],
      multipleSelection: [],
    };
  },
  created() {
    // 初始化数据，例如从服务器获取分公司和价格表列表
    this.fetchSchools();
    // this.fetchGrades();
  },
  watch: {
    "search.gradeId": {
      handler(newVal, oldVal) {
        if (newVal !== oldVal && this.search.userId !== "") {
          this.checkAllChanged();
        }
      },
      immediate: false,
      deep: true,
    },
    "search.userId": {
      handler(newVal, oldVal) {
        if (newVal !== oldVal && this.search.gradeId !== "") {
          this.checkAllChanged();
        }
      },
      immediate: false,
      deep: true,
    },
    time: {
      handler(newVal, oldVal) {
        if (newVal !== oldVal) {
          this.checkAllChanged();
        }
      },
      immediate: false,
      deep: true,
    },
  },
  destroyed() {
    // 组件销毁时清除定时器
    // clearInterval(this.timer);
    clearInterval(this.timers);
  },

  methods: {
    mmsa() {
      if (this.search.gradeId == 1) {
        this.$message.warning("全年级不能进行核算！");
        return;
      }
      this.dialogVisible1 = true;
    },
    //核算成功判断
    async fetchDatas() {
      try {
        const response = await getstuta({ taskId: this.taskId });

        if (response.msg == 1) {
          this.$alert("复核已经完成", "提示", {
            confirmButtonText: "确定",
            callback: () => {
              this.$message({
                type: "success",
                message: "复核成功！",
              });
              this.fetchstatical();
            },
          });
          this.isQueryEnabled = true;
          this.heloading = false;
          clearInterval(this.timers);
        } else if (response.msg == 2) {
          this.$alert(response.data, "提示", {
            confirmButtonText: "确定",
            callback: () => {
              this.$message({
                type: "success",
                message: "取消成功",
              });
            },
          });
          this.isQueryEnabled = true;
          this.heloading = false;
          clearInterval(this.timers);
        }
      } catch (error) {
        //
      }
    },
    //表二成功判断
    // async fetchData() {
    //   try {
    //     const response = await getstuta({ taskId: this.taskId });
    //     if (response.msg == 1) {
    //       // this.$alert("报表导出已经完成", "提示", {
    //       //   confirmButtonText: "确定",
    //       //   callback: () => {
    //       //     this.$message({
    //       //       type: "info",
    //       //       message: "成功！",
    //       //     });
    //       //   },
    //       // });
    //       let params = {
    //         userId: this.search.userId,
    //         gradeId: this.search.gradeId,
    //         size: this.search.size,
    //         current: this.search.current,
    //       };

    //       if (this.search.endTime && this.search.startTime) {
    //         params.startTime = this.formatDate(this.search.startTime);
    //         params.endTime = this.formatDate(this.search.endTime); // 使用修正后的变量名
    //       }
    //       const res = await export2A(params);
    //       downloadBlob(res);

    //       this.isQueryEnabled = true;
    //       this.loadingb = false;
    //       clearInterval(this.timer);
    //     } else if (response.msg == 2) {
    //       this.$alert(response.data, "提示", {
    //         confirmButtonText: "确定",
    //         callback: (action) => {
    //           this.$message({
    //             type: "info",
    //             message: `action: ${action}`,
    //           });
    //         },
    //       });
    //       this.isQueryEnabled = true;
    //       this.loadingb = false;
    //       clearInterval(this.timer);
    //     }
    //   } catch (error) {
    //     // console.error("Error fetching data:", error);
    //     // else if (response.msg === 0) {
    //     //   this.loadingv = false;
    //     // }
    //   }
    // },

    checkAllChanged() {
      if (
        this.search.gradeId !== "" &&
        this.search.userId !== "" &&
        this.time !== ""
      ) {
        this.isQueryEnabled = true;
      }
    },
    //业务删除功能
    async delForm(row) {
      // console.log(row.id);
      const confirmed = await this.$confirm(
        "此操作将永久删除该条报表数据, 是否继续?",
        "提示",
        {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        }
      );

      // 如果确认删除
      if (confirmed) {
        delscipt(row.id)
          .then(() => {
            this.$message.success("删除成功！");
            // 从表格数据中移除已删除的项
            this.tableData = this.tableData.filter(
              (item) => item.id !== row.id
            );
            // 重新加载数据以获取最新的列表
            this.fetchstatical();
          })
          .catch(() => {
            // 错误消息
            // this.$message.error("删除失败，请重试！");
          });
      }
    },

    //表1
    async openBothUrls() {
      this.loadinga = true;
      let params = {
        userId: this.search.userId,
        gradeId: this.search.gradeId,
        size: this.search.size,
        current: this.search.current,
      };

      if (this.search.endTime && this.search.startTime) {
        params.endTime = this.formatDate(this.search.endTime);
        params.startTime = this.formatDate(this.search.startTime);
      }

      // console.log(params); // 根据需要决定是否保留这行日志输出
      // console.log("c11c1111");
      try {
        const res = await export1A(params);
        // console.log(res);
        downloadBlob(res);
      } catch (error) {
        // console.error("导出失败:", error);
        // this.$message.error("导出失败，请重试!"); // 增加错误提示
      } finally {
        this.loadinga = false;
      }
    },
    //表2
    async downloadGradeOneBReport() {
      this.loadingb = true;
      let params = {
        userId: this.search.userId,
        gradeId: this.search.gradeId,
        size: this.search.size,
        current: this.search.current,
      };

      if (this.search.endTime && this.search.startTime) {
        params.startTime = this.formatDate(this.search.startTime);
        params.endTime = this.formatDate(this.search.endTime); // 使用修正后的变量名
      }

      try {
        // this.loadingb = false;
        // this.isQueryEnabled = false;
        // this.taskId = res.data;
        // this.timer = setInterval(this.fetchData, 3000);
        // console.log(this.taskId);

        const res = await export2A(params);
        downloadBlob(res);
      } catch (error) {
        // console.error("下载年级表一B时发生错误:", error);
        // this.$message.error("下载年级表一B失败，请重试!");
      } finally {
        this.loadingb = false;
      }
    },
    //表3
    async downloadGradeOneBReport3() {
      this.loadingc = true;
      let params = {
        userId: this.search.userId,
        gradeId: this.search.gradeId,
        size: this.search.size,
        current: this.search.current,
      };

      if (this.search.endTime && this.search.startTime) {
        params.startTime = this.formatDate(this.search.startTime);
        params.endTime = this.formatDate(this.search.endTime); // 使用修正后的变量名
      }

      try {
        const res = await export3A(params);
        downloadBlob(res);
      } catch (error) {
        // console.error("下载年级表一B时发生错误:", error);
        // this.$message.error("下载年级表一B失败，请重试!");
      } finally {
        this.loadingc = false;
      }
    },
    //表4
    async downloadGradeOneBReport4() {
      this.loadingd = true;
      let params = {
        userId: this.search.userId,
        gradeId: this.search.gradeId,
        size: this.search.size,
        current: this.search.current,
      };
      if (this.search.endTime && this.search.startTime) {
        params.startTime = this.formatDate(this.search.startTime);
        params.endTime = this.formatDate(this.search.endTime); // 使用修正后的变量名
      }

      try {
        const res = await export4A(params);
        downloadBlob(res);
      } catch (error) {
        // console.error("下载年级表一B时发生错误:", error);
        // this.$message.error("下载年级表一B失败，请重试!");
      } finally {
        this.loadingd = false;
      }
    },
    formatDate(date) {
      if (date) {
        let d = new Date(date);
        return `${d.getFullYear()}-${("0" + (d.getMonth() + 1)).slice(-2)}-${(
          "0" + d.getDate()
        ).slice(-2)}`;
      }
      return ""; // 如果date为空，则返回空字符串
    },

    onYearChange(value) {
      // 当年份选择改变时，此方法会被调用
      if (value) {
        // 获取年份并存入 newYear
        this.newYear = value.getFullYear();
      } else {
        // 清空处理
        this.newYear = "";
      }
    },
    //判断是否选择了学校
    mays() {
      if (this.selectedSchool !== "") {
        this.isSelectDisabled = false;
      }
    },

    editFrom(row) {
      this.$refs.editForm.inits(row, this.grades);
    },
    //获取当前用户的姓名
    async fetchName() {
      try {
        // 使用axios或其他HTTP客户端库来请求数据
        // console.log(this.userId);
        await getUserByid(this.search.userId);
      } catch (error) {
        // console.error("Failed to fetch schools", error);
      }
    },

    //获取当前用户的价格表
    async fetchPriceList() {
      try {
        // 使用axios或其他HTTP客户端库来请求数据
        // console.log(this.userId);
        const res = await getPrice(this.search.userId);
        // console.log(res.data);
        this.priceLists = res.data.records; // 假设后端返回的数据直接赋值给schools
      } catch (error) {
        // console.error("Failed to fetch schools", error);
      }
    },

    //获取勾选项的id
    handleSelectionChange(val) {
      this.multipleSelection = val;
      this.id = this.multipleSelection.map((item) => item.id);
      // console.log(this.id); // 打印选中的id数组，便于调试
    },
    handleSchoolChange() {
      // console.log(this.ruleForm.school);
      this.fetchGrades();
      // console.log("123");
      this.fetchPriceList();
      this.fetchName();
      this.search.gradeId = "";
      this.tableData = [];
    },

    //打开修改年份年级的弹窗
    updateyearup() {
      //判断是否勾选
      if (this.id == "") {
        this.$message.warning("请先选择后再进行操作！");
      } else {
        this.dialogVisible = true;
      }
    },
    //批量修改年级、年份功能
    async handleSubmit() {
      try {
        const params = {
          newGradeName: this.newGradeId,
          newYear: this.newYear,
        };
        let data = JSON.parse(JSON.stringify(this.id));
        await updateyears(params, data);
        this.$message.success("修改成功!");
        this.fetchstatical();
        this.dialogVisible = false;
      } catch (error) {
        // console.error("Failed to update year and grade:", error);
      }
    },

    //核算功能
    async heStaticalup() {
      this.heloading = true;
      try {
        const params = {
          userId: this.search.userId,
          priceListId: this.selectedPriceList,
          gradeId: this.search.gradeId,
        };
        if (this.search.endTime && this.search.startTime) {
          params.startTime = this.formatDate(this.search.startTime);
          params.endTime = this.formatDate(this.search.endTime); // 使用修正后的变量名
        }

        //  console.log(params);
        this.isQueryEnabled = false;
        const res = await heStatical(params);
        // this.fetchstatical();
        this.taskId = res.data;

        this.timers = setInterval(this.fetchDatas, 3000);
        // this.$message.success("复核成功！");
        this.dialogVisible1 = false;
        // console.log(res.data);
      } catch (error) {
        // console.error("Failed to fetch grades", error);
      }
      // finally {
      //   this.heloading = false;
      // }
    },

    getNew() {
      this.search.current = 1;
      this.fetchstatical();
    },
    //获取报表数据

    async fetchstatical() {
      this.loading = true;
      try {
        // const params = {
        //   size: this.search.size,
        //   current: this.search.current,
        // };
        const [startTime, endTime] = this.time;

        this.search.startTime = startTime ? this.formatDate(startTime) : "";
        this.search.endTime = endTime ? this.formatDate(endTime) : "";

        const params = {
          ...this.search,
          ...(startTime && { startTime: this.search.startTime }),
          ...(endTime && { endTime: this.search.endTime }),
        };
        if (params.gradeId === 1) {
          params.gradeId = "";
        }

        // console.log(params);

        // 使用axios或其他HTTP客户端库来请求数据
        const res = await getmove(params);
        res.data.records.forEach((item) => {
          if (item.date) {
            const date = new Date(item.date);
            item.formattedCreateTime = `${date.getFullYear()}年${(
              "0" +
              (date.getMonth() + 1)
            ).slice(-2)}月${("0" + date.getDate()).slice(-2)}日`;
          }
        });
        this.tableData = res.data.records;
        this.$nextTick(() => {
          this.$refs.multipleTable.bodyWrapper.scrollTop = 0;
        });
        this.total = res.data.total;
        this.pages =
          res.data.pages || Math.ceil(this.total / this.search.limit);

        // console.log(res.data);
      } catch (error) {
        // console.error("Failed to fetch grades", error);
      } finally {
        this.loading = false;
      }
    },

    //获取班级信息
    async fetchGrades() {
      try {
        // console.log(this.search.userId);
        // 使用axios或其他HTTP客户端库来请求数据
        const res = await getGradelist(this.search.userId);

        // console.log(res.data);
        // this.grades = res.data; // 假设后端返回的数据直接赋值给grades
        this.grades = [
          ...res.data,
          { id: 0, name: "部门" },
          { id: 1, name: "全年级" },
        ];
      } catch (error) {
        // console.error("Failed to fetch grades", error);
      }
    },

    // 获取学校下拉框
    async fetchSchools() {
      try {
        const userInfo = getStore({ name: "userinfo" });
        // 使用axios或其他HTTP客户端库来请求数据
        const params = {
          userId: userInfo.id,
          roleType: userInfo.roleType - 2,
        };
        const res = await getSchoollist(params);
        // console.log(res);
        this.schools = res.data.records; // 假设后端返回的数据直接赋值给schools
      } catch (error) {
        // console.error("Failed to fetch schools", error);
      }
    },

    handleDateChange() {
      this.tableData = [];
    },

    handleGradeChange() {
      this.tableData = [];
    },
    handleSchoolChanges() {
      this.fetchName();
      this.fetchPriceList();
    },
    fetchPriceLists() {
      this.priceLists = [];
    },

    //删除操作
    onDelete() {
      this.$message.warning("请确认要删除的内容");
      // console.log("删除操作");
      // 实现删除逻辑
    },

    //分页的处理
    handleSizeChange(val) {
      this.search.size = val;
      this.fetchstatical(); // 页大小改变时重新加载数据
      // this.search.size = 16;
    },

    handleCurrentChange(val) {
      this.search.current = val;
      this.fetchstatical(); // 页码改变时重新加载数据
      // this.search.current = 1;
    },

    //取消选择
    toggleSelection(rows) {
      if (rows) {
        rows.forEach((row) => {
          this.$refs.multipleTable.toggleRowSelection(row);
        });
      } else {
        this.$refs.multipleTable.clearSelection();
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.selector-group {
  display: flex;
  align-items: center;
  gap: 10px; /* 调整组件间的间距 */
}
.inline-input {
  width: 30%; /* 设置输入框宽度为容器的一半 */
  box-sizing: border-box; /* 包含边框和内填充在内，确保总宽度为50% */
  margin: 10px; /* 输入框之间的间距 */
}
.btn-left {
  margin-left: 10px;
  // margin-right: 10px;
}
.button-group {
  margin-top: 10px;
}
.spand {
  margin-left: 10px;
}
.custom-date-picker {
  width: 150px; /* 自定义宽度 */
  margin-left: 15px;
}
</style>
