import Vue from "vue";
import VueRouter from "vue-router";
import HomeView from "../views/HomeView.vue";
import NotFound from "../views/NotFound.vue";
import { clearLogonStore, getStore } from "@/utils/util";
import store from "@/store/index.js";
// 菜单的路由
import { routeArr } from "./menuRouter";
Vue.use(VueRouter);
const routes = [
  {
    path: "/",
    name: "home",
    component: HomeView,
    children: [].concat(routeArr),
  },
  {
    path: "/login",
    name: "login",
    meta: {
      routeWhitelist: true,
      notRouter: true,
    },
    component: () => import("@/views/login.vue"),
  },
  {
    path: "*",
    component: NotFound,
  },
];

const router = new VueRouter({
  routes,
});
//路由卫士  不会报错  注意
router.beforeEach((to, from, next) => {
  if (to.meta.title) {
    document.title = `业务管理系统 | ${to.meta.title}`;
  } else {
    document.title = "业务管理系统";
  }
  // if (to.meta && to.meta.title) {
  store.commit("SET_ROUTING_LIST", to);
  // }

  // 判断路由跳转  如果用户4小时未操作  清楚缓存
  let time = getStore({ name: "lastRequestTime" });
  let newTime = parseInt(new Date().getTime() / 1000);
  if (!time) {
    clearLogonStore();
  } else {
    if (newTime - Number(time) > 7200 * 2) {
      clearLogonStore();
    }
  }
  // 特殊判断不需要登录
  if (to.meta.routeWhitelist) {
    next();
    return;
  }
  const userinfo = getStore({ name: "userinfo" });
  if (!userinfo) {
    clearLogonStore();
    next({ path: "/login" });
    return;
  }
  next();
});
// 处理异常路由
const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location, resolve, reject) {
  if (resolve || reject)
    return originalPush.call(this, location, resolve, reject);
  return originalPush.call(this, location).catch(() => {
    //
  });
};

export default router;
