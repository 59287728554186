import request from "@/request/axios";

// 获取迁移表中分页信息
export const getmove = (data) => {
  return request({
    url: "/report/list",
    method: "get",
    params: data,
  });
};

// 迁移
export const movedata = (data) => {
  return request({
    url: "/report/dataMigration",
    method: "post",
    params: data,
  });
};
