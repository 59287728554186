import request from "@/request/axios";

// 根据id获取业务信息
export const getBusinessinfo = (userId, { page, size }) => {
  return request({
    url: `/business/list/${userId}`,
    method: "get",
    params: {
      page,
      size,
    },
  });
};

// 获取业务分页信息
export const getBusinesslist = (data) => {
  return request({
    url: "/business/list",
    method: "get",
    params: data,
  });
};

// 添加业务信息
export const addBusiness = (data) => {
  return request({
    url: "/business/add",
    method: "post",
    data,
  });
};

// 删除业务信息
export const delBusiness = (id) => {
  return request({
    url: `/business/delete/${id}`,
    method: "delete",
  });
};

// 更新业务信息
export const updateBusiness = (data) => {
  return request({
    url: "/business/update",
    method: "put",
    data,
  });
};

// 获取学校的信息
export const getSchoollist = ({ userId, roleType }) => {
  return request({
    url: "/user/school",
    method: "get",
    params: {
      userId,
      roleType,
    },
  });
};

// 获取年级的信息
export const getGradelist = (userId) => {
  // 确保userId存在且为非空，根据实际情况调整验证条件

  return request({
    url: `/user/grades/${userId}`, // 使用模板字符串将userId作为路径参数
    method: "get",
  });
};

// 获取班级列表的API
export const getClassList = ({ userId, gradeId }) => {
  return request({
    url: "/user/class",
    method: "get",
    params: {
      userId,
      gradeId,
    },
  });
};
// 印刷方式获取
export const getlistMethod = () => {
  return request({
    url: "/paper/listMethod",
    method: "get",
    // params: {
    //   custName,
    // },
  });
};

// 纸张类型获取
export const getpaper = () => {
  return request({
    url: "/paper/listType",
    method: "get",
    // params: {
    //   custName,
    // },
  });
};

//核算功能

export const Hcalculate = (data) => {
  return request({
    url: "/business/calculate",
    method: "post",
    params: data,
  });
};
export const getexportBusiness = (data) => {
  return request({
    url: "/business/export",
    method: "get",
    params: data,
    responseType: "blob", // 添加此行来指定响应类型为Blob
  });
};

export const getexportBusiness1 = async (params) => {
  try {
    const response = await request({
      url: "/business/export",
      method: "get",
      params,
      responseType: "blob", // 添加此行来指定响应类型为Blob
    });

    // 处理Blob响应，触发文件下载
    const url = window.URL.createObjectURL(new Blob([response]));
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", "业务列表.xlsx"); // 设置下载文件的名称
    document.body.appendChild(link);
    link.click();
    window.URL.revokeObjectURL(url);

    return response; // 如果需要的话，可以返回响应，但实际操作中可能不需要
  } catch (error) {
    console.error("下载文件时发生错误:", error);
    throw error; // 重新抛出错误以便调用者可以处理
  }
};
