<template>
  <div class="editForm">
    <el-dialog
      title="新增用户"
      :visible.sync="dialogVisible"
      width="400px"
      :close-on-click-modal="false"
    >
      <el-form
        :model="ruleForm"
        :rules="rules"
        ref="ruleForm"
        label-width="70px"
      >
        <el-form-item label="用户名" prop="username">
          <el-input
            v-model="ruleForm.username"
            placeholder="请输入用户名"
          ></el-input>
        </el-form-item>
        <!-- <el-form-item label="姓名" prop="name">
          <el-input v-model="ruleForm.name" placeholder="请输入姓名"></el-input>
        </el-form-item> -->

        <el-form-item label="密码" prop="password">
          <el-input
            v-model="ruleForm.password"
            placeholder="请输入密码"
          ></el-input>
        </el-form-item>
        <el-form-item label="手机号" prop="phone">
          <el-input
            v-model="ruleForm.phone"
            placeholder="请输入手机号"
          ></el-input>
        </el-form-item>
        <el-form-item label="角色" prop="roleType">
          <el-select
            v-model="ruleForm.roleType"
            filterable
            placeholder="请选择角色"
            style="width: 100%"
            @change="handleRoleChange"
          >
            <el-option label="学校管理员" :value="0"> </el-option>
            <el-option label="文印室" :value="1"> </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="学校" prop="schoolName" v-show="!isSchoolAdmin">
          <el-input
            v-model="ruleForm.schoolName"
            filterable
            placeholder="请选择学校"
            style="width: 100%"
          >
          </el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="submitAddUser()"> 确 定 </el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { getCustomerlist } from "@/request/api/customer";
import { addUser } from "@/request/api/login";

export default {
  name: "EditForm",
  data() {
    return {
      search: {
        keyword: "",
        page: 1, // 当前页码
        limit: 10, // 每页显示数量
      },
      options: [],

      dialogVisible: false,
      isSchoolAdmin: true, // 用于跟踪是否选择了学校管理员
      ruleForm: {
        password: "",
        name: "",
        username: "",
        schoolId: "",
        roleType: "", // 初始化为字符串，以匹配验证规则中的预期类型
        phone: "",
        schoolName: "",
      },
      rules: {
        username: [
          { required: true, message: "请输入用户名", trigger: "blur" },
          { min: 2, max: 8, message: "用户名长度应为2到8位", trigger: "blur" },
        ],
        name: [
          { required: true, message: "请输入姓名", trigger: "blur" },
          { min: 2, max: 8, message: "用户名长度应为2到8位", trigger: "blur" },
        ],

        password: [
          { required: true, message: "请输入密码", trigger: "blur" },
          { min: 6, max: 18, message: "密码长度应为6到18位", trigger: "blur" },
        ],
        phone: [
          { required: true, message: "请输入手机号", trigger: "blur" },
          {
            pattern: /^1[3-9]\d{9}$/,
            message: "手机号格式不正确",
            trigger: "blur",
          },
        ],
        roleType: [
          { required: true, message: "请选择角色", trigger: "change" },
        ],
        schoolId: [
          { required: true, message: "请选择学校", trigger: "change" },
        ],
      },
    };
  },
  created() {
    this.getSchoollists();
  },
  methods: {
    async getSchoollists() {
      const params = {
        size: 1000,
        current: 1,
      };
      const res = await getCustomerlist(params);
      this.options = res.data.records;

      // console.log(res);
    },
    //管理员不能选择学校，文印室可以选择学校
    handleRoleChange() {
      const newValue = this.ruleForm.roleType;
      this.isSchoolAdmin = false;
      if (newValue === 0) {
        this.isSchoolAdmin = true;
      } // 0代表学校管理员
    },
    init() {
      this.dialogVisible = true;
      // 初始化表单数据
      // this.$refs.ruleForm.resetFields();
    },
    //提交表单
    submitAddUser() {
      this.dialogVisible = false;
      // 创建用户对象并加密密码
      const SysUser = {
        ...this.ruleForm,
        // password: md5(this.ruleForm.password),
      };

      // 调用添加用户的方法
      addUser(SysUser)
        .then(() => {
          // 显示成功消息
          this.$message({
            message: "添加成功！",
            type: "success",
            showClose: true,
          });

          // 触发更新用户列表的事件
          this.$emit("call-get-userlists");
        })
        .catch(() => {});
    },
  },
};
</script>

<style lang="scss" scoped></style>
