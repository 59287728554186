import request from "@/request/axios";
// 获取当前用户的班级列表

export const getclazz = ({ userId }) => {
  return request({
    url: "/class/userinfo",
    method: "get",
    params: {
      userId,
    },
  });
};

// 添加班级表
export const addclazz = (data) => {
  return request({
    url: "/class/add",
    method: "post",
    data,
  });
};

//科目人数查询

export const getsujectnum = (classId) => {
  return request({
    url: `/class/subjects/${classId}`,
    method: "get",
  });
};

// 更新科目人数
export const updateSubjectnum = (data) => {
  return request({
    url: "/class/update",
    method: "put",
    data,
  });
};

//获取该年级的全部班级
export const getclassByid = (gradeId) => {
  return request({
    url: `/class/classes/${gradeId}`,
    method: "get",
  });
};
