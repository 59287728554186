<template>
  <div class="editFrom">
    <!-- 编辑信息模块 -->
    <el-dialog
      title="编辑账户信息"
      :visible.sync="dialogVisible"
      width="380px"
      :close-on-click-modal="false"
    >
      <el-form
        :model="ruleForm1"
        :rules="rules"
        ref="ruleForm1"
        label-width="70px"
      >
        <el-form-item label="学校" prop="schoolName">
          <el-input
            v-model="ruleForm1.schoolName"
            placeholder="请输入学校"
          ></el-input>
        </el-form-item>

        <el-form-item label="手机号" prop="phone">
          <el-input
            v-model="ruleForm1.phone"
            placeholder="请输入手机号"
          ></el-input>
        </el-form-item>

        <el-form-item label="地址" prop="address">
          <el-input
            v-model="ruleForm1.address"
            placeholder="请输入地址"
          ></el-input>
        </el-form-item>

        <el-form-item label="个性签名" prop="notes">
          <el-input
            v-model="ruleForm1.notes"
            placeholder="请填写个性签名(非必填)"
            type="textarea"
          ></el-input>
        </el-form-item>

        <!-- <el-form-item label="角色" prop="roleType">
          <el-select
            v-model="ruleForm1.roleType"
            filterable
            placeholder="请选择角色"
            style="width: 100%"
            @change="handleRoleChange"
          >
            <el-option label="学校管理员" :value="0"> </el-option>
            <el-option label="文印室" :value="1"> </el-option>
          </el-select>
        </el-form-item> -->

        <!-- <el-form-item label="密码" prop="password">
          <el-input
            v-model="ruleForm1.password"
            show-password
            placeholder="请输入密码"
          ></el-input>
        </el-form-item> -->
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="updateUserup()"> 确 定 </el-button>
      </span>
    </el-dialog>

    <!-- 修改密码模块 -->
    <!-- 验证原密码 -->
    <el-dialog
      title="修改密码"
      :visible.sync="Visible"
      width="380px"
      :close-on-click-modal="false"
    >
      <el-form
        :model="ruleForm"
        :rules="rules"
        ref="ruleForm"
        label-width="80px"
      >
        <el-form-item label="原密码" prop="password">
          <el-input
            v-model="ruleForm.password"
            show-password
            placeholder="请输入密码"
          ></el-input>
        </el-form-item>

        <el-form-item label="新密码" prop="newPassword">
          <el-input
            v-model="ruleForm.newPassword"
            show-password
            placeholder="请输入新密码"
          ></el-input>
        </el-form-item>

        <el-form-item label="确认密码" prop="repassword">
          <el-input
            v-model="ruleForm.repassword"
            show-password
            placeholder="请确认密码"
          ></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="Visible = false">取 消</el-button>
        <el-button type="primary" @click="submit()"> 确 定 </el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import { levelGroup } from "@/utils/dictionaries";
import { checkPassword, updatePassword, updateUser } from "@/request/api/login";

import { getStore } from "@/utils/util";
import { md5 } from "js-md5";
export default {
  name: "addFrom",
  data() {
    return {
      Visibles: false,
      Visible: false,
      dialogVisible: false,
      levelGroup: levelGroup,
      ruleForm: {
        password: "", //旧密码
        newPassword: "", //新密码
        repassword: "", //确认新密码
      },

      rules: {
        // Password validation for the original password field in ruleForm
        password: [
          { required: true, message: "原密码不能为空", trigger: "blur" },
          { min: 6, max: 18, message: "密码长度应为6到18位", trigger: "blur" },
        ],

        // New password validation in mans
        newPassword: [
          { required: true, message: "新密码不能为空", trigger: "blur" },
          { min: 6, max: 18, message: "密码长度应为6到18位", trigger: "blur" },
        ],

        // Confirm password validation (assuming you rename repassword to confirmPassword for clarity)
        repassword: [
          { required: true, message: "确认密码不能为空", trigger: "blur" },
          { min: 6, max: 18, message: "密码长度应为6到18位", trigger: "blur" },
        ],
        name: [
          { required: true, message: "请输入姓名", trigger: "blur" },
          { min: 2, max: 8, message: "姓名长度应为2到8位", trigger: "blur" },
        ],
        phone: [
          { required: true, message: "请输入手机号", trigger: "blur" },
          {
            pattern: /^1[3-9]\d{9}$/,
            message: "手机号格式不正确",
            trigger: "blur",
          },
        ],
        address: [
          { required: true, message: "请输入地址", trigger: "blur" },
          {
            pattern: /^[A-Za-z0-9\u4e00-\u9fa5]+$/,
            message: "地址格式不正确",
            trigger: "blur",
          },
        ],
      },

      ruleForm1: {
        //password: "", //密码
        name: "", //名字
        // roleType: "", // 初始化为字符串，以匹配验证规则中的预期类型
        phone: "", //手机号
        notes: "", //个人签名
        address: "", //地址
        id: "", //用户ID
      },
    };
  },

  created() {},
  methods: {
    init(mm) {
      //打开修改信息的窗口
      this.dialogVisible = true;
      // console.log(mm);
      this.ruleForm1 = JSON.parse(JSON.stringify(mm));
      //数据回调
      // const data = this.ruleForm;
      // console.log(data);
      // data.password = "";
      // //根据返回的-1，0，1判断角色
      // if (data.roleType == -1) {
      //   data.roleType = "超级管理员";
      // }

      // if (data.roleType == 0) {
      //   data.roleType = "院校管理员";
      // }
      // if (data.roleType == 1) {
      //   data.roleType = "文印室";
      // }
      // this.ruleForm1 = data;
    },
    inits() {
      this.Visible = true;
    },

    // 验证新密码和确认密码是否一致
    validatePasswordMatch() {
      const newPassword = this.ruleForm.newPassword;
      const confirmPassword = this.ruleForm.repassword;

      // 新增：检查新密码和确认密码是否为空
      if (!newPassword || !confirmPassword) {
        this.$message.error("新密码和确认密码不能为空，请输入！");
        return false;
      }

      // 检查新密码和确认密码是否一致
      if (newPassword !== confirmPassword) {
        this.$message.error("新密码和确认密码不一致，请重新输入！");
        return false;
      } else {
        this.submits();
        return true;
      }
    },

    async submits() {
      try {
        const userInfo = await getStore({ name: "userinfo" });
        //将数据转换成json格式
        let data = JSON.parse(JSON.stringify(this.ruleForm));
        //对密码进行md5加密
        data.newPassword = md5(data.newPassword);
        data.id = userInfo.id;
        updatePassword(data); // 使用加密后的密码数据
        // this.$message.success("密码修改成功！请重新登录");
        this.Visibles = false;
        this.$router.push({
          path: "/login",
        });
        this.$message.success("密码修改成功！请重新登录");
        // this.$emit("call-get-userlists");
      } catch (error) {
        // console.error("Error updating password:", error);
      }
    },

    async submit() {
      this.Visible = false;
      // 复制表单数据并加密密码
      let data = JSON.parse(JSON.stringify(this.ruleForm));
      data.password = md5(data.password);

      try {
        // 验证密码
        await checkPassword(data);
        this.validatePasswordMatch();
        // 提示用户是否继续删除操作
      } catch (error) {
        // 密码验证失败
      }
    },

    //修改用户信息
    async updateUserup() {
      this.dialogVisible = false;
      //数据准备
      //拿取登录中缓存的数据
      const userInfo = getStore({ name: "userinfo" });
      //将数据转换成json格式
      let data = JSON.parse(JSON.stringify(this.ruleForm1));
      //对密码进行md5加密
      // data.password = md5(data.password);
      data.id = userInfo.id;
      //开始进行请求
      await updateUser(data)
        .then(() => {
          //请求成功
          this.$message({
            message: "修改成功！",
            type: "success",
            showClose: true,
          });
          this.$emit("call-get-userlists");
          this.ruleForm1 = [];
        })
        .catch(() => {});
    },
  },
};
</script>
<style lang="scss" scoped></style>
