<template>
  <div class="editFrom">
    <el-drawer
      :title="this.ruleForm.id ? '修改价格表项' : '添加价格表项'"
      :visible.sync="dialogVisible"
      width="380px"
      :before-close="dialog"
      :close-on-click-modal="false"
    >
      <el-form
        :model="ruleForm"
        ref="ruleForm"
        label-width="80px"
        :rules="rules"
      >
        <el-form-item
          label="价格表"
          v-if="!this.ruleForm.id"
          prop="priceListId"
        >
          <el-select
            v-model="ruleForm.priceListId"
            placeholder="请选择价格表"
            style="width: 100%"
            title="请先选择公司，才能选择价格表"
          >
            <el-option
              clearable
              v-for="priceList in priceLists"
              :key="priceList.id"
              :label="priceList.name"
              :value="priceList.id"
            ></el-option>
          </el-select>
        </el-form-item>

        <el-form-item label="印刷方式" prop="project">
          <el-select
            clearable
            v-model="ruleForm.project"
            filterable
            placeholder="请选择印刷方式"
            style="width: 100%"
            @keyup.native="handleKeyup"
          >
            <el-option
              v-for="method in printingMethods"
              :key="method.id"
              :label="method.printingMethod"
              :value="method.printingMethod"
            ></el-option>
          </el-select>
        </el-form-item>

        <el-form-item label="单/双面" prop="singleDoubleSided">
          <el-select
            clearable
            v-model="ruleForm.singleDoubleSided"
            filterable
            placeholder="请选择单双面"
            style="width: 100%"
            @keyup.native="handleKeyup"
          >
            <el-option
              v-for="item in side"
              :key="item.value"
              :label="item.label"
              :value="item.label"
            ></el-option>
          </el-select>
        </el-form-item>

        <el-form-item label="纸型" prop="paperType">
          <el-select
            clearable
            v-model="ruleForm.paperType"
            filterable
            placeholr="请选择纸型"
            style="width: 100%"
            @keyup.native="handleKeyup"
          >
            <el-option
              v-for="item in options"
              :key="item.id"
              :label="item.paperType"
              :value="item.paperType"
            ></el-option>
          </el-select>
        </el-form-item>

        <el-form-item label="加版单价" prop="extraUnitPrice">
          <el-input
            v-model="ruleForm.extraUnitPrice"
            placeholder="请输入加版单价(元)"
          ></el-input>
        </el-form-item>

        <el-form-item label="单价" prop="unitPrice">
          <el-input
            v-model="ruleForm.unitPrice"
            style="width: 100%"
            placeholder="请输入单价(元)"
          ></el-input>
        </el-form-item>

        <el-form-item>
          <el-button @click="dialog()">取 消</el-button>
          <el-button type="primary" @click="submitForm()"> 确 定 </el-button>
        </el-form-item>
      </el-form>
      <!-- <span slot="footer" class="dialog-footer">
        <el-button @click="dialog()">取 消</el-button>
        <el-button type="primary" @click="submitForm()"> 确 定 </el-button>
      </span> -->
    </el-drawer>
  </div>
</template>
<script>
import { levelGroup } from "@/utils/dictionaries";
import { addPriceitem, updatePriceitem } from "@/request/api/priceitem";
import { getlistMethod, getpaper } from "@/request/api/business";
import { getPrice } from "@/request/api/price";
export default {
  name: "editFrom",
  data() {
    return {
      dialogVisible: false,
      levelGroup: levelGroup,
      seacher: {
        size: 10,
        page: 1,
      },
      ruleForm: {
        // id: "",
        project: "",
        singleDoubleSided: "",
        paperType: "",
        extraUnitPrice: "",
        unitPrice: "",
      },
      printingMethods: [],
      userId: "",
      options: [],
      priceLists: [],

      side: [
        // 定义单双面
        { value: "1", label: "单面" },
        { value: "2", label: "双面" },
      ],
      rules: {
        // 添加加版单价的验证规则
        extraUnitPrice: [
          { required: true, message: "请输入加版单价", trigger: "blur" },
          {
            pattern: /^\d+(\.\d{1,3})?$/,
            message: "加版单价必须是数字，且最多保留三位小数",
            trigger: "blur",
          },
        ],
        // 添加单价的验证规则
        unitPrice: [
          { required: true, message: "请输入单价", trigger: "blur" },
          {
            pattern: /^\d+(\.\d{1,3})?$/,
            message: "单价必须是数字，且最多保留三位小数",
            trigger: "blur",
          },
        ],
      },
    };
  },
  created() {
    this.fetchpapertype();
    this.fetchtype();
  },
  methods: {
    inits(row, dd) {
      this.dialogVisible = true;
      this.ruleForm = JSON.parse(JSON.stringify(row));
      this.userId = dd;
      // this.priceLists = {
      //   id: row.priceListId,
      //   name: row.priceListName,
      // };
      // this.ruleForm = JSON.parse(JSON.stringify(this.ruleForm));
      // console.log(this.priceLists);
    },
    init(row) {
      this.userId = row;
      this.dialogVisible = true;
      this.fetchPriceList();
    },
    //当点击取消按钮或者关闭弹窗时触发
    //将数据重置
    dialog() {
      this.dialogVisible = false;
      this.ruleForm = [];
    },

    //获取价格表下拉框
    async fetchPriceList() {
      try {
        const res = await getPrice(this.userId);
        console.log(res.data);
        this.priceLists = res.data.records; // 假设后端返回的数据直接赋值给schools
      } catch (error) {
        //
      }
    },

    //获取纸张类型
    async fetchpapertype() {
      try {
        const res = await getpaper();

        this.options = res.data;
      } catch (error) {
        //
      }
    },

    async fetchtype() {
      try {
        // 使用axios或其他HTTP客户端库来请求数据
        const res = await getlistMethod();

        this.printingMethods = res.data;
      } catch (error) {
        //
      }
    },

    async submitForm() {
      // 首先验证表单
      this.$refs["ruleForm"].validate(async (valid) => {
        if (valid) {
          try {
            let data = JSON.parse(JSON.stringify(this.ruleForm));

            if (this.ruleForm.id) {
              // 如果有id，则执行修改逻辑
              await updatePriceitem(data);
              this.dialogVisible = false;
              this.$message.success("修改成功");
            } else {
              // 否则执行添加逻辑
              await addPriceitem(data);
              this.$message.success("添加成功");
              // this.$emit("call-get-userlists");
            }

            // 成功后关闭对话框并刷新列表
            // this.dialogVisible = false;
            this.$emit("call-get-userlists");
          } catch (error) {
            // this.$message.error("操作失败，请重试");
          }
        } else {
          // 校验不通过时的处理，可选
          this.$message.warning("请检查表单填写是否有误");
          return false; // 阻止后续逻辑执行
        }
      });
    },

    resetForm() {
      // 重置表单数据
      this.ruleForm = {
        priceListId: "",
        project: "",
        singleDoubleSided: "",
        paperType: "",
        extraUnitPrice: "",
        unitPrice: "",
      };
    },
  },
};
</script>
<style lang="scss" scoped></style>
