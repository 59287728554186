<template>
  <div class="editFrom">
    <el-dialog
      title="修改客户信息"
      :visible.sync="dialogVisible"
      width="380px"
      :close-on-click-modal="false"
    >
      <el-form
        :model="ruleForm"
        :rules="rules"
        ref="ruleForm"
        label-width="70px"
      >
        <el-form-item label="客户名" prop="schoolName">
          <el-input
            v-model="ruleForm.schoolName"
            placeholder="请输入客户名"
          ></el-input>
        </el-form-item>
        <!-- <el-form-item label="学段" prop="type">
          <el-select
            v-model="ruleForm.type"
            placeholder="请选择学段"
            style="width: 100%"
          >
            <el-option
              :label="item.label"
              :value="item.label"
              v-for="(item, index) in levelGroup"
              :key="index"
            ></el-option>
          </el-select>
        </el-form-item> -->
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="updateUserup()"> 确 定 </el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import { levelGroup } from "@/utils/dictionaries";

import { UpdateCustomer } from "@/request/api/customer";
export default {
  name: "addFrom",
  data() {
    return {
      dialogVisible: false,
      levelGroup: levelGroup,
      ruleForm: {
        schoolName: "",
        type: "",
        id: "",
      },
      rules: {
        schoolName: [
          { required: true, message: "请输入客户名称", trigger: "blur" },
          {
            min: 2,
            max: 30,
            message: "长度在 2 到 30 个字符",
            trigger: "blur",
          },
        ],
        type: [{ required: true, message: "请选择学段", trigger: "change" }],
      },
    };
  },
  created() {},
  methods: {
    init(row) {
      this.dialogVisible = true;
      this.ruleForm = JSON.parse(JSON.stringify(row));
      this.ruleForm.id = row.id;
    },
    updateUserup() {
      this.$refs.ruleForm.validate(async (valid) => {
        if (valid) {
          this.dialogVisible = false;
          //数据准备
          let data = JSON.parse(JSON.stringify(this.ruleForm));

          UpdateCustomer(data)
            .then(() => {
              this.$message({
                message: "修改成功！",
                type: "success",
                showClose: true,
              });
              this.$emit("call-get-userlists");
              // console.log(res.data);
            })
            .catch(() => {});
        } else {
          // 表单验证未通过，可给出提示或直接返回，无需关闭对话框
          this.$message({
            message: "请填写完整信息后再提交",
            type: "warning",
            duration: 1500,
          });
          return false;
        }
      });
    },
  },
};
</script>
<style lang="scss" scoped></style>
