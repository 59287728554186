import axios from "axios";

import {
  getStore,
  clearStore,
  serialize,
  clearLogonStore,
  setStore,
} from "@/utils/util";
import router from "@/router/index";
import { Message, MessageBox } from "element-ui";
import qs from "qs";
import NProgress from "nprogress";
import "nprogress/nprogress.css"; //这个样式必须引入

axios.defaults.timeout = 30000;
// 返回其他状态吗
axios.defaults.validateStatus = function (status) {
  return status >= 200 && status <= 500; // 默认的
};
// 跨域请求，允许保存cookie
axios.defaults.withCredentials = true;

// HTTPrequest拦截
axios.interceptors.request.use(
  (config) => {
    // 时间判断  如果用户 超时4小时 就拒绝请求
    // if (!config.headers.isNotNprogress) {
    //   NProgress.start();
    // }
    let time = getStore({ name: "lastRequestTime" });
    let newTime = parseInt(new Date().getTime() / 1000);
    if (!time) {
      setStore({ name: "lastRequestTime", content: newTime });
    } else {
      if (newTime - Number(time) > 7200 * 2) {
        clearLogonStore();
        router.push({ name: "login" });
        return Promise.reject(new Error("error"));
      } else {
        setStore({ name: "lastRequestTime", content: newTime });
      }
    }
    // 时间判断  如果用户 超时4小时 就拒绝请求
    const isToken = (config.headers || {}).isToken === false;
    const token = getStore({ name: "token" });
    if (token && !isToken) {
      config.headers["token"] = token; // token
    }

    // headers中配置serialize为true开启序列化
    if (config.method === "post" && config.headers.serialize) {
      config.data = serialize(config.data);
      delete config.data.serialize;
    }

    if (config.method === "get") {
      config.paramsSerializer = function (params) {
        return qs.stringify(params, { arrayFormat: "repeat" });
      };
    }

    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// HTTPresponse拦截
axios.interceptors.response.use(
  (res) => {
    NProgress.done();

    const status = Number(res.status) || 200;
    const message = res.data.msg || "服务器内部错误";
    // 后台定义 424 针对令牌过去的特殊响应码
    if (status === 424) {
      MessageBox.confirm("令牌状态已过期，请点击重新登录", "系统提示", {
        confirmButtonText: "重新登录",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          // 刷新登录页面，避免多次弹框
          clearStore();
          router.push({ path: "/login" });
          window.location.reload();
        })
        .catch(() => {
          //
        });
      return;
    }
    const dispositionHeader =
      res.headers["content-disposition"] || res.headers["Content-Disposition"];
    //blob文件下载
    if (dispositionHeader) {
      return res;
    }

    // 针对密文返回解密
    if (res.data.encryption) {
      // console.log(aesDecrypt(res.data.encryption, website.encPassword))
      // const originData = JSON.parse(
      //   aesDecrypt(res.data.encryption, website.encPassword)
      // );
      // res.data = originData;
      return res;
    }

    if (status !== 200 || res.data.code === 1 || !res.data) {
      if (res.data.msg !== "账号不是管理端账号，请确认后重试") {
        Message({
          message: message,
          type: "error",
        });
      }
      // console.log(res)
      return Promise.reject(res);
    }

    return res.data;
  },
  (error) => {
    // 处理 503 网络异常
    let message = error.response.data.msg || "服务器开了点小差,请稍后再试!";
    Message({
      message: message,
      type: "error",
    });
    // NProgress.done();
    return Promise.reject(error);
  }
);

export default axios;
