import request from "@/request/axios";

// 获取全部价格列表
export const getPrices = ({ size, page }) => {
  return request({
    url: "/price-list/list",
    method: "get",
    params: {
      size,
      page,
    },
  });
};

// 获取当前用户的价格列表
export const getPrice = (userId) => {
  return request({
    url: `/price-list/list/${userId}`,
    method: "get",
  });
};

// 添加价格表
export const addPrice = (data) => {
  return request({
    url: "/price-list/add",
    method: "post",
    data,
  });
};

// 删除价格表
export const delPrice = (id) => {
  return request({
    url: `/price-list/delete/${id}`,
    method: "delete",
  });
};

// 更新价格表
export const updatePrice = (data) => {
  return request({
    url: "/user/login",
    method: "post",
    params: data,
  });
};
