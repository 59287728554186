import request from "@/request/axios";

// 获取客户分页信息
export const getCustomerlist = ({ size, current, keyword }) => {
  return request({
    url: "/school/customerList",
    method: "get",
    params: {
      size,
      current,
      keyword,
    },
  });
};

// 添加客户信息
export const addCustomer = (data) => {
  return request({
    url: "/school/addCustomer",
    method: "post",
    data,
  });
};

// 查询客户信息
export const SelectCustomer = () => {
  return request({
    url: "/auth/token/logout",
    method: "get",
  });
};

// 修改客户信息
export const UpdateCustomer = (data) => {
  return request({
    url: "/school/updateCustomer",
    method: "put",
    data,
  });
};

// 添加用户关联学校
export const userCustomer = (data) => {
  return request({
    url: "/user/userLinkSchool",
    method: "post",
    params: data,
  });
};
