export const roleTypeGroup = [
  {
    label: "超级管理员",
    value: 1,
  },
  {
    label: "学校管理员",
    value: 2,
  },
  {
    label: "文印室 ",
    value: 3,
  },
];
// 学段
export const levelGroup = [
  {
    label: "小学",
    value: 1,
  },
  {
    label: "初中",
    value: 2,
  },
  {
    label: "高中",
    value: 3,
  },
  {
    label: "大学",
    value: 4,
  },
];
