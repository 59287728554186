import request from "@/request/axios";

//报表管理

// 删除报表数据
export const delscipt = (id) => {
  return request({
    url: `/report/delete/${id}`,
    method: "delete",
  });
};

//1
export const export1A = (params) => {
  return request({
    url: "/report/export1A",
    method: "get",
    params: params,
    responseType: "blob", // 添加此行来指定响应类型为Blob
  });
};

//2
export const export2A = (params) => {
  return request({
    url: "/report/export2A",
    method: "get",
    params: params,
    responseType: "blob", // 添加此行来指定响应类型为Blob
  });
};
//3
export const export3A = (params) => {
  return request({
    url: "/report/export3A",
    method: "get",
    params: params,
    responseType: "blob", // 添加此行来指定响应类型为Blob
  });
};

//4
export const export4A = (params) => {
  return request({
    url: "/report/export4A",
    method: "get",
    params: params,
    responseType: "blob", // 添加此行来指定响应类型为Blob
  });
};
