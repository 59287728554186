<template>
  <div class="editForm">
    <el-dialog
      title="密码验证"
      :visible.sync="dialogVisible"
      width="400px"
      :close-on-click-modal="false"
    >
      <el-form
        :model="ruleForm"
        :rules="rules"
        ref="ruleForm"
        label-width="90px"
      >
        <el-form-item label="密码" prop="password">
          <el-input
            v-model="ruleForm.password"
            placeholder="请输入密码"
          ></el-input>
        </el-form-item>
        <el-form-item label="确认密码" prop="repassword">
          <el-input v-model="repassword" placeholder="请确认密码"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="validatePasswords()">
          确 定
        </el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { checkPassword, deleteUser } from "@/request/api/login";
import { md5 } from "js-md5";

export default {
  name: "checkpassword",
  data() {
    return {
      dialogVisible: false,
      ruleForm: {
        password: "",
      },
      repassword: "",
      rules: {
        password: [
          { required: true, message: "请输入密码", trigger: "blur" },
          { min: 6, max: 18, message: "密码长度应为6到18位", trigger: "blur" },
        ],
      },
    };
  },
  methods: {
    init(row) {
      this.dialogVisible = true;
      this.id = row.id;
      // 初始化表单数据
      // this.$refs.ruleForm.resetFields();
    },

    validatePasswords() {
      if (this.ruleForm.password == this.repassword) {
        this.submit();
      } else {
        this.$message({
          message: "密码不一致请重新输入！",
          type: "error",
          showClose: true,
        });
      }
    },

    submit() {
      // 首先验证表单
      this.$refs.ruleForm.validate(async (valid) => {
        if (valid) {
          this.dialogVisible = false;

          // 复制表单数据并加密密码
          let data = JSON.parse(JSON.stringify(this.ruleForm));
          data.password = md5(data.password);

          try {
            // 验证密码
            await checkPassword(data);
            // this.$message({
            //   message: "验证成功！",
            //   type: "success",
            //   showClose: true,
            // });

            // 提示用户是否继续删除操作
            const confirmed = await this.$confirm(
              "此操作将永久删除该用户, 是否继续?",
              "提示",
              {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning",
              }
            );

            // 如果用户确认删除
            if (confirmed) {
              try {
                await deleteUser({ id: this.id });
                this.$message.success("删除成功！");
                this.$emit("call-get-userlists");
              } catch (error) {
                // console.error("Error deleting user:", error);
                // this.$message.error("删除过程中发生错误，请重试。");
              }
            }
          } catch (error) {
            // 密码验证失败
            this.$message.error("密码验证失败，请重新输入。");
          }
        } else {
          // 表单验证未通过
          return false;
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped></style>
