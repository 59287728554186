<template>
  <div class="user">
    <div class="filter-box">
      <span>学校:</span>
      <el-select
        filterable
        v-model="search.userId"
        placeholder="请选择学校"
        @change="handleSchoolChange(), mays()"
        @keyup.native="handleKeyup"
      >
        <el-option
          v-for="school in schools"
          :key="school.id"
          :label="school.schoolName"
          :value="school.userId"
        >
        </el-option>
      </el-select>

      <span>年级:</span>
      <el-select
        filterable
        title="请先选择学校，才能选择年级"
        v-model="search.gradeId"
        placeholder="请选择年级"
        @change="handleGradeChange()"
        @keyup.native="handleKeyup"
        :disabled="isSelectDisabled"
      >
        <el-option
          v-for="grade in grades"
          :key="grade.id"
          :label="grade.name"
          :value="grade.id"
        >
        </el-option>
      </el-select>

      <span class="time">起始时间：</span>
      <el-date-picker
        :clearable="false"
        v-model="time"
        type="daterange"
        range-separator="至"
        start-placeholder="开始日期"
        end-placeholder="结束日期"
        @change="changetime"
        class="m1"
      >
      </el-date-picker>
    </div>

    <div class="filter-box">
      <span>纸型:</span>
      <el-select
        v-model="search.paperType"
        filterable
        clearable
        placeholr="请选择纸型"
        @keyup.native="handleKeyup"
      >
        <el-option
          v-for="item in options"
          :key="item.id"
          :label="item.paperType"
          :value="item.paperType"
        ></el-option>
      </el-select>
      <span>印刷方式:</span>
      <el-select
        v-model="search.project"
        filterable
        clearable
        placeholder="请选择印刷方式"
        @keyup.native="handleKeyup"
      >
        <el-option
          v-for="method in printingMethods"
          :key="method.id"
          :label="method.printingMethod"
          :value="method.printingMethod"
        ></el-option>
      </el-select>
      <span>内容:</span>
      <el-input
        v-model="search.content"
        placeholder="请输入内容"
        @input="getNewList()"
      ></el-input>

      <el-button
        type="primary"
        icon="el-icon-search"
        :disabled="!isQueryEnabled"
        @click="getNew()"
        >查询</el-button
      >
      <el-button
        v-loading="loadingCalculate"
        element-loading-text="正在核算中，请耐心等待！"
        type="primary"
        @click="disg()"
        v-if="rolety <= 2"
        :disabled="!isQueryEnabled"
        >核算</el-button
      >

      <el-button
        type="primary"
        icon="el-icon-circle-plus-outline"
        @click="up()"
        :disabled="isSelectDisabled1"
        >添加业务</el-button
      >
      <el-button
        type="primary"
        icon="el-icon-download"
        :disabled="!isQueryEnabled"
        @click="down()"
        >导出</el-button
      >
    </div>

    <!-- 核算的价格表选择 -->
    <el-dialog
      title="选择价格表"
      :visible.sync="visible"
      element-loading-text="正在核算中，请耐心等待！"
      width="400px"
      v-loading="loading2"
    >
      <el-form>
        <el-form-item label="价格表：" label-width="80px">
          <el-select
            v-model="selectedPriceList"
            placeholder="请选择价格表"
            clearable
            :disabled="isSelectDisabled"
            title="请先选择公司，才能选择价格表"
          >
            <el-option
              v-for="priceList in priceLists"
              :key="priceList.id"
              :label="priceList.name"
              :value="priceList.id"
            ></el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="visible = false">取 消</el-button>
        <el-button type="primary" @click="calculate()">确 定</el-button>
      </span>
    </el-dialog>

    <el-table
      v-loading="loading"
      element-loading-text="拼命加载中"
      :data="tableData"
      height="580"
      style="width: 100%"
      stripe
      border
      ref="tableRef"
    >
      <el-table-column prop="userName" label="客户名称" align="center">
      </el-table-column>

      <el-table-column
        prop="formattedCreateTime"
        label="时间"
        align="center"
        width="160"
      >
      </el-table-column>

      <el-table-column prop="gradeName" label="年级/部门" align="center">
      </el-table-column>

      <el-table-column prop="classList" label="班级/科室" align="center">
      </el-table-column>

      <el-table-column prop="content" label="内容" align="center">
      </el-table-column>

      <el-table-column prop="paperType" label="纸张类型" align="center">
      </el-table-column>

      <el-table-column prop="singleDoubleSided" label="单/双面" align="center">
      </el-table-column>

      <el-table-column prop="project" label="印刷方式" align="center">
      </el-table-column>

      <el-table-column prop="useCount" label="份数" align="center">
      </el-table-column>

      <el-table-column prop="quantity" label="用量" align="center">
      </el-table-column>

      <el-table-column prop="isExtraPrint" label="是否加版" align="center">
      </el-table-column>
      <el-table-column prop="extraUnitPrice" label="加版单价" align="center">
      </el-table-column>
      <el-table-column prop="unitPrice" label="单价" align="center">
      </el-table-column>
      <el-table-column prop="totalPrice" label="总金额" align="center">
      </el-table-column>

      <el-table-column prop="address" label="操作" align="center" width="160">
        <template slot-scope="{ row }">
          <el-button type="text" @click="ups(row)">修改</el-button>
          <el-button type="text" class="btn-err" @click="delForm(row)"
            >删除</el-button
          >
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      hide-on-single-page
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page="search.current"
      :page-sizes="[16, 40, 80, 100, 200]"
      :page-size="search.size"
      layout="total, sizes, prev, pager, next, jumper"
      :total="total"
    >
    </el-pagination>

    <addBusinessup
      ref="addBusinessup"
      @call-get-userlists="getNewList"
    ></addBusinessup>
  </div>
</template>

<script>
import {
  Hcalculate,
  delBusiness,
  getBusinesslist,
  getClassList,
  getGradelist,
  getSchoollist,
  getexportBusiness,
  getlistMethod,
  getpaper,
} from "@/request/api/business";
import addBusinessup from "./components/addBusinessup.vue";
import { getPrice } from "@/request/api/price";
import { getStore } from "@/utils/util";
import { getstuta } from "@/request/api/login";
import { downloadBlob } from "@/utils/util";
export default {
  name: "business",
  components: { addBusinessup },
  data() {
    return {
      taskId: "",
      dias: true, // 初始状态设为 true
      isQueryEnabled: false,
      loadingCalculate: false,
      printingMethods: [],
      options: [],
      rolety: "",
      time: "",
      priceLists: [],
      visible: false,
      isSelectDisabled: true,
      isSelectDisabled1: true,
      loading: false,
      loading2: false,
      search: {
        current: 1, // 当前页码
        size: 16, // 每页显示数量
        startTime: "",
        endTime: "",
        userId: "",
        gradeId: "",
        paperType: "",
        project: "",
        content: "",
      },

      selectedPriceList: "",
      priceListId: "",
      grades: [],
      schools: [],

      pages: "",
      total: 0,
      tableData: [], // 这里需要初始化数据，例如通过API请求
    };
  },
  watch: {
    "search.gradeId": {
      handler(newVal, oldVal) {
        if (newVal !== oldVal && this.search.userId !== "") {
          this.checkAllChanged();
        }
      },
      immediate: false,
      deep: true,
    },
    "search.userId": {
      handler(newVal, oldVal) {
        if (newVal !== oldVal && this.search.gradeId !== "") {
          this.checkAllChanged();
        }
      },
      immediate: false,
      deep: true,
    },
    time: {
      handler(newVal, oldVal) {
        if (newVal !== oldVal) {
          this.checkAllChanged();
        }
      },
      immediate: false,
      deep: true,
    },
  },
  created() {
    this.fetchSchools();
    this.fetchpapertype();
    this.fetchtype();
    //查询按钮的防抖，3秒一次
    this.debouncedGetNewList = this.debounce(this.getNewList.bind(this), 300); // 调整300为合适的延迟时间
  },
  mounted() {},
  beforeDestroy() {
    // 组件销毁前清除定时器
    clearInterval(this.timer);
  },

  methods: {
    async fetchData() {
      try {
        const response = await getstuta({ taskId: this.taskId });

        if (response.msg == 1) {
          this.$alert("核算已经完成", "提示", {
            confirmButtonText: "确定",
            callback: () => {
              this.$message({
                type: "success",
                message: "核算成功！",
              });
              this.getNewList();
            },
          });
          this.isQueryEnabled = true;
          this.loadingCalculate = false;
          clearInterval(this.timer);
        } else if (response.msg == 2) {
          this.$alert(response.data, "提示", {
            confirmButtonText: "确定",
            callback: () => {
              this.$message({
                type: "success",
                message: "取消成功",
              });
            },
          });
          this.isQueryEnabled = true;
          this.loadingCalculate = false;
          clearInterval(this.timer);
        }
      } catch (error) {
        //
      }
    },
    checkAllChanged() {
      if (
        this.search.gradeId !== "" &&
        this.search.userId !== "" &&
        this.time !== ""
      ) {
        this.isQueryEnabled = true;
      }
    },
    handleGradeChange() {
      this.isSelectDisabled1 = false;
      this.tableData = [];
    },
    async down() {
      const [startTime, endTime] = this.time;

      // 如果有值，才进行格式化（取决于后端接口是否需要特定格式）
      this.search.startTime = startTime ? this.formatDate(startTime) : "";
      this.search.endTime = endTime ? this.formatDate(endTime) : "";

      // 确保只有当 startTime 或 endTime 存在且有效时才传递给后端
      const params = {
        ...this.search,
        ...(startTime && { startTime: this.search.startTime }),
        ...(endTime && { endTime: this.search.endTime }),
      };
      const res = await getexportBusiness(params);
      downloadBlob(res);
    },
    async fetchpapertype() {
      try {
        // 使用axios或其他HTTP客户端库来请求数据
        const res = await getpaper();
        // console.log(res.data);
        this.options = res.data;
      } catch (error) {
        // console.error("Failed to fetch grades", error);
      }
    },

    async fetchtype() {
      try {
        // 使用axios或其他HTTP客户端库来请求数据
        const res = await getlistMethod();
        // console.log(res.data);
        this.printingMethods = res.data;
      } catch (error) {
        // console.error("Failed to fetch grades", error);
      }
    },
    //打开添加业务的弹窗
    up() {
      this.$refs.addBusinessup.init(this.search); // 假设addBusiness组件需要一个空的初始化
    },

    ups(row) {
      this.$refs.addBusinessup.inits(row); // 假设addBusiness组件需要一个空的初始化
    },

    //判断是否选择了学校
    mays() {
      if (this.tableData.userId !== "") {
        this.isSelectDisabled = false;
      }
    },

    // async fetchBusinessinfo() {
    //   const res = await getBusinessinfo(this.userId, {
    //     page: this.search.page,
    //     size: this.search.size,
    //   });
    //   console.log(res.data);
    // },
    changetime() {
      this.tableData = [];
    },

    handleSchoolChange() {
      this.fetchGrades();
      this.fetchPriceList();
      this.search.gradeId = "";
      this.tableData = [];
    },
    //获取当前用户的价格表
    async fetchPriceList() {
      try {
        // 使用axios或其他HTTP客户端库来请求数据
        // console.log(this.userId);
        const res = await getPrice(this.search.userId);
        // console.log(res);

        this.priceLists = res.data.records; // 假设后端返回的数据直接赋值给schools
      } catch (error) {
        // console.error("Failed to fetch schools", error);
      }
    },
    // 获取年级下拉框
    async fetchGrades() {
      try {
        // 使用axios或其他HTTP客户端库来请求数据
        const res = await getGradelist(this.search.userId);
        // this.grades = res.data; // 假设后端返回的数据直接赋值给grades
        this.grades = [...res.data, { id: 0, name: "部门" }];

        const gradePromises = res.data.map(async (grade) => {
          const classRes = await getClassList({
            userId: this.search.userId,
            gradeId: grade.id,
          });
          return {
            gradeName: grade.name,
            gradeId: grade.id,
            classes: classRes.data.map((clazz) => ({
              className: clazz.name,
              classId: clazz.id,
            })),
          };
        });

        // 确保在引用前等待 gradePromises 完成
        const formattedGrades = await Promise.all(gradePromises);

        // 现在可以安全地使用 formattedGrades
        // formattedGrades.forEach((grade, index) => {
        //   console.log(
        //     `年级 ${index + 1} - Grade ID: ${
        //       grade.gradeId
        //     }, 班级列表: [${grade.classes.map((c) => c.classId).join(", ")}]`
        //   );
        // });

        this.formattedTableData = formattedGrades.map((grade) => ({
          gradeName: grade.gradeName,
          classes: grade.classes, // 直接将班级数组作为子项
        }));
      } catch (error) {
        // console.error("Failed to fetch grades", error);
      }
    },

    // 获取学校下拉框
    async fetchSchools() {
      try {
        const userInfo = getStore({ name: "userinfo" });
        // 使用axios或其他HTTP客户端库来请求数据
        const params = {
          userId: userInfo.id,
          roleType: userInfo.roleType - 2,
        };
        this.rolety = userInfo.roleType;
        // console.log(this.rolety);
        const res = await getSchoollist(params);
        // console.log(res);
        this.schools = res.data.records; // 假设后端返回的数据直接赋值给schools
      } catch (error) {
        // console.error("Failed to fetch schools", error);
      }
    },

    //业务删除功能
    async delForm(row) {
      // console.log(row.id);
      const confirmed = await this.$confirm(
        "此操作将永久删除该用户, 是否继续?",
        "提示",
        {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        }
      );

      // 如果确认删除
      if (confirmed) {
        delBusiness(row.id)
          .then(() => {
            this.$message.success("删除成功！");
            // 从表格数据中移除已删除的项
            this.tableData = this.tableData.filter(
              (item) => item.id !== row.id
            );
            // 重新加载数据以获取最新的列表
            this.getNewList();
          })
          .catch(() => {
            // 错误消息
            // this.$message.error("删除失败，请重试！");
          });
      }
    },

    formatDate(date) {
      if (date) {
        let d = new Date(date);
        return `${d.getFullYear()}-${("0" + (d.getMonth() + 1)).slice(-2)}-${(
          "0" + d.getDate()
        ).slice(-2)}`;
      }
      return ""; // 如果date为空，则返回空字符串
    },
    //防抖函数
    debounce(func, wait) {
      let timeout;
      return function (...args) {
        const context = this;
        clearTimeout(timeout);
        timeout = setTimeout(() => func.apply(context, args), wait);
      };
    },

    getNew() {
      this.search.current = 1;
      this.debouncedGetNewList();
    },

    //获取业务数据
    async getNewList(data) {
      this.loading = true;
      try {
        if (data) {
          //  console.log(data);
          this.time = data.time;
          this.search.gradeId = data.gradeId;
          this.search.userId = data.userId;
        }

        const [startTime, endTime] = this.time;

        // 如果有值，才进行格式化（取决于后端接口是否需要特定格式）
        this.search.startTime = startTime ? this.formatDate(startTime) : "";
        this.search.endTime = endTime ? this.formatDate(endTime) : "";

        // 确保只有当 startTime 或 endTime 存在且有效时才传递给后端
        const params = {
          ...this.search,
          ...(startTime && { startTime: this.search.startTime }),
          ...(endTime && { endTime: this.search.endTime }),
        };

        const res = await getBusinesslist(params);
        res.data.records.forEach((item) => {
          if (item.date) {
            const date = new Date(item.date);
            item.formattedCreateTime = `${date.getFullYear()}年${(
              "0" +
              (date.getMonth() + 1)
            ).slice(-2)}月${("0" + date.getDate()).slice(-2)}日`;
          }
        });
        this.tableData = res.data.records;
        this.total = res.data.total;
        this.pages = Math.ceil(this.total / this.search.size);
      } catch (error) {
        // console.error("Error fetching business list:", error);
      } finally {
        this.loading = false;
      }
      // this.$message({
      //   message: "获取成功！",
      //   type: "success",
      //   showClose: true,
      // });
    },

    disg() {
      this.visible = true;
    },

    //核算功能实现
    async calculate() {
      // this.timer = setInterval(this.fetchData, 3000);
      this.loadingCalculate = true;
      try {
        const [startTime, endTime] = this.time;

        // 如果有值，才进行格式化（取决于后端接口是否需要特定格式）
        this.search.startTime = startTime ? this.formatDate(startTime) : "";
        this.search.endTime = endTime ? this.formatDate(endTime) : "";
        const params = {
          userId: this.search.userId,
          priceListId: this.selectedPriceList,
          gradeId: this.search.gradeId,
          startTime: this.search.startTime,
          endTime: this.search.endTime,
        };
        // await new Promise((resolve) => setTimeout(resolve, 1000));
        this.visible = false;
        this.isQueryEnabled = false;
        const res = await Hcalculate(params);
        this.taskId = res.data;

        this.timer = setInterval(this.fetchData, 3000);
        // this.$message.success("核算成功");
        // clearInterval(this.timer);
      } catch {
        //错误提示
      }
    },
    handleSizeChange(val) {
      const table = this.$refs.tableRef;
      const divData = table.bodyWrapper;
      divData.scrollTop = 0;
      this.search.size = val;
      this.getNewList(); // 页大小改变时重新加载数据
    },

    handleCurrentChange(val) {
      const table = this.$refs.tableRef;
      const divData = table.bodyWrapper;
      divData.scrollTop = 0;
      this.search.current = val;
      this.getNewList(); // 页码改变时重新加载数据
      // this.search.current = 1;
    },
  },
};
</script>
<style lang="scss" scoped>
span {
  margin-right: 10px;
}
.custom-date-picker {
  width: 150px; /* 自定义宽度 */
}
.hes {
  margin-right: 10px;
  margin-left: 5px;
}
.m1 {
  margin-right: 15px;
}
</style>
