<template>
  <div class="editFrom">
    <el-drawer
      :wrapperClosable="false"
      :title="this.ruleForm.id ? '修改业务信息' : '添加业务信息'"
      :visible.sync="dialogVisible"
      width="460"
      :before-close="dialog"
      :close-on-click-modal="false"
    >
      <el-form
        :model="ruleForm"
        :rules="rules"
        ref="ruleForm"
        id="ruleForm"
        label-width="90px"
        filterable
        :key="viewKey"
      >
        <el-form-item label="学校" prop="userId">
          <el-select
            v-model="ruleForm.userId"
            class="focusSelect"
            filterable
            placeholder="请选择学校"
            @change="handleSchoolChange(), mays()"
          >
            <el-option
              v-for="school in schools"
              :key="school.id"
              :label="school.schoolName"
              :value="school.userId"
            >
            </el-option>
          </el-select>
        </el-form-item>

        <el-form-item label="年级" prop="gradeId">
          <el-select
            title="请先选择学校，才能选择年级"
            v-model="ruleForm.gradeId"
            class="focusSelect"
            placeholder="请选择年级"
            filterable
            @change="handleGradeChange()"
          >
            <!-- :disabled="isSelectDisabled" -->
            <el-option
              v-for="grade in grades"
              :key="grade.id"
              :label="grade.name"
              :value="grade.id"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="日期" prop="date">
          <el-date-picker
            v-model="ruleForm.date"
            type="date"
            class="focusSelect"
            placeholder="选择日期"
            @change="msad"
            @keyup.enter="nextInput"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item :label="classOrDeptLabel" prop="className">
          <el-row>
            <el-col :span="16">
              <el-input
                class="focusSelect"
                v-model="ruleForm.className"
                placeholder="例如:1.2.3"
              />
            </el-col>
            <el-col :span="8">
              <el-button
                v-if="classOrDeptLabel == '班级'"
                v-model="checked"
                @click="wholeGrade"
                :disabled="visiblecheck"
              >
                全年级
              </el-button>
            </el-col>
          </el-row>
        </el-form-item>

        <el-form-item label="内容" prop="content">
          <el-input
            v-model="ruleForm.content"
            class="focusSelect"
            placeholder="请填写备注"
          ></el-input>
        </el-form-item>

        <el-form-item
          label="科目"
          prop="subjectName"
          v-if="this.classOrDeptLabel == '班级'"
        >
          <el-select
            v-model="ruleForm.subjectName"
            clearable
            filterable
            class="focusSelect"
            placeholder="请选择科目"
          >
            <el-option
              v-for="item in subjectS"
              :key="item.subjectId"
              :label="item.subjectName"
              :value="item.subjectName"
            />
          </el-select>
        </el-form-item>

        <el-form-item label="纸型" prop="paperType">
          <el-select
            v-model="ruleForm.paperType"
            filterable
            class="focusSelect"
            clearable
            placeholr="请选择纸型"
            style="width: 100%"
          >
            <el-option
              v-for="item in options"
              :key="item.id"
              :label="item.paperType"
              :value="item.paperType"
            ></el-option>
          </el-select>
        </el-form-item>

        <el-form-item label="单/双面" prop="singleDoubleSided">
          <el-select
            v-model="ruleForm.singleDoubleSided"
            filterable
            clearable
            class="focusSelect"
            placeholder="请选择单双面"
            style="width: 100%"
          >
            <el-option
              v-for="item in side"
              :key="item.value"
              :label="item.label"
              :value="item.label"
            ></el-option>
          </el-select>
        </el-form-item>

        <el-form-item
          label="印量"
          prop="combinedInput"
          class="inline-input-group"
        >
          <el-input
            v-model="ruleForm.useCount"
            placeholder="份数"
            class="inline-input focusSelect"
          ></el-input>
          <span style="margin: 0 5px">✖</span>
          <el-input
            v-model="ruleForm.quantity"
            placeholder="用量"
            class="inline-input focusSelect"
          ></el-input>
        </el-form-item>

        <el-form-item label="印刷方式" prop="project">
          <el-select
            v-model="ruleForm.project"
            filterable
            clearable
            placeholder="请选择印刷方式"
            class="focusSelect"
            style="width: 100%"
          >
            <el-option
              v-for="method in printingMethods"
              :key="method.id"
              :label="method.printingMethod"
              :value="method.printingMethod"
            ></el-option>
          </el-select>
        </el-form-item>

        <el-form-item label="是否加版" prop="isExtraPrint">
          <el-select
            v-model="ruleForm.isExtraPrint"
            filterable
            class="focusSelect"
          >
            <el-option label="是" value="是"></el-option>
            <el-option label="否" value="否"></el-option>
          </el-select>
        </el-form-item>

        <el-form-item>
          <el-button @click="dialog()">退出</el-button>
          <el-button
            v-loading="mloading"
            type="primary"
            @click="validatesubmit()"
          >
            提交
          </el-button>
        </el-form-item>
      </el-form>
    </el-drawer>
  </div>
</template>
<script>
import {
  getSchoollist,
  getGradelist,
  getlistMethod,
  getpaper,
  addBusiness,
  updateBusiness,
} from "@/request/api/business";
import { getclassByid } from "@/request/api/clazz";
import { getSubjectMiddle } from "@/request/api/subject";
import { getStore } from "@/utils/util";

export default {
  name: "addBusinessup",
  data() {
    return {
      visiblecheck: false,
      mloading: false,
      checked: false,
      processedNames: "",
      //判断班级是否可用
      classOrDeptLabel: "班级", // 初始为班级
      isSelectDisabled: true,
      isSelectDisabled1: false,
      // isSelectDisabled1: true,
      options: [],
      dialogVisible: false,
      schoolList: [],
      side: [
        // 定义单双面
        { value: "1", label: "单面" },
        { value: "2", label: "双面" },
      ],
      ruleForm: {
        content: "",
        singleDoubleSided: "",
        useCount: "",
        quantity: "",
        className: "",
        date: "",
        userId: "",
        gradeId: "",
        project: "",
        subjectName: "",
        paperType: "",
        isExtraPrint: "",
      },
      focusIndex: null,
      viewKey: 1,
      time: [],
      // ruleForm: [],
      subjectS: [],
      grades: [],
      schools: [],
      printingMethods: [],

      clas: [],
      rules: {
        name: [
          { required: true, message: "请输入客户名称", trigger: "blur" },
          {
            min: 2,
            max: 30,
            message: "长度在 2 到 30 个字符",
            trigger: "blur",
          },
        ],
        combinedInput: [
          {
            validator: (rule, value, callback) => {
              const useCount = this.ruleForm.useCount;
              const quantity = this.ruleForm.quantity;

              // 正数的正则表达式，匹配大于0的数字，包括整数和小数
              const positiveNumberRegex = /^[+]?\d*\.?\d+$/;

              // 验证逻辑：确保两个字段都是正数
              if (
                !positiveNumberRegex.test(useCount) ||
                !positiveNumberRegex.test(quantity)
              ) {
                callback(new Error("请输入正数作为份数和用量"));
              } else {
                callback();
              }
            },
            trigger: "blur", // 触发验证的时机，这里设置为失去焦点时
          },
        ],
        className: [
          { required: true, message: "班级/科室不能为空", trigger: "blur" },
          {
            validator: this.validmod,
            trigger: ["blur", "change"],
          },
        ],
        isExtraPrint: [{ required: true, message: "请选择", trigger: "blur" }],
      },
    };
  },
  created() {
    // 假设这是你从后端获取数据的方法
    this.fetchSchools();
    this.fetchtype();
    this.fetchpapertype();
    this.fetchSubjects;
    //防抖
    this.debouncedUpdateBusinessup = this.debounce(
      this.updateBusinessup.bind(this),
      300
    );
    this.debouncedAddBusinessup = this.debounce(
      this.addBusinessup.bind(this),
      300
    );
  },
  mounted() {
    // 在组件挂载后添加键盘事件监听器
    document.addEventListener("keydown", this.handleKeyDown);
  },
  beforeDestroy() {
    // 在组件销毁前移除监听器
    document.removeEventListener("keydown", this.handleKeyDown);
  },
  methods: {
    handleKeyDown(event) {
      // 根据按键执行相应逻辑
      if (event.keyCode === 13) {
        this.nextInput(event);
      }
    },
    nextInput() {
      const ruleForm = document.getElementById("ruleForm");
      const inputs = ruleForm.getElementsByClassName("el-input__inner");
      const focusedElement = document.activeElement;

      if (focusedElement.tagName == "INPUT") {
        // 遍历每一个input 看是否都有值
        const result = [...inputs].every((item) => item.value);
        if (!result) {
          // 找出那个没有值的
          const emptyInput = [...inputs].find((item) =>
            [null, undefined, ""].includes(item.value)
          );
          emptyInput.focus();
        } else {
          const elIndex = [...inputs].findIndex((item) => {
            return item.value == focusedElement.value;
          });
          if (
            ![null, undefined, ""].includes(elIndex) &&
            elIndex < inputs.length - 1
          ) {
            inputs[elIndex + 1].focus();
          }
        }
      }
    },
    //获取科目信息
    async fetchsubjectback() {
      this.loading = true;
      try {
        const res = await getSubjectMiddle(this.ruleForm.userId);
        this.subjectS = res.data;
        // console.log(res);
      } catch (error) {
        // 可以在这里处理错误，比如提示用户
      } finally {
        this.loading = false;
      }
    },

    //选择全部班级/科室
    async wholeGrade() {
      // 只有当checkbox被勾选时才执行填充数据的操作
      if (this.ruleForm.gradeId == "") {
        this.$message.warning("请先选择年级！");
        return;
      }

      try {
        const res = await getclassByid(this.ruleForm.gradeId);
        if (res.data == "") {
          this.$message.warning("该年级下没有班级！");
          return;
        }

        const names = res.data.map((item) => item.name.replace("班", ""));
        this.processedNames = names.join(".");
        this.ruleForm.className = this.processedNames;
      } catch (error) {
        // 处理错误
      }
    },

    //判断是班级还是科室，选择不同的参数校验方法
    //当为班级时，参数校验
    validmod(rule, value, callback) {
      if (this.classOrDeptLabel == "班级") {
        // console.log("班级");
        this.validateCommaSeparatedIntegers(rule, value, callback);
      } else {
        // console.log("科室");
        this.validateCommaSeparatedInteger(rule, value, callback);
      }
    },
    //当为文印室时，参数校验
    validateCommaSeparatedInteger(rule, value, callback) {
      // 检查值是否为空
      if (!value) {
        callback(new Error("科室不能为空！"));
      } else {
        // 如果有其他后续验证需求，可以在这里添加
        callback();
      }
    },

    //当为年级时，参数校验
    validateCommaSeparatedIntegers(rule, value, callback) {
      // 正则表达式，匹配逗号分隔的正整数序列
      const pattern = /^\d+(\.\d+)*$/;

      if (pattern.test(value)) {
        callback(); // 验证通过
      } else {
        callback(new Error('请输入形如 "1.2.3" 的逗号分隔的正整数序列'));
      }
    },
    validatePositiveInteger(rule, value, callback) {
      // 正则表达式，匹配大于0的数字（包括正整数和正浮点数）
      const pattern = /^[1-9]\d*(\.\d+)?|0\.\d*[1-9]\d*$/;

      if (pattern.test(value)) {
        callback(); // 验证通过
      } else {
        callback(new Error("请输入大于0的数字"));
      }
    },

    //对班级进行参数校验
    validatesubmit() {
      this.$refs.ruleForm.validate(async (valid) => {
        if (valid) {
          // 验证通过，执行后续逻辑，如提交数据
          this.types();
          // console.log("验证通过，可以提交数据");
        } else {
          // 验证失败的处理
          this.$message.warning("请正确填写信息！");
          // console.log("验证未通过");
        }
      });
    },

    //点击关闭时，将所以的东西置为空
    dialog() {
      this.dialogVisible = false;
      // this.ruleForm = {
      //   content: "",
      //   singleDoubleSided: "",
      //   useCount: "",
      //   quantity: "",
      //   className: "",
      //   date: "",
      //   userId: "",
      //   gradeId: "",
      //   project: "",
      //   subjectName: "",
      //   classList: "",
      // };
    },

    //判断是否选择了学校
    mays() {
      if (this.ruleForm.userId !== "") {
        this.isSelectDisabled = false;
      }
    },

    //年级改变时，判断是年级还是科室
    handleGradeChange() {
      // this.fetchClass();
      if (this.ruleForm.gradeId == 0) {
        this.classOrDeptLabel = "科室";
        // this.visiblecheck = true;
        // this.fetchDepartment(); // 获取科室数据
        // this.classOrDeptOptions = []; // 清空现有选项，准备填充科室数据
      } else {
        this.classOrDeptLabel = "班级";
        // 如果有必要，重新获取班级数据
      }
      this.ruleForm.className = "";
      this.isSelectDisabled1 = false;
    },
    handleSchoolChange() {
      // console.log(this.ruleForm.school);
      this.fetchGrades();
      this.ruleForm.gradeId = "";
      this.ruleForm.className = "";
      this.fetchsubjectback();
    },

    // //获取科目类别
    // async fetchSubjects() {
    //   try {
    //     const res = await getSubject();
    //     this.subjectS = res.data;
    //     // console.log(res);
    //   } catch {
    //     //
    //   }
    // },

    async fetchpapertype() {
      try {
        // 使用axios或其他HTTP客户端库来请求数据
        const res = await getpaper();
        // console.log(res.data);
        this.options = res.data;
      } catch (error) {
        // console.error("Failed to fetch grades", error);
      }
    },

    //获取印刷类型
    async fetchtype() {
      try {
        // 使用axios或其他HTTP客户端库来请求数据
        const res = await getlistMethod();
        // console.log(res.data);
        this.printingMethods = res.data;
      } catch (error) {
        // console.error("Failed to fetch grades", error);
      }
    },

    //获取年级信息
    async fetchGrades() {
      try {
        // 使用axios或其他HTTP客户端库来请求数据
        const res = await getGradelist(this.ruleForm.userId);

        // console.log(res.data);
        // this.grades = res.data; // 假设后端返回的数据直接赋值给grades
        this.grades = [...res.data, { id: 0, name: "部门" }];
      } catch (error) {
        // console.error("Failed to fetch grades", error);
      }
    },

    //获取学校信息
    async fetchSchools() {
      try {
        const userInfo = getStore({ name: "userinfo" });
        // 使用axios或其他HTTP客户端库来请求数据
        const params = {
          userId: userInfo.id,
          roleType: userInfo.roleType - 2,
        };
        const res = await getSchoollist(params);
        // 使用axios或其他HTTP客户端库来请求数据

        // console.log(res);
        this.schools = res.data.records; // 假设后端返回的数据直接赋值给schools
      } catch (error) {
        // console.error("Failed to fetch schools", error);
      }
    },
    init(search) {
      Object.assign(this.$data.ruleForm, this.$options.data().ruleForm);
      this.dialogVisible = true;
      this.$nextTick(() => {
        this.viewKey++;
        this.ruleForm.userId = search.userId;

        this.handleSchoolChange();

        this.ruleForm.gradeId = search.gradeId;
        if (this.ruleForm.gradeId == 0) {
          this.classOrDeptLabel = "科室";
          // this.fetchDepartment(); // 获取科室数据
          // this.classOrDeptOptions = []; // 清空现有选项，准备填充科室数据
        } else {
          this.classOrDeptLabel = "班级";
          // 如果有必要，重新获取班级数据
        }
        this.fetchsubjectback();
        this.clas = [];
        this.grades = [];
        this.isSelectDisabled = true;
        this.isSelectDisabled1 = false;
      });
    },
    inits(row) {
      this.dialogVisible = true;
      this.ruleForm = JSON.parse(JSON.stringify(row));
      this.ruleForm.className = this.ruleForm.classList;
    },

    async updateBusinessup() {
      try {
        // console.log(this.ruleForm.userId);
        let data = JSON.parse(JSON.stringify(this.ruleForm));
        data.classList = data.className;
        data.date = this.formatDate(this.ruleForm.date);
        // console.log(data);
        // 使用axios或其他HTTP客户端库来请求数据
        await updateBusiness(data); // 更正函数名称
        this.dialogVisible = false;
        this.$message.success("修改成功！");
        this.$emit("call-get-userlists");

        // console.log(res.data);
      } catch (error) {
        // console.error("Failed to fetch printing methods", error); // 更正错误信息
      }
    },
    formatDate(date) {
      if (date) {
        let d = new Date(date);
        return `${d.getFullYear()}-${("0" + (d.getMonth() + 1)).slice(-2)}-${(
          "0" + d.getDate()
        ).slice(-2)}`;
      }
      return ""; // 如果date为空，则返回空字符串
    },
    //将时间范围前后扩大10天
    msad() {
      const selectedDate = new Date(this.ruleForm.date);
      const tenDaysBefore = new Date(selectedDate);
      tenDaysBefore.setDate(selectedDate.getDate() - 10);
      const tenDaysAfter = new Date(selectedDate);
      tenDaysAfter.setDate(selectedDate.getDate() + 10);

      this.timed = [
        tenDaysBefore.toISOString().substring(0, 10),
        tenDaysAfter.toISOString().substring(0, 10),
      ];
      // console.log(this.timed);
    },
    async addBusinessup() {
      this.mloading = true;
      try {
        this.msad();
        let data = JSON.parse(JSON.stringify(this.ruleForm));
        // console.log(data);
        data.date = this.formatDate(this.ruleForm.date);
        // console.log(data);
        // 使用axios或其他HTTP客户端库来请求数据

        // this.dialogVisible = false;
        await addBusiness(data); // 更正函数名称
        this.$message.success("添加成功！");

        let quns = {
          time: this.timed,
          userId: this.ruleForm.userId,
          gradeId: this.ruleForm.gradeId,
        };
        this.$emit("call-get-userlists", quns);
        // this.ruleForm = [];
        // console.log(res.data);
      } catch (error) {
        // console.error("Failed to fetch printing methods", error); // 更正错误信息
      } finally {
        this.mloading = false;
      }
    },

    types() {
      if (this.ruleForm.id) {
        this.debouncedUpdateBusinessup();
      } else {
        this.debouncedAddBusinessup();
      }
    },
    //防抖函数
    debounce(func, wait) {
      let timeout;
      return function (...args) {
        const context = this;
        clearTimeout(timeout);
        timeout = setTimeout(() => func.apply(context, args), wait);
      };
    },
  },
};
</script>
<style lang="scss" scoped>
.inline-input {
  width: 20%; /* 设置输入框宽度为容器的一半 */
  box-sizing: border-box; /* 包含边框和内填充在内，确保总宽度为50% */
  margin: 0 5px; /* 输入框之间的间距 */
}

.separator {
  font-size: 16px; /* 调整"✖"符号的大小 */
  line-height: 1; /* 确保垂直居中 */
  margin: 0 3px; /* 分隔符与输入框的间距，可调整 */
  pointer-events: none; /* 防止点击"✖"影响输入框 */
  user-select: none; /* 防止文本被选中 */
}
.el-form {
  padding-right: 18px;
}
</style>
