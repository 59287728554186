<template>
  <div class="editForm">
    <div>
      <el-form :model="ruleForm" ref="ruleForm" label-width="30px">
        <el-form-item>
          <div class="avatar-container">
            <img
              src="@/assets/common/avatar.png"
              alt="用户头像"
              class="avatar"
            />
          </div>
        </el-form-item>
      </el-form>

      <div>
        <el-card>
          <el-descriptions class="margin-top" :column="2" border>
            <el-descriptions-item>
              <template slot="label">
                <i class="el-icon-user"></i>
                用户名
              </template>
              {{ ruleForm.username }}
            </el-descriptions-item>
            <el-descriptions-item>
              <template slot="label">
                <i class="el-icon-s-custom"></i>
                学校
              </template>
              {{ ruleForm.schoolName }}
            </el-descriptions-item>

            <el-descriptions-item>
              <template slot="label">
                <i class="el-icon-mobile-phone"></i>
                手机号码
              </template>
              {{ ruleForm.phone }}
            </el-descriptions-item>
            <el-descriptions-item>
              <template slot="label">
                <i class="el-icon-location-outline"></i>
                城市
              </template>
              {{ ruleForm.address }}
            </el-descriptions-item>

            <el-descriptions-item>
              <template slot="label">
                <i class="el-icon-magic-stick"></i>
                个性签名
              </template>
              {{ ruleForm.notes }}
            </el-descriptions-item>
            <el-descriptions-item>
              <template slot="label">
                <i class="el-icon-date"></i>
                注册日期
              </template>
              {{ formatDate(new Date(ruleForm.createTime)) }}
            </el-descriptions-item>

            <el-descriptions-item>
              <el-button
                type="primary"
                icon="el-icon-edit"
                @click="editFrom()"
                class="m2"
                >修改信息</el-button
              >
              <el-button
                type="primary"
                icon="el-icon-lock"
                @click="editpassword()"
                >修改密码</el-button
              >
            </el-descriptions-item>
          </el-descriptions>
        </el-card>
      </div>
    </div>
    <h1 class="log-header">登录日志</h1>
    <div
      class="scrollable-log-container"
      v-loading="loadingLogs"
      element-loading-text="正在加载登录日志..."
    >
      <!-- <h1 class="log-header">登录日志</h1> -->
      <el-card shadow="never" style="margin-bottom: 15px">
        <el-collapse v-model="activeNames" accordion>
          <el-collapse-item
            v-for="(log, index) in logs"
            :key="index"
            :title="`登录时间: ${log.formattedCreateTime}`"
          >
            <p>操作: {{ log.title }}</p>
            <p>IP地址: {{ log.remoteAddr }}</p>
            <p>操作对象: {{ log.createBy }}</p>
          </el-collapse-item>
        </el-collapse>
      </el-card>
      <editImfomation
        ref="editImfomation"
        @call-get-userlists="getUserInfo"
      ></editImfomation>
    </div>
  </div>
</template>

<script>
import { getLoglists } from "@/request/api/log";
import { getUserByid } from "@/request/api/login";
import { getStore } from "@/utils/util";
import editImfomation from "@/views/account/components/editImfomation";

export default {
  name: "EditForm",
  components: { editImfomation },
  data() {
    return {
      loadingLogs: false, // 登录日志加载状态
      keyword: "用户登录",
      createBy: "",

      username: "用户名",

      activeNames: ["1"],
      logs: [],
      dialogVisible: false,
      ruleForm: {
        password: "",
        deptId: "",
        name: "",
        username: "",
        roleType: "", // 初始化为字符串，以匹配验证规则中的预期类型
        phone: "",
      },

      userId: "",
    };
  },
  created() {
    this.getUserInfo();
  },
  methods: {
    formatDate(date) {
      if (!date) return ""; // 如果date为空，则返回空字符串
      const year = date.getFullYear();
      const month = (date.getMonth() + 1).toString().padStart(2, "0");
      const day = date.getDate().toString().padStart(2, "0");
      return `${year}年${month}月${day}日`;
    },
    editpassword() {
      this.$refs.editImfomation.inits();
    },
    editFrom() {
      // 初始化表单数据
      const mm = this.ruleForm;
      this.$refs.editImfomation.init(mm);
    },

    async fetchLogs() {
      this.loadingLogs = true; // 开始加载

      try {
        // 调用API获取日志
        let params = { userId: this.userId };
        // console.log(this.search);
        const res = await getLoglists(params);
        res.data.records.forEach((item) => {
          if (item.createTime) {
            item.formattedCreateTime = new Date(
              item.createTime
            ).toLocaleString();
          }
        });
        this.logs = res.data.records;

        // 更新数据
        // console.log(res);
      } catch (error) {
        // console.error("获取日志列表失败:", error);
      } finally {
        this.loadingLogs = false; // 完成加载
      }
    },
    getUserInfo() {
      const userInfo = getStore({ name: "userinfo" });
      if (userInfo) {
        // 这里可以使用userInfo数据了，比如赋值给组件的data属
        // console.log(userInfo);
        this.createBy = userInfo.username;
        this.userId = userInfo.id;
        // console.log(userInfo);
        // console.log(this.userId);
        this.fetchLogs();
        this.fetchuserinfo();
      } else {
        // 数据不存在时的处理逻辑
      }
    },

    async fetchuserinfo() {
      this.loading = true; // 开始加载
      try {
        // 调用API获取日志
        const res = await getUserByid(this.userId);
        this.ruleForm = res.data;
        // 更新数据
      } catch {
        // console.error("获取失败");
      } finally {
        this.loading = false; // 完成加载
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.el-card {
  width: 200%; /* 确保卡片占据其父容器的全部宽度 */
  max-width: 700px; /* 可选：限制卡片的最大宽度，避免过大 */
  padding: 20px; /* 增加内边距使内容区域看起来更大 */
  margin-left: 100px;
  box-sizing: border-box; /* 确保内边距不增加卡片总宽度 */
}
.avatar-container {
  text-align: center; /* 让头像和用户名居中 */
}

.avatar {
  width: 100px;
  height: 100px;
  object-fit: cover;
  margin-left: 100px;
  border-radius: 50%; /* 如果需要圆形头像 */
  display: inline-block; /* 使图片与文本在同一行 */
  vertical-align: middle; /* 垂直居中对齐 */
}

.username-display {
  margin-top: 10px; /* 用户名与头像之间的间距 */
}
.editForm {
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  > div {
    width: 45%;
  }
}
.scrollable-log-container {
  margin-top: 60px;
  height: 600px; /* 您可以根据需要调整这个值 */
  overflow-y: auto;
  margin-right: -50px;
}
// .m2 {
//   margin-left: 330px;
// }
.log-header {
  position: absolute;
  top: 5%;
  right: 30%;
  background-color: #fff; /* 根据需要设置背景颜色 */
  padding: 0px 0; /* 根据需要设置上下内边距 */
  z-index: 0; /* 确保标题在内容之上 */
}
</style>
