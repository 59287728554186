import request from "@/request/axios";

// 获取日志分页信息
export const getLoglist = ({ size, current }) => {
  return request({
    url: "/log/list",
    method: "get",
    params: {
      size,
      current,
    },
  });
};

// 获取日志分页信息
export const getLoglists = ({
  keyword,
  endTime,
  startTime,
  size,
  current,
  createBy,
  userId,
}) => {
  return request({
    url: "/log/getTimeList",
    method: "get",
    params: {
      endTime,
      startTime,
      keyword,
      size,
      current,
      createBy,
      userId,
    },
  });
};
