<template>
  <div class="not-found">
    <h1>404</h1>
    <p>页面加在失败,请联系管理员!</p>
  </div>
</template>

<script>
export default {
  name: "NotFound",
};
</script>

<style scoped>
.not-found {
  text-align: center;
  margin-top: 50px;
}
</style>
